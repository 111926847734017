import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckoutFormProps, AvailableRecurrence, Recurrence } from '../../types';
import { OtherPayer } from './OtherPayer';
import { Api } from '../../api';
import { endpoints } from '../../utils';
import { useAuthContext } from '../../context';
import { useQueryParams } from '../../hooks';

const useStyles = makeStyles(({ spacing, shape, palette }) => ({
  paymentOption: {
    background: 'transparent',
    padding: spacing(1),
    border: `1px solid ${palette.text.secondary}`,
    borderRadius: shape.borderRadius,
    marginBottom: spacing(1),
    marginLeft: 0,
    marginRight: 0,
  },
  selectedPaymentOption: {
    borderColor: palette.text.primary,
  },
  formLabel: {
    marginBottom: spacing(2),
  },
  otherPayer: {
    margin: `${spacing(1)} 0 ${spacing(3)} 0`,
  },
}));

export const Payment: React.FC<CheckoutFormProps> = ({ formValues, handleChange, formErrors }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [payerSelection, setPayerSelection] = useState(formValues.parental_consent ? 'other' : 'self');
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
  const [paymentRecurrenceDialogOpen, setPaymentRecurrenceDialogOpen] = useState(false);
  const [paymentRecurrence, setPaymentRecurrence] = useState(1);
  const [recurrences, setRecurrences] = useState<Recurrence[]>([]);
  const [totalAmount, setTotalAmount] = useState<string>('');
  const queryParams = useQueryParams();
  const selectedProduct = queryParams.get('product');
  const quantity = queryParams.get('quantity');
  const { token } = useAuthContext();

  useEffect(() => {
    setPaymentRecurrence(formValues.recurrences);
  }, [formValues.recurrences]);
  // product recurrence
  useEffect(() => {
    if (selectedProduct) {
      (async (): Promise<void> => {
        try {
          if (token.length) {
            const { data } = await Api.get<AvailableRecurrence>(
              endpoints.recurrences(Number(selectedProduct), Number(quantity)),
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
            const { recurrences, total_amount } = data;
            const noneOneTimeRecurrences = recurrences.filter((item: Recurrence) => item.recurrences > 1);
            setRecurrences(noneOneTimeRecurrences);
            setTotalAmount(total_amount);
          }
        } catch {
          setRecurrences([]);
          setTotalAmount('');
        }
      })();
    }
  }, [token, selectedProduct, quantity]);

  const handleClosePaymentRecurrenceDialog = (): void => setPaymentRecurrenceDialogOpen(false);

  const handleChangePaymentRecurrence = (e: ChangeEvent<HTMLInputElement>, value: string): void => {
    setPaymentRecurrence(Number(value));
    handleChange(e);
  };

  const handlePayerChange = (e: MouseEvent<HTMLButtonElement>): void =>
    setPayerSelection((e.target as HTMLButtonElement).value);

  const updatePaymentSelection = ({ target }: MouseEvent<HTMLButtonElement>): void => {
    if (target instanceof HTMLInputElement) {
      setSelectedPaymentOption(target.value);
      if (target.value === 'epic_invoice') {
        setPaymentRecurrenceDialogOpen(true);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          {t<string>('courseWebshop.paymentTotalAmount')}: {`${totalAmount}€`}
        </Typography>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t<string>('courseWebshop.paymentSelectionLabel')}</FormLabel>
          <RadioGroup
            value={payerSelection}
            aria-label={t<string>('courseWebshop.paymentSelectionLabel')}
            onChange={handleChange}
          >
            <FormControlLabel
              name="payer"
              key="self"
              value="self"
              label={t<string>('courseWebshop.paymentHandledBySelf')}
              control={<Radio onClick={handlePayerChange} />}
            />
            <FormControlLabel
              name="payer"
              key="other"
              value="other"
              label={t<string>('courseWebshop.paymentHandledByOther')}
              control={<Radio onClick={handlePayerChange} />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {payerSelection === 'other' ? (
        <Grid item xs={12} className={classes.otherPayer}>
          <OtherPayer formValues={formValues} handleChange={handleChange} formErrors={formErrors} />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend" className={classes.formLabel}>
            {t<string>('courseWebshop.paymentMethodSelection')}
          </FormLabel>
          <RadioGroup
            value={selectedPaymentOption}
            aria-label={t<string>('courseWebshop.paymentMethodSelection')}
            onChange={handleChange}
          >
            <FormControlLabel
              name="payment"
              value="epic_invoice"
              label={t<string>('courseWebshop.epicInvoice')}
              className={`${classes.paymentOption} ${
                selectedPaymentOption === 'epic_invoice' ? classes.selectedPaymentOption : ''
              }`}
              control={<Radio onClick={updatePaymentSelection} />}
            />
            <FormControlLabel
              name="payment"
              value="anders_klarna"
              label={t<string>('courseWebshop.klarnaInvoice')}
              className={`${classes.paymentOption} ${
                selectedPaymentOption === 'anders_klarna' ? classes.selectedPaymentOption : ''
              }`}
              control={<Radio onClick={updatePaymentSelection} />}
            />
          </RadioGroup>
          <FormHelperText>{t<string>('courseWebshop.paymentSelectionInfo')}</FormHelperText>
        </FormControl>
      </Grid>
      <Dialog open={paymentRecurrenceDialogOpen} onClose={handleClosePaymentRecurrenceDialog} fullWidth>
        <DialogTitle>{t<string>('courseWebshop.paymentRecurrenceHeader')}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t<string>('courseWebshop.paymentRecurrenceLabel')}</FormLabel>
            <RadioGroup value={paymentRecurrence} name="recurrences" onChange={handleChangePaymentRecurrence}>
              {!!totalAmount && (
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={`${totalAmount}€ ${t<string>('courseWebshop.singlePayment')}`}
                />
              )}
              {!!recurrences &&
                recurrences.map((item: Recurrence, i) => (
                  <FormControlLabel
                    value={item.recurrences}
                    control={<Radio />}
                    label={`${item.amount}€/${t<string>('courseWebshop.month')} x${item.recurrences}`}
                    key={item.recurrences}
                    checked={i === paymentRecurrence - 2}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentRecurrenceDialog} color="primary" fullWidth>
            {t<string>('nav.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
