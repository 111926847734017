import React from 'react';
import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  circularProgressBackground: {
    color: palette.grey[400],
    position: 'absolute',
    [breakpoints.down('md')]: {
      width: '77px !important',
      height: '77px !important',
    },
  },
  circularProgress: {
    position: 'absolute',
    [breakpoints.down('md')]: {
      width: '77px !important',
      height: '77px !important',
    },
  },
  progressContainer: {
    marginBottom: '24px',
    height: '100%',
    minHeight: '80px',
  },
  number: {
    fontSize: '22px',
  },
}));

interface Props {
  progress: number;
}

export const Progress: React.FC<Props> = ({ progress }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.progressContainer}
      position="relative"
      width="100%"
      height="100px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={77} className={classes.circularProgressBackground} variant="determinate" value={100} />
      <CircularProgress
        size={77}
        className={classes.circularProgress}
        variant="determinate"
        color="secondary"
        value={progress}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.number} variant="h1">
          {progress}%
        </Typography>
      </Box>
    </Box>
  );
};
