import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { FinnishSSN } from 'finnish-ssn';
import { CheckoutControlProps } from '../../types';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  navigationArrows: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'stretch',
    marginTop: spacing(3),
    [breakpoints.up('sm')]: {
      marginTop: spacing(4),
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
  },
  buttonBack: {
    [breakpoints.down('xs')]: {
      marginBottom: spacing(2),
    },
  },
}));

export const CheckoutControls: React.FC<CheckoutControlProps> = ({
  isLoading,
  handlePrev,
  handleNext,
  currentStep,
  formValues: { nin_other, payer },
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let ageInYearsOther = null;

  if (FinnishSSN.validate(nin_other.toUpperCase())) {
    const { ageInYears: _ageInYearOther } = FinnishSSN.parse(nin_other.toUpperCase());
    ageInYearsOther = _ageInYearOther;
  }

  // Disable the next button on other payer details if the other payer is below 16.
  const tooYoungOtherPayer = currentStep === 2 && ageInYearsOther !== null && ageInYearsOther < 18;

  // If the user is below 18, force to input the information of the other payer.
  const tooYoungSelfPayer = currentStep === 2 && ageInYearsOther !== null && ageInYearsOther < 16 && payer === 'self';

  const disabled = isLoading || tooYoungOtherPayer || tooYoungSelfPayer;
  return (
    <div className={classes.navigationArrows}>
      {currentStep !== 1 && (
        <Button
          className={classes.buttonBack}
          data-testid="checkoutNav-prev"
          id="checkoutNav-prev"
          onClick={handlePrev}
          startIcon={<ArrowBack aria-hidden="true" />}
          variant="text"
        >
          {t<string>('nav.prev')}
        </Button>
      )}
      <Button
        data-testid="checkoutNav-next"
        id="checkoutNav-next"
        onClick={handleNext}
        endIcon={
          isLoading ? (
            <CircularProgress aria-hidden="true" size={24} color="inherit" />
          ) : (
            <ArrowForward aria-hidden="true" />
          )
        }
        disabled={disabled}
        variant="contained"
      >
        {t<string>('nav.next')}
      </Button>
    </div>
  );
};
