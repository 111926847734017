import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Alert from '@material-ui/lab/Alert';
import { colors } from '../../theme';
import { CheckoutControlProps, CheckoutFormProps } from '../../types';
import { Payment } from './Payment';
import { CheckoutControls } from './CheckoutControls';
import { Confirmation } from './Confirmation';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  registrationHeader: {
    padding: spacing(1),
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      alignItems: 'center',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
  },
  progressBar: {
    padding: spacing(2),
    maxWidth: '125px',
    [breakpoints.up('sm')]: {
      marginRight: spacing(2),
    },
  },
  registrationHeaderTitle: {
    margin: `${spacing(1)} 0`,
  },
  registrationHeaderText: {
    margin: `${spacing(1)} 0`,
    color: palette.text.secondary,
    '& strong': {
      color: palette.text.primary,
    },
  },
  paper: {
    padding: `${spacing(3)} ${spacing(2)}`,
    marginBottom: spacing(4),
    [breakpoints.up('sm')]: {
      padding: spacing(4),
    },
  },
  textRequiredFields: {
    color: palette.text.secondary,
    marginBottom: spacing(3),
  },
}));

interface Props extends CheckoutFormProps, CheckoutControlProps {
  checkoutError: string | null;
}

export const CheckoutView: React.FC<Props> = ({
  formErrors,
  formValues,
  setFormValues,
  handleChange,
  currentStep,
  totalSteps,
  handlePrev,
  handleNext,
  isLoading,
  checkoutError,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formHasErrors = formErrors.length > 0;

  const renderSteps = (): JSX.Element => {
    switch (currentStep) {
      case 1: {
        return (
          <Payment
            handleChange={handleChange}
            formValues={formValues}
            setFormValues={setFormValues}
            formErrors={formErrors}
          />
        );
      }

      case 2: {
        return <Confirmation formValues={formValues} checkoutError={checkoutError} />;
      }

      default: {
        return (
          <Payment
            handleChange={handleChange}
            formValues={formValues}
            setFormValues={setFormValues}
            formErrors={formErrors}
          />
        );
      }
    }
  };

  return (
    <>
      <div className={classes.registrationHeader}>
        <CircularProgressbar
          className={classes.progressBar}
          value={currentStep}
          maxValue={totalSteps}
          text={`${currentStep}/${totalSteps}`}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: colors.secondary,
            textColor: colors.textBlack,
            trailColor: 'rgba(0,0,0,0.1)',
          })}
        />
        <div>
          <h2 className={classes.registrationHeaderTitle}>{t<string>('userData.personalInformation')}</h2>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form data-testid="checkout-form">
          {currentStep !== 2 && (
            <div className={classes.textRequiredFields}>{t<string>('formValidation.formRequiredFields')}</div>
          )}
          {formHasErrors && (
            <Box marginBottom={3}>
              <Alert severity="error">{t<string>('formValidation.formHasErrors')}</Alert>
            </Box>
          )}
          {renderSteps()}
        </form>
        <CheckoutControls
          isLoading={isLoading}
          handlePrev={handlePrev}
          handleNext={handleNext}
          currentStep={currentStep}
          totalSteps={totalSteps}
          formValues={formValues}
        />
      </Paper>
    </>
  );
};
