import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Card, CardHeader, CardContent, Typography, Grid, Chip } from '@material-ui/core';
import { urls, endpoints } from '../utils';
import { Header, MainTemplate } from '../components';
import { Api } from '../api';
import { useAuthContext } from '../context';

const useStyles = makeStyles(({ spacing, palette }) => ({
  invoiceCard: {
    marginBottom: spacing(2),
  },
  invoiceLine: {
    borderBottom: `0.05rem solid ${palette.grey[400]}`,
  },
  openInvoices: {
    marginRight: '16px',
  },
}));

interface InvoiceLine {
  name: string;
  price_unit: number;
  product_name: string;
  quantity: number;
  taxes: number[];
}

interface Invoice {
  name: string;
  payment_reference: string;
  payment_state: string;
  amount_tax: number;
  amount_total: number;
  invoice_date: string;
  invoice_date_due: string;
  lines: InvoiceLine[];
}

export const Invoices: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const classes = useStyles();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [toggleOpenInvoice, setToggleOpenInvoice] = useState<boolean>(true);
  const [togglePaidInvoice, setTogglePaidInvoice] = useState<boolean>(false);
  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await Api.get(endpoints.invoices, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setInvoices(data);
    })();
  }, [token]);

  const handlePrev = useCallback(
    (e: SyntheticEvent): void => {
      e.preventDefault();
      history.push(urls.profile);
    },
    [history],
  );
  const handleTogglePaidInvoice = useCallback((): void => {
    setToggleOpenInvoice(false);
    setTogglePaidInvoice(true);
  }, [setToggleOpenInvoice, setTogglePaidInvoice]);

  const handleToggleOpenInvoice = useCallback((): void => {
    setToggleOpenInvoice(true);
    setTogglePaidInvoice(false);
  }, [setToggleOpenInvoice, setTogglePaidInvoice]);

  const renderHeader = useMemo(
    () => (
      <Header
        handlePrev={handlePrev}
        titleText={`${t<string>('invoices.header')} (${invoices.length})`}
        hidePrev={false}
      />
    ),
    [handlePrev, invoices.length, t],
  );

  const renderInvoiceLines = useCallback(
    (i: Invoice) =>
      i.lines.map((l: InvoiceLine, index: number) => (
        <CardContent className={classes.invoiceLine} key={index}>
          <Grid container>
            <Grid item xs={6}>
              <Typography color="textSecondary" variant="body2">
                {t<string>('invoices.name')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {l.name}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography color="textSecondary" variant="body2">
                {t<string>('invoices.price')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {l.price_unit}€
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography color="textSecondary" variant="body2">
                {t<string>('invoices.productName')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {l.product_name}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography color="textSecondary" variant="body2">
                {t<string>('invoices.quantity')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {l.quantity}
            </Grid>
          </Grid>
          {!!l.taxes.length && (
            <Grid container>
              <Grid item xs={6}>
                <Typography color="textSecondary" variant="body2">
                  {t<string>('invoices.tax')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {l.taxes[0]}%
              </Grid>
            </Grid>
          )}
        </CardContent>
      )),
    [t, classes.invoiceLine],
  );
  const renderInvoiceInfo = useCallback(
    (i: Invoice) => (
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography color="textSecondary" variant="body2">
              {t<string>('invoices.date')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {i.invoice_date}
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary" variant="body2">
              {t<string>('invoices.dueDate')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {i.invoice_date_due}
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary" variant="body2">
              {t<string>('invoices.amount')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {i.amount_total}€
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary" variant="body2">
              {t<string>('invoices.amountTax')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {i.amount_tax}
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary" variant="body2">
              {t<string>('invoices.reference')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {i.payment_reference}
          </Grid>
        </Grid>
      </CardContent>
    ),
    [t],
  );

  const renderOpenInvoices = useMemo(
    () =>
      invoices
        .filter((i) => i.payment_state !== 'paid')
        .map((i, index) => (
          <Card className={classes.invoiceCard} key={index}>
            <CardHeader title={`${t<string>('invoices.openInvoices')} ${i.name}`} />
            {renderInvoiceLines(i)}
            {renderInvoiceInfo(i)}
          </Card>
        )),
    [invoices, classes.invoiceCard, t, renderInvoiceInfo, renderInvoiceLines],
  );

  const renderPaidInvoices = useMemo(
    () =>
      invoices
        .filter((i) => i.payment_state === 'paid')
        .map((i, index) => (
          <Card className={classes.invoiceCard} key={index}>
            <CardHeader title={`${t<string>('invoices.paidInvoices')} ${i.name}`} />
            {renderInvoiceLines(i)}
            {renderInvoiceInfo(i)}
          </Card>
        )),
    [invoices, classes.invoiceCard, t, renderInvoiceInfo, renderInvoiceLines],
  );

  return (
    <MainTemplate>
      <Container>
        {renderHeader}
        <Chip
          label={`${t<string>('invoices.openInvoices')}`}
          component="h6"
          variant="outlined"
          className={classes.openInvoices}
          onClick={handleToggleOpenInvoice}
        />
        <Chip
          label={`${t<string>('invoices.paidInvoices')}`}
          component="h6"
          variant="outlined"
          onClick={handleTogglePaidInvoice}
        />
        {toggleOpenInvoice && renderOpenInvoices}
        {togglePaidInvoice && renderPaidInvoices}
      </Container>
    </MainTemplate>
  );
};
