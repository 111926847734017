import React from 'react';
import { Dialog, Button, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
  isOpen: boolean;
  handleYes: () => void;
  handleCancel: () => void;
  confirmText?: string;
  cancelText?: string;
}

export const ConfirmationDialog: React.FC<Props> = ({
  text,
  isOpen,
  handleYes,
  handleCancel,
  confirmText,
  cancelText,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYes} color="primary">
          {confirmText || `${t<string>('confirmationDialog.yes')}`}
        </Button>
        <Button onClick={handleCancel}>{cancelText || `${t<string>('confirmationDialog.cancel')}`}</Button>
      </DialogActions>
    </Dialog>
  );
};
