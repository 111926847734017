import React, { useCallback, useState, useEffect, useMemo, Fragment, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  Divider,
  List,
  ListItemText,
  Card,
  CardContent,
  Container,
} from '@material-ui/core';
import { ArrowBack, ArrowForwardRounded, Close, Timer, Check, Cancel } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Certification, Progress } from '../home';
import { Embed } from './Embed';
import { colors } from '../../theme';
import { useAuthContext, useStudyPathContext } from '../../context';
import { useScreenType, useTimerRef } from '../../hooks';
import { Api } from '../../api';
import { endpoints, tags, urls } from '../../utils';
import { SimpleDialog } from './SimpleDialog';
import { SurveyResults, ExamState, SurveyQuestion, Slide } from '../../types';
import { getSections } from '../studentpath/Sections';
import { parseImageSrcFromString } from '../../utils/string';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
  },
  hidden: {
    display: 'none',
  },
  timeline: {
    padding: 0,
    margin: 0,
    position: 'relative',
  },
  timelineItem: {
    marginTop: spacing(8),
  },
  timelineContent: {
    padding: 0,
  },
  timelineContentOdd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  timelineCard: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    borderRadius: '3rem',
    height: '6.5rem',
    cursor: 'pointer',
    display: 'flex',
    width: '15rem',
  },
  categoryName: {
    marginLeft: spacing(1),
  },
  pathConnector: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
  },
  cardIcon: {
    height: '2.5rem',
    width: '2.5rem',
  },
  drawerRounded: {
    borderRadius: '1.5rem 1.5rem 0 0',
    [breakpoints.up('md')]: {
      maxWidth: '30rem',
      margin: '0 auto',
    },
  },
  drawerContent: {
    padding: spacing(4),
    textAlign: 'center',
  },
  drawerButtons: {
    marginTop: spacing(4),
  },
  dialog: {
    backgroundColor: colors.background,
    textAlign: 'center',
    padding: `${spacing(4)} ${spacing(2)}`,
    [breakpoints.up('md')]: {
      borderRadius: '1.5rem',
    },
  },
  reviewDialogPaper: {
    textAlign: 'center',
    borderRadius: '1.5rem',
  },
  categoryCard: {
    marginTop: spacing(3),
    borderRadius: '1.5rem',
  },
  categoryCardActionArea: {
    padding: spacing(6),
  },
  categoryLockedIcon: {
    margin: `${spacing(8)} 0`,
    width: '3rem',
    height: '3rem',
  },
  slides: {
    [breakpoints.down('md')]: {
      padding: '8px 24px 16px',
      paddingBottom: '0px',
    },
    '& :first-child': {
      overflowY: 'hidden',
    },
  },
  categoryLockedHeader: {
    marginBottom: spacing(4),
  },
  nextQuestionButton: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  questionPercentage: {
    color: palette.success.main,
  },
  '@keyframes fadeAnimation': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  fadeIn: {
    opacity: 0,
    animation: '1.5s $fadeAnimation forwards',
  },
  crown: {
    width: '1.75rem',
    height: '1.5rem',
    marginLeft: spacing(2),
  },
  greeting: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  categoryPrevButton: {
    color: palette.common.black,
  },
  chatButton: {
    borderRadius: '25px',
    fontSize: '15px',
  },
  greenBox: {
    backgroundColor: colors.secondary,
    padding: spacing(1),
    marginBottom: spacing(1),
    textAlign: 'center',
  },
  redBox: {
    backgroundColor: colors.primary,
    padding: spacing(1),
    marginBottom: spacing(1),
    textAlign: 'center',
  },
  whiteText: {
    color: colors.textWhite,
    padding: spacing(1),
  },
  sectionScore: {
    color: colors.textGray,
  },
  whiteBg: {
    background: '#fff',
    fontSize: '20px',
  },
  questionTitle: {
    marginBottom: '16px',
  },
  answerFlex: {
    display: 'flex',
    gap: '8px',
    alignItems: 'flex-end',
  },
  externalIcon: {
    marginLeft: '6px',
  },
  chatIcon: {
    fontSize: '2rem',
  },
}));

interface Props {
  sectionName?: string;
  isOpenHours?: boolean;
}

export const SlidesDialog: React.FC<Props> = ({ sectionName, isOpenHours }) => {
  const classes = useStyles();
  const practiceExamTimerRef = useTimerRef(2700000, sectionName);
  const [markedQuestions, setMarkedQuestions] = useState<number[]>([]);
  const [buttonStates, setButtonStates] = useState<boolean[]>([]);
  const [pauseVideo, setPauseVideo] = useState(false);
  const { isMobileOrTablet } = useScreenType();
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openStartExam, setOpenStartExam] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [completionTime, setCompletionTime] = useState(0);
  const [slideMinTimeCert, setSlideMinTimeCert] = useState(0);
  const [slideMinTimeVideo, setSlideMinTimeVideo] = useState(0);
  const [endTime, setEndTime] = useState(0);
  /* tracks slide ids while going through lessons, resets at the start, lets user skip if lesson slide is completed in Odooo and its id is in this state */
  const [examSlidesCompleted, setExamSlidesCompleted] = useState<number[]>([]);
  const [examCurrentState, setExamCurrentState] = useState(ExamState.NotStarted);
  const [showExamTimer, setShowExamTimer] = useState(false);
  const [showExamAnswers, setShowExamAnswers] = useState(false);
  const [showSendAnswersDialog, setShowSendAnswersDialog] = useState(false);
  const [outOfTime, setOutOfTime] = useState(false);
  const [showMinimumTimeMessage, setShowMinimumTimeMessage] = useState(false);
  const [showMinimumExamTimeMessage, setShowMinimumExamTimeMessage] = useState(false);
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<SurveyQuestion | undefined>(undefined);
  const [liveChatUrl, setLiveChatUrl] = useState<string>('');
  const { token, logout, logSlideActivity, logActivity } = useAuthContext();
  const [examProgress, setExamProgress] = useState(0);
  const { t } = useTranslation();
  const {
    selectedCategory,
    selectedCourse,
    slideIndex,
    categoryLocked,
    questionIndex,
    examIndex,
    surveyId,
    questionsList,
    setQuestionIndex,
    setExamIndex,
    setSlideIndex,
    setSelectedCategory,
    answers,
    surveys,
    setAnswers,
    setQuestionsList,
    slides,
    handleSlidesPrev,
    setStudyPathPhoneNumber,
    surveyAnswers,
    setSurveyAnswers,
    setSections,
    sections,
  } = useStudyPathContext();

  // check if current study should have chat
  const hasChat = (slide: Slide): boolean => {
    const supervisedCategories = sections.find((s) => s.section_type === 'supervised')?.categories || [];
    const isSupervised = !!selectedCategory && supervisedCategories.includes(selectedCategory);
    const noneChatSlides = ['driving_lesson', 'online_lesson', 'driving_exam', 'notification'];
    return isSupervised && !noneChatSlides.includes(slide?.content_type);
  };

  useEffect(() => {
    setButtonStates(slides.map((s) => s.content_type === 'document'));
  }, [setButtonStates, slides]);

  useEffect(() => {
    const currentQuestion = questionsList[examIndex]?.find((q, i) => i === questionIndex);
    if (questionsList && questionIndex >= 0 && examIndex >= 0) {
      setQuestions(questionsList[examIndex]);
      setCurrentQuestion(currentQuestion);
    }
  }, [examIndex, questionIndex, questionsList, setQuestions, setCurrentQuestion]);

  useEffect(() => {
    if (showExamTimer === true) {
      const completionTimeSeconds = slides[slideIndex]?.survey_time_limit * 60 || 600;
      //  const completionTimeSeconds = 1;
      setEndTime(Number(localStorage.getItem('endTime')));
      setCompletionTime(completionTimeSeconds);
      const examState = localStorage.getItem('examState');

      if (!examState) {
        setExamCurrentState(ExamState.NotStarted);
        setShowExamTimer(false);
      }
      if (examState === 'started') {
        setExamCurrentState(ExamState.Started);
        setShowExamTimer(true);
      }
      if (examState === 'ended') {
        setExamCurrentState(ExamState.Ended);
        setShowExamTimer(false);
        localStorage.setItem('endTime', '0');
      }
    }
  }, [slideIndex, showExamTimer, slides]);

  useEffect(() => {
    const currentSurveyID = localStorage.getItem('examID');

    if (
      questionIndex === 0 &&
      slides[slideIndex]?.content_type === 'certification' &&
      slides[slideIndex]?.is_practice_exam &&
      slides[slideIndex]?.survey_id !== Number(currentSurveyID)
    ) {
      setOpenStartExam(true);
    } else if (
      examCurrentState !== ExamState.Started &&
      questionIndex === 0 &&
      slides[slideIndex]?.content_type === 'certification' &&
      slides[slideIndex]?.is_practice_exam
    ) {
      setOpenStartExam(true);
    } else {
      setOpenStartExam(false);
    }
  }, [examCurrentState, questionIndex, slideIndex, slides, setQuestionsList]);

  useEffect(() => {
    const slide = slides[slideIndex];
    if (slide?.survey_id) {
      setExamIndex(surveys.indexOf(slide.survey_id));
    }
  }, [slides, slideIndex, surveys, setExamIndex]);

  useEffect(() => {
    const currentSlide = slides[slideIndex];
    setExamProgress(
      Math.round(
        Number((slideIndex / slides?.length) * 100) +
          Number(((questionIndex / questions?.length) * 100) / slides.length),
      ) || 0,
    );

    if (questionIndex === 0 && currentSlide?.content_type === 'certification') {
      // first slide of survey
      const completionTimeSeconds = slides[slideIndex]?.completion_time * 60 * 60;
      //const completionTimeSeconds = 1;

      const startTime = new Date();
      const endTime = startTime.getTime() + completionTimeSeconds * 1000;

      setSlideMinTimeCert(endTime);
    }
  }, [slideIndex, slides, questionIndex, setExamProgress, examSlidesCompleted, questions?.length]);

  useEffect(() => {
    if (slides[slideIndex]?.is_practice_exam && slides[slideIndex]?.is_time_limited) {
      setShowExamTimer(true);
    }

    if (slides[slideIndex]?.content_type === 'certification') {
      if (questionIndex === questions?.length - 1 && slides[slideIndex].is_practice_exam) {
        setIsLastQuestion(true);
        const marked = String(localStorage.getItem('marked_questions')) || '';
        if (marked !== '') {
          const markedQ: number[] = JSON.parse(marked);
          setMarkedQuestions(markedQ);
        }
      } else {
        setIsLastQuestion(false);
      }
    } else {
      setIsLastQuestion(false);
      setShowExamTimer(false);
    }
  }, [slides, questionIndex, questions, slideIndex, examCurrentState]);

  const getExamAnswers = useCallback(async (): Promise<void> => {
    if (token) {
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await Api.get<SurveyResults>(
        endpoints.examCorrectAnswers(slides[slideIndex]?.survey_id),
        requestConfig,
      );

      setSurveyAnswers(data);
    }
  }, [setSurveyAnswers, slideIndex, slides, token]);

  useEffect(() => {
    if (!slides[slideIndex]) return;

    const { content_type } = slides[slideIndex];
    if (content_type === 'notification') {
      setShowNotification(true);
    }

    if (content_type !== 'certification' || !currentQuestion) return;

    const filterCurrentAnswers = answers.filter((a) => a.questionId === currentQuestion.id);
    const state = filterCurrentAnswers.some((a) => !a.isCorrect) || !filterCurrentAnswers.length;

    setButtonStates(
      slides.map((slide, i) => {
        if (i !== slideIndex) return i < slideIndex;

        if (!slide.is_practice_exam) return !state;

        // disable  button of last question to show send anwers button
        return questionIndex < questions?.length - 1;
      }),
    );
  }, [answers, currentQuestion, slideIndex, slides, questionIndex, questions?.length, slideMinTimeCert]);

  const startVideo = (): void => {
    const completionTimeSeconds = slides[slideIndex]?.completion_time * 60 * 60;
    // const completionTimeSeconds = 1;

    const startTime = new Date();
    const endTime = startTime.getTime() + completionTimeSeconds * 1000;
    setSlideMinTimeVideo(endTime);

    logSlideActivity('PRESS_PLAY_VIDEO', slides[slideIndex]?.id);
  };

  const videoPlaying = (): void => {
    setPauseVideo(false);
  };

  const resetIndexes = useCallback(async (): Promise<void> => {
    setSlideIndex(0);
    setQuestionsList([]);
    setExamIndex(0);
    setSelectedCategory(null);

    const data = await getSections(selectedCourse, token);
    if (data) {
      setSections(data);
    }
  }, [setSlideIndex, setSelectedCategory, setQuestionsList, setExamIndex, selectedCourse, token, setSections]);

  // Check answers and update the category.
  const submitAnswers = useCallback(
    async (_: number, commit: boolean, isLastSlide = false): Promise<void> => {
      try {
        if (surveyId) {
          if (token) {
            if (commit) logSlideActivity('ANSWERS_SENT', slides[slideIndex].id);
            await Api.post(
              endpoints.submitExamAnswers(slides[slideIndex].survey_id),
              {
                commit,
                slide_id: slides[slideIndex].id,
                user_input_line_ids: answers.map((a) => ({
                  answer_type: 'suggestion',
                  question_id: a.questionId,
                  skipped: false,
                  suggested_answer_id: a.answerId,
                  value_text_box: a.values[0],
                })),
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
          }
          if (isLastSlide) {
            resetIndexes();
          }
          if (commit && slides[slideIndex].is_practice_exam) {
            setAnswers([]);
            getExamAnswers();
          }
        }
      } catch (error) {
        // @ts-ignore
        console.log(error.response.data);
        console.log('submit error');
      }
    },
    [answers, slides, slideIndex, surveyId, token, getExamAnswers, setAnswers, logSlideActivity, resetIndexes],
  );

  const completeSlide = useCallback(async (): Promise<void> => {
    try {
      await Api.post(
        endpoints.completeSlide(slides[slideIndex].id.toString()),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch {
      logout();
      await Api.get(endpoints.informLogout, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStudyPathPhoneNumber('');
    }
    // Set the Slide as completed in this component
  }, [slides, slideIndex, token, logout, setStudyPathPhoneNumber]);

  const clickSubmitAnswers = useCallback((): void => {
    // will submit all answers with commit=true
    logSlideActivity('SUBMIT_ANSWERS', slides[slideIndex].id);
    setShowSendAnswersDialog(false);
    setExamCurrentState(ExamState.Ended);
    localStorage.setItem('examState', ExamState.Ended);
    localStorage.setItem('examID', '');
    localStorage.setItem('endTime', '0');
    setShowExamTimer(false);
    setShowExamAnswers(true);
    submitAnswers(Number(selectedCategory?.id), true);
    // clear marked questions after submitting answers
    setMarkedQuestions([]);
    localStorage.setItem('marked_questions', '');
  }, [selectedCategory?.id, submitAnswers, logSlideActivity, slides, slideIndex]);

  const goToQuestion = (questionIndex: number): void => {
    setQuestionIndex(questionIndex);
  };

  // Move to next slide or question or close the category.
  const handleNextQuestionClick = useCallback(async (): Promise<void> => {
    // open start exam dialog after if next slide has exam
    const currentSurveyID = localStorage.getItem('examID');
    const contentType = slides[slideIndex]?.content_type;
    const isLastQuestion = questions ? questionIndex === questions.length - 1 : false;
    const isLastSlide = slideIndex === slides.length - 1;
    let skip = false;

    // early bail outs for practice exams
    if (sectionName !== 'PracticeExams') {
      // if website is closed, quit exam
      if (!isOpenHours) {
        resetIndexes();
      }

      // if user has already completed the slide, allow them to skip it
      if (slides[slideIndex].completed) {
        if (examSlidesCompleted.includes(slides[slideIndex]?.id)) {
          skip = true;
          if (slides[slideIndex]?.content_type === 'video') {
            setSlideMinTimeVideo(1);
          } else {
            setSlideMinTimeCert(1);
          }
        } else {
          skip = false;
        }
      }

      // practice exams are regulated to be specific time length
      if (practiceExamTimerRef.current !== 0 && isLastSlide) {
        // extra check to skip first exam, which is for accepting terms
        const summedCompletionTimes = slides.reduce((acc, slide) => acc + slide.completion_time, 0);

        if (summedCompletionTimes !== 0) {
          setShowMinimumExamTimeMessage(true);
          return;
        }
      } else {
        if (slides[slideIndex]?.content_type === 'video') {
          if (new Date().getTime() < slideMinTimeVideo) {
            if (!skip) {
              if (contentType === 'video') {
                setShowMinimumTimeMessage(true);
                return;
              }
            }
          }
        } else {
          if (new Date().getTime() < slideMinTimeCert) {
            if (contentType === 'certification' && isLastQuestion) {
              setShowMinimumTimeMessage(true);
              return;
            }
          }
        }
      }
    }

    if (slides.length < slideIndex) {
      if (slides[slideIndex + 1].content_type === 'certification') {
        if (
          examCurrentState !== ExamState.Started &&
          slides[slideIndex + 1].is_practice_exam &&
          slides[slideIndex + 1].survey_id !== Number(currentSurveyID)
        ) {
          setOpenStartExam(true);
        }
      }
    }
    if (!slides[slideIndex]?.completed) {
      const slide = { ...slides[slideIndex], completed: true };
      slides[slideIndex] = slide;
      if (contentType !== 'certification') {
        await completeSlide();
      }
    }

    if (contentType === 'video') {
      setPauseVideo(true);
    }

    if (contentType === 'certification') {
      if (isLastQuestion) {
        logActivity('EXAM_LAST_QUESTION', questions[questionIndex].title, slides[slideIndex]?.id);
        submitAnswers(Number(selectedCategory?.id), false, isLastSlide);
        setQuestionIndex(0);
      } else {
        logActivity('EXAM_NEXT_QUESTION', questions[questionIndex].title, slides[slideIndex]?.id);
        setQuestionIndex(questionIndex + 1);
      }
    }

    if (contentType !== 'certification' || (contentType === 'certification' && isLastQuestion)) {
      if (isLastSlide) {
        logSlideActivity('LAST_SLIDE', slides[slideIndex]?.id);
        resetIndexes();
        localStorage.setItem('examState', ExamState.Ended);
        localStorage.setItem('examID', '');
        localStorage.setItem('endTime', '0');
      } else {
        setExamSlidesCompleted([...examSlidesCompleted, slides[slideIndex]?.id]);
        setSlideIndex(slideIndex + 1);
        logSlideActivity('NEXT_SLIDE', slides[slideIndex]?.id);
      }
    }
  }, [
    slideMinTimeCert,
    slideMinTimeVideo,
    examCurrentState,
    completeSlide,
    questionIndex,
    selectedCategory?.id,
    setQuestionIndex,
    setSlideIndex,
    slideIndex,
    slides,
    submitAnswers,
    resetIndexes,
    logActivity,
    logSlideActivity,
    questions,
    sectionName,
    practiceExamTimerRef,
    examSlidesCompleted,
    isOpenHours,
  ]);

  const handleComplete = (slideID: number, isComplete: boolean): void => {
    const slideIndex = slides.findIndex((slide) => slide.id === slideID);
    setButtonStates(
      buttonStates.map((b, index) => {
        if (slideIndex === index) {
          return isComplete;
        }
        return b;
      }),
    );
  };

  useEffect(() => {
    let interval = setInterval(() => {}, 0);
    const endingTime = Number(localStorage.getItem('endTime')) || 0;
    if (seconds === 0) {
      localStorage.setItem('endTime', '0');
      setShowExamTimer(false);
    }

    if (localStorage.getItem('examIndex') === 'started') {
      localStorage.setItem('examIndex', 'onGoing');
      setExamSlidesCompleted([]);
    }

    if (examCurrentState === ExamState.Started && completionTime > 0) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const secondsLeft = Math.round((endingTime - now) / 1000);
        setSeconds(secondsLeft);
        if (completionTime !== 0 && secondsLeft <= 0) {
          if (secondsLeft > -5) {
            // If the time left is recent
            setOutOfTime(true);
            setExamCurrentState(ExamState.Ended);
            setQuestionIndex(questions?.length - 1);
            setSeconds(0);
            localStorage.setItem('endTime', '0');
            setShowExamTimer(false);
            setShowSendAnswersDialog(true);
          } else {
            // If the user left the exam session, restart the exam
            setExamCurrentState(ExamState.NotStarted);
            setSeconds(0);
            setExamSlidesCompleted([]);
            localStorage.setItem('endTime', '0');
            setShowExamTimer(false);
          }
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return (): void => clearInterval(interval);
  }, [
    answers.length,
    clickSubmitAnswers,
    examCurrentState,
    seconds,
    completionTime,
    endTime,
    submitAnswers,
    selectedCategory?.id,
    slides,
    slideIndex,
    setQuestionIndex,
    questions?.length,
  ]);

  const closeNotification = (): void => {
    handleNextQuestionClick();
    setShowNotification(false);
  };

  const handleCloseStartExamDialog = (): void => {
    setOpenStartExam(false);
    handleSlidesPrev();
  };

  const handleCloseShowExamAnswers = (): void => {
    if (slideIndex < slides.length - 1) {
      setQuestionIndex(0);
      setSlideIndex(slideIndex + 1);
    } else {
      resetIndexes();
    }
    setShowExamAnswers(false);
  };

  const handleStartExam = (): void => {
    logSlideActivity('START_EXAM', slides[slideIndex]?.id);
    setSeconds(0);
    setOutOfTime(false);
    setExamCurrentState(ExamState.Started);
    setQuestions(questionsList[examIndex]);
    setCurrentQuestion(currentQuestion);
    localStorage.setItem('marked_questions', '');

    const completionTimeSeconds = slides[slideIndex]?.survey_time_limit * 60;
    //const completionTimeSeconds = 1;

    setCompletionTime(completionTimeSeconds);
    const startTime = new Date();
    const endTime = startTime.getTime() + completionTimeSeconds * 1000;
    localStorage.setItem('endTime', endTime.toString());
    localStorage.setItem('examState', ExamState.Started);
    localStorage.setItem('examID', slides[slideIndex]?.survey_id.toString());

    setEndTime(endTime);
    setSeconds((endTime - startTime.getTime()) / 1000);
    setOpenStartExam(false);
  };

  const sendAnswersButton = useMemo(
    () => isLastQuestion && <Button onClick={clickSubmitAnswers}>{t<string>('practiceExams.sendAnswers')}</Button>,
    [isLastQuestion, clickSubmitAnswers, t],
  );

  const secondsToDate = (t: number): string => {
    if (t) {
      const s = t * 60 * 60;
      const seconds = Math.round(s);
      return `${seconds}`;
    }
    return '';
  };

  const timeLeft = (t: number): string => {
    if (t) {
      const s = t / 60 / 1000;
      const minutes = Math.round(s);

      if (minutes === 0) {
        return `${'> ' + minutes}`;
      } else {
        return `${minutes}`;
      }
    }
    return '';
  };

  // live chat
  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (token.length) {
          const { data } = await Api.get(endpoints.liveChat, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setLiveChatUrl(data.liveChatUrl);
        }
      } catch {
        setLiveChatUrl('');
      }
    })();
  }, [token, setLiveChatUrl]);

  const handleOpenChat = useCallback(() => {
    ((): void => {
      window.open(liveChatUrl, '_blank');
    })();
  }, [liveChatUrl]);

  const closeMinimumTimeDialog = (): void => {
    setShowMinimumTimeMessage(false);
  };

  const closeMinimumExamTimeMessage = () => setShowMinimumExamTimeMessage(false);

  const canShowDialog = (): boolean => {
    if (sectionName === 'PracticeExams') {
      return !!selectedCategory;
    }
    return !!selectedCategory && !categoryLocked;
  };

  const renderQuestionImage = (id: number) => {
    if (!questions) return <></>;

    const question = questions.find((q) => q.id === id);
    if (!question) return <></>;

    const relativeSrc = parseImageSrcFromString(question.description);
    if (!relativeSrc) return <></>;
    return <img width="100%" src={`${process.env.REACT_APP_API_BASE_URL}${relativeSrc}`} alt="i" />;
  };

  const sliderRef = useRef(null);

  /**
   * Scroll to top of the dialog when changing slide
   */
  useEffect(() => {
    if (sliderRef.current) {
      // @ts-ignore
      sliderRef.current.containerNode.parentElement.scrollTo({
        top: 0,
        behaviour: 'smooth',
      });
    }
  }, [slideIndex, questionIndex, sliderRef]);

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={canShowDialog()}
      onClose={handleSlidesPrev}
      fullWidth
      fullScreen={isMobileOrTablet}
    >
      <Dialog open={openStartExam} transitionDuration={0}>
        <DialogContent dividers>
          <Typography gutterBottom>{slides[slideIndex]?.name || ''}</Typography>
          {slides[slideIndex]?.description && (
            <Typography gutterBottom dangerouslySetInnerHTML={{ __html: slides[slideIndex]?.description }} />
          )}
          <Typography gutterBottom>{'Koe aikaa: ' + slides[slideIndex]?.survey_time_limit + 'min' || ''}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStartExamDialog}>{t<string>('misc.cancel')}</Button>
          <Button onClick={handleStartExam}>{t<string>('practiceExams.start')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showSendAnswersDialog} transitionDuration={0}>
        <DialogContent>
          <Typography>You ran out of time!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={clickSubmitAnswers}>{t<string>('practiceExams.sendAnswers')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showMinimumTimeMessage}>
        <DialogContent>
          <Typography>
            {t<string>('practiceExams.minimumTimeMsg')}
            {secondsToDate(slides[slideIndex]?.completion_time)} {t<string>('practiceExams.minimumTimeMsg2')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMinimumTimeDialog} color="primary">
            {t<string>('home.ok')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showMinimumExamTimeMessage}>
        <DialogContent>
          <Typography>
            {t<string>('practiceExams.minimumExamTimeMsg')}
            {timeLeft(practiceExamTimerRef.current)}
            {t<string>('practiceExams.minimumExamTimeMsg2')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMinimumExamTimeMessage} color="primary">
            {t<string>('home.ok')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullScreen open={showExamAnswers}>
        <DialogContent dividers>
          <Container maxWidth="md">
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box>
                  {!!surveyAnswers && surveyAnswers?.exam_passed ? (
                    <Box borderRadius={20} className={classes.greenBox}>
                      <Typography className={classes.whiteText}>{t<string>('practiceExams.passed')} 🥳</Typography>
                    </Box>
                  ) : (
                    <Box borderRadius={20} className={classes.redBox}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <Typography className={classes.whiteText} variant="h3">
                            {t<string>('practiceExams.failed')} 🙁 {t<string>('practiceExams.minScore')}
                          </Typography>{' '}
                        </Grid>
                        <Grid item>
                          <Chip className={classes.whiteBg} label={surveyAnswers?.min_scoring_success} />
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <Typography className={classes.whiteText}>{t<string>('practiceExams.totalScore')}</Typography>{' '}
                        </Grid>
                        <Grid item>
                          <Chip
                            className={classes.whiteBg}
                            label={`${surveyAnswers?.total_correct_answers} /
                        ${surveyAnswers?.max_score}`}
                          />
                        </Grid>
                        <Grid item>
                          <Typography className={classes.whiteText}>oikein</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Grid>

              {surveyAnswers?.sections.map((s, i) => (
                <Fragment key={i}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{s.title}</Typography>
                    <Typography variant="subtitle2" className={classes.sectionScore}>
                      ({s.score}/{s.max_score}) {t<string>('practiceExams.correct')}{' '}
                      {s.score < s.min_scoring_success ? (
                        <>{t<string>('practiceExams.failed')} 🙁</>
                      ) : (
                        <>{t<string>('practiceExams.passed')} 🥳</>
                      )}
                    </Typography>
                  </Grid>

                  <Divider light />

                  {s.questions.map((q, j) => (
                    <Fragment key={j}>
                      <Grid item xs={12} className={classes.questionTitle}>
                        <Typography variant="h5">{q.question}</Typography>
                      </Grid>
                      {renderQuestionImage(q.id)}
                      <Grid item xs={12}>
                        <List>
                          {q.user_answers.map((a, k) => (
                            <>
                              {a.is_correct ? (
                                <Typography color="secondary" variant="caption">
                                  {t<string>('practiceExams.yourAnswers')}
                                </Typography>
                              ) : (
                                <Typography color="error" variant="caption">
                                  {t<string>('practiceExams.yourAnswers')}
                                </Typography>
                              )}
                              <ListItemText key={k}>
                                <Typography className={classes.answerFlex}>
                                  <Typography variant="caption">{a.value}</Typography>
                                  {a.is_correct ? <Check color="secondary" /> : <Cancel color="primary" />}
                                </Typography>
                              </ListItemText>
                            </>
                          ))}
                        </List>

                        <List>
                          <Typography color="secondary" variant="caption">
                            {t<string>('practiceExams.correctAnswers')}
                          </Typography>
                          {q.answers.map((a, k) => (
                            <ListItemText key={k}>
                              <Typography variant="caption">{a.value}</Typography>
                            </ListItemText>
                          ))}
                        </List>
                        {q.question_feedback !== false && typeof q.question_feedback === 'string' && (
                          <List>
                            <Typography color="secondary" variant="caption">
                              {t<string>('practiceExams.feedbackAnswers')}
                            </Typography>
                            <Grid>
                              <ListItemText>
                                <Typography
                                  variant="caption"
                                  dangerouslySetInnerHTML={{ __html: q.question_feedback }}
                                ></Typography>
                              </ListItemText>
                            </Grid>
                          </List>
                        )}
                      </Grid>
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShowExamAnswers}>{t<string>('nav.next')}</Button>
        </DialogActions>
      </Dialog>

      {canShowDialog() && (
        <SwipeableViews
          index={slideIndex}
          disabled
          // @ts-ignore
          ref={(elem: SwipeableViews) => (sliderRef.current = elem)}
        >
          {slides.length > 0 ? (
            slides.map((s, i) => (
              <Box key={i}>
                <DialogTitle>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item container direction="column" alignItems="flex-start">
                      <Button
                        onClick={handleSlidesPrev}
                        startIcon={<ArrowBack />}
                        variant="text"
                        className={classes.categoryPrevButton}
                      >
                        {t<string>('nav.prev')}
                      </Button>
                      <Grid container alignItems="center">
                        {hasChat(s) && (
                          <Grid item xs={8} container justifyContent="flex-start" alignItems="flex-start">
                            <Button className={classes.chatButton} onClick={handleOpenChat}>
                              <ContactSupportIcon className={classes.chatIcon} fontSize="large" />
                              {t<string>('chat.askForHelp')}
                              <FontAwesomeIcon className={classes.externalIcon} icon={faExternalLink} />
                            </Button>{' '}
                          </Grid>
                        )}
                        {hasChat(s) && (
                          <Grid item xs={4} container justifyContent="flex-end" alignItems="flex-end">
                            <Box width="5rem" height="5rem">
                              <Progress progress={examProgress} />
                            </Box>
                          </Grid>
                        )}
                        {!hasChat(s) && (
                          <Grid item xs={4} container justifyContent="flex-start" alignItems="flex-start">
                            <Box width="5rem" height="5rem">
                              <Progress progress={examProgress} />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                      <Box marginTop={4}>
                        {['driving_lesson', 'online_lesson', 'driving_exam'].includes(`${s.content_type}`) && (
                          <Card variant="outlined">
                            <CardContent>
                              <Typography gutterBottom variant="h5" component="div">
                                {s.name}
                              </Typography>
                              {!!s?.description && (
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  dangerouslySetInnerHTML={{ __html: s?.description }}
                                />
                              )}
                              <Grid container alignItems="flex-start" direction="column">
                                <Typography variant="body2" gutterBottom>
                                  {t<string>('slide.duration')}{' '}
                                  <Chip label={`${s.completion_time} ${t<string>('slide.hours')}`} variant="outlined" />
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {t<string>('slide.tags')}{' '}
                                  {s.is_simulator && (
                                    <Chip label={t<string>(`slide.${tags.is_simulator}`)} variant="outlined" />
                                  )}
                                  {!s.is_simulator && (
                                    <Chip label={t<string>(`slide.${tags.is_normal}`)} variant="outlined" />
                                  )}
                                  {s.is_eas_lesson && (
                                    <Chip label={t<string>(`slide.${tags.is_eas_lesson}`)} variant="outlined" />
                                  )}
                                  {s.is_night_driving_lesson && (
                                    <Chip
                                      label={t<string>(`slide.${tags.is_night_driving_lesson}`)}
                                      variant="outlined"
                                    />
                                  )}
                                  {s.is_rtk_lesson && (
                                    <Chip label={t<string>(`slide.${tags.is_rtk_lesson}`)} variant="outlined" />
                                  )}
                                  {s.is_slippery_driving_lesson && (
                                    <Chip
                                      label={t<string>(`slide.${tags.is_slippery_driving_lesson}`)}
                                      variant="outlined"
                                    />
                                  )}
                                </Typography>
                                {!s.completed && (
                                  <Typography variant="body2" gutterBottom>
                                    <Button variant="contained" fullWidth href={urls.calendar}>
                                      {t<string>('slide.reserveLesson')}
                                    </Button>
                                  </Typography>
                                )}
                              </Grid>
                              {s.completed && <Alert severity="success">{t<string>('lessons.completed')}</Alert>}
                            </CardContent>
                          </Card>
                        )}
                        {!['driving_lesson', 'online_lesson', 'driving_exam'].includes(`${s.content_type}`) && (
                          <Typography gutterBottom variant="h5" component="div">
                            {s.name}
                          </Typography>
                        )}
                        <Box>
                          {isLastQuestion && markedQuestions.length > 0 && (
                            <Box>{t<string>('practiceExams.markedQuestions')}</Box>
                          )}
                          {isLastQuestion &&
                            markedQuestions.length > 0 &&
                            markedQuestions.map((m, j) => (
                              <Box key={j}>
                                <Button onClick={() => goToQuestion(m)}>Go to question: {m + 1}</Button>
                              </Box>
                            ))}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </DialogTitle>
                {s.content_type !== 'notification' && (
                  <DialogContent>
                    {outOfTime && <Typography>{t<string>('practiceExams.outOfTime')}</Typography>}
                    {showExamTimer && (
                      <Chip
                        label={new Date(seconds * 1000).toISOString().substr(11, 8)}
                        icon={<Timer />}
                        color="secondary"
                      />
                    )}
                  </DialogContent>
                )}
                <DialogContent className={classes.slides}>
                  {(s.content_type === 'video' || s.content_type === 'document') && (
                    <Embed
                      slide={s}
                      isPaused={s.id === slides[slideIndex].id ? pauseVideo : true}
                      isCompleted={handleComplete}
                      videoStarted={startVideo}
                      videoPlaying={videoPlaying}
                    />
                  )}
                  {s.content_type === 'certification' && <Certification slide={s} />}
                  {s.content_type === 'notification' && !s.completed && (
                    <SimpleDialog text={s.description} isOpen={showNotification} handleClose={closeNotification} />
                  )}
                  {sendAnswersButton}
                </DialogContent>

                <DialogContent className={classes.nextQuestionButton} style={{ paddingBottom: '1rem' }}>
                  <Button
                    onClick={handleNextQuestionClick}
                    color="primary"
                    variant="contained"
                    endIcon={<ArrowForwardRounded />}
                    fullWidth
                    disabled={!buttonStates[i]}
                  >
                    {t<string>('misc.next')}
                  </Button>
                </DialogContent>
              </Box>
            ))
          ) : (
            <>
              <DialogTitle>
                <Grid container alignItems="center">
                  <Grid item xs={2} container>
                    <IconButton onClick={handleSlidesPrev} size="small">
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h2">{t<string>('home.noContent')}</Typography>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </DialogTitle>
              <DialogContent className={classes.nextQuestionButton}>
                <Button
                  onClick={handleNextQuestionClick}
                  color="primary"
                  variant="contained"
                  endIcon={<ArrowForwardRounded />}
                  fullWidth
                >
                  {t<string>('misc.return')}
                </Button>
              </DialogContent>
            </>
          )}
        </SwipeableViews>
      )}
    </Dialog>
  );
};
