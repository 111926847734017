import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { BOTTOM_NAVBAR_HEIGHT } from '../../theme';
import { BottomNavbar } from './BottomNavbar';
import { ReviewDialog, SimpleDialog, ConfirmationDialog } from '../common';
import { useAuthContext, useStudyPathContext } from '../../context';
import { Api } from '../../api';
import { endpoints } from '../../utils';

const useStyles = makeStyles({
  root: {
    //backgroundImage: 'url(images/background.svg)',
    backgroundColor: '#EDF0FA',
    minHeight: '100vh',
  },
  bottomNavbarSpacing: {
    paddingBottom: BOTTOM_NAVBAR_HEIGHT,
  },
});

interface Props {
  children?: React.ReactNode;
  hideBottomNavbar?: boolean;
}

export const MainTemplate: React.FC<Props> = ({ children, hideBottomNavbar }) => {
  const classes = useStyles();
  const { setReviewDialogOpen } = useStudyPathContext();
  const { openInvoicePaid, setOpenInvoicePaid } = useStudyPathContext();
  const { t } = useTranslation();

  const {
    profileData: { latest_driving_session_reviewed, latest_done_driving_lesson_id },
    token,
    logout,
    refreshServerToken,
    expiresIn,
  } = useAuthContext();
  const [instructor, setInstructor] = useState<string>('');

  // idle timer
  const TIMEOUTS = {
    timeout: expiresIn, // 45 minutes
    popupTime: 60 * 1000, // 1 minute
    sec: 1000,
  };
  const [timeToLogout, setTimeToLogOut] = useState(TIMEOUTS.popupTime);
  const [timeOutDialog, setTimeOutDialog] = useState(false);

  // Driving lesson teacher
  useEffect(() => {
    if (!token || !latest_done_driving_lesson_id) return;
    const calendarValue = new Date();

    Api.get(endpoints.getLessons, {
      params: {
        states: 'completed',
        date_from: `${calendarValue.getFullYear()}-${calendarValue.getMonth() + 1}-1 00:00:00`,
      },
      paramsSerializer: (params) => qs.stringify(params),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        if ('error' in data) {
          console.warn(data.error);
          return;
        }

        const lastDoneLesson = data.find((item: { id: number | null }) => item.id === latest_done_driving_lesson_id);
        if (!lastDoneLesson) return;
        setInstructor(lastDoneLesson.instructor.name);
      })
      .catch(() => {
        console.log('Error getting lessons');
      });
  }, [setInstructor, token, latest_done_driving_lesson_id]);
  // Open the review dialog automatically if the user has not yet submitted a review.
  useEffect(() => {
    if (!token || latest_driving_session_reviewed === false) return;
    setReviewDialogOpen(true);
  }, [latest_driving_session_reviewed, setReviewDialogOpen, token]);

  const closeOpenInvoicePaid = useCallback((): void => {
    setOpenInvoicePaid(false);
  }, [setOpenInvoicePaid]);

  const renderBottomNavbar = useMemo(() => !hideBottomNavbar && <BottomNavbar />, [hideBottomNavbar]);
  const renderReviewDialog = useMemo(
    () => (!latest_driving_session_reviewed && instructor.length ? <ReviewDialog instructor={instructor} /> : ''),
    [latest_driving_session_reviewed, instructor],
  );

  useEffect(() => {
    refreshServerToken();
  }, []); //eslint-disable-line

  // idle timer
  const onIdle = useCallback(() => {
    setTimeToLogOut(0);
  }, [setTimeToLogOut]);

  const onPrompt = useCallback(() => {
    setTimeOutDialog(true);
  }, [setTimeOutDialog]);

  // remove 1 second from timeout to give time for
  // dialog to choo se to remaining online or logout
  const timeout = Number(expiresIn) * 1000 - 60000;
  const { isPrompted, start } = useIdleTimer({
    timeout,
    promptTimeout: TIMEOUTS.popupTime,
    onPrompt,
    onIdle,
  });

  useEffect(() => {
    if (!token) return;
    const interval = setInterval(() => {
      if (!isPrompted()) return;

      setTimeToLogOut((value) => value - TIMEOUTS.sec);
    }, TIMEOUTS.sec);
    return () => clearInterval(interval);
  }, [token, TIMEOUTS.sec, TIMEOUTS.popupTime, setTimeToLogOut, isPrompted]);

  useEffect(() => {
    if (timeToLogout > 0) return;
    logout();
  }, [timeToLogout, logout]);

  const handleLogOut = useCallback((): void => {
    setTimeOutDialog(false);
    logout();
  }, [setTimeOutDialog, logout]);

  const resetIdleTime = useCallback(async () => {
    if (token) {
      await Api.post(
        endpoints.refreshToken,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    }
    start();
    setTimeOutDialog(false);
    setTimeToLogOut(timeout);
  }, [setTimeOutDialog, token, start, timeout]);

  return (
    <Box className={clsx(classes.root, !hideBottomNavbar && classes.bottomNavbarSpacing)}>
      <ConfirmationDialog
        text={t<string>('misc.idleTimer', { timeToLogout: timeToLogout / TIMEOUTS.sec })}
        handleYes={handleLogOut}
        handleCancel={resetIdleTime}
        isOpen={timeOutDialog}
        confirmText={t<string>('misc.logout')}
        cancelText={t<string>('misc.keepSignIn')}
      />
      {openInvoicePaid && (
        <SimpleDialog
          text={t<string>('home.openInvoicePaid')}
          isOpen={openInvoicePaid}
          handleClose={closeOpenInvoicePaid}
        />
      )}
      {children}
      {renderBottomNavbar}
      {renderReviewDialog}
    </Box>
  );
};
