import React, { ChangeEventHandler, FormEvent, useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { Header } from '../components';
import { Api, getRegistrationToken } from '../api';
import { endpoints } from '../utils';
import { SimpleDialog } from '../components/common/SimpleDialog';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  paper: {
    marginTop: spacing(2),
    padding: `${spacing(3)} ${spacing(2)}`,
    [breakpoints.up('sm')]: {
      padding: spacing(4),
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: spacing(3),
  },
  textField: {
    marginTop: spacing(2),
  },
  submitButton: {
    marginTop: spacing(2),
  },
  buttonProgress: {
    color: palette.common.white,
  },
}));

const initialValues = {
  email: '',
  sendResetLink: '',
};

export const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [accessToken, setAccessToken] = useState('');
  const { t } = useTranslation();

  const handleTextFieldChange: ChangeEventHandler<HTMLInputElement> = ({ target }) =>
    setValues({ ...values, [target.name]: target.value });

  const closeDialog = (): void => {
    setDialogOpen(false);
  };
  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const { email } = values;
    setSubmitting(true);
    setError('');
    try {
      await Api.get(endpoints.forgotPassword, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          email,
        },
      });
      setDialogOpen(true);
      setMessage(t<string>('forgotPassword.resetLinkSentText'));
      setSubmitting(false);
    } catch {
      setError(t<string>('forgotPassword.errorForgotPassword'));
      setSubmitting(false);
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      const {
        data: { access_token: accessToken },
      } = await getRegistrationToken();

      setAccessToken(accessToken);
    })();
  }, []);

  return (
    <Box>
      <Container maxWidth="sm">
        <Header hidePrev />
        <Paper className={classes.paper}>
          <Typography variant="h2" className={classes.title}>
            {t<string>('forgotPassword.title')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t<string>('forgotPassword.subtitle')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t<string>('userData.email')}
          </Typography>
          <SimpleDialog text={message} isOpen={dialogOpen} handleClose={closeDialog} />
          <form onSubmit={handleSubmit}>
            <TextField
              className={classes.textField}
              value={values.email}
              onChange={handleTextFieldChange}
              label={t<string>('userData.email')}
              name="email"
              required
              disabled={submitting}
            />
            {!!error && (
              <Box marginTop="1rem">
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Button
              fullWidth
              type="submit"
              className={classes.submitButton}
              disabled={submitting}
              endIcon={submitting && <CircularProgress size={20} className={classes.buttonProgress} />}
            >
              {t<string>('forgotPassword.sendResetLink')}
            </Button>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};
