import React, { useMemo, useState, MouseEvent } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { LanguageSelection } from '../common/LanguageSelection';

interface Props {
  titleText?: string;
  hidePrev?: boolean;
  handlePrev?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const Header: React.FC<Props> = ({ hidePrev, handlePrev, titleText }) => {
  const { t } = useTranslation();
  const userLanguage = localStorage.getItem('user_language') ?? 'FI';
  const [selectedLanguage, setSelectedLanguage] = useState(userLanguage);

  const renderBackButton = useMemo(
    () =>
      !hidePrev &&
      !!handlePrev && (
        <Button variant="text" color="default" startIcon={<ArrowBack />} onClick={handlePrev}>
          {t<string>('nav.prev')}
        </Button>
      ),
    [handlePrev, hidePrev, t],
  );

  const renderTitleText = useMemo(
    () =>
      !!titleText && (
        <Box marginTop={2}>
          <Typography variant="h1">{titleText}</Typography>
        </Box>
      ),
    [titleText],
  );

  return (
    <Box paddingY={4}>
      <Grid container>
        <Grid item xs={6}>
          {renderBackButton}
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <LanguageSelection selectedLanguage={selectedLanguage} setLaguage={setSelectedLanguage} />
        </Grid>
      </Grid>
      <Grid container>{renderTitleText}</Grid>
    </Box>
  );
};
