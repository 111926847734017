import React, { useCallback, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { Api } from '../../api';
import { useStudyPathContext, useAuthContext } from '../../context';
import { endpoints } from '../../utils';

const useStyles = makeStyles({
  paper: {
    textAlign: 'center',
    borderRadius: '1.5rem',
  },
});

export const ReviewDialog: React.FC<{ instructor: string }> = ({ instructor }) => {
  const { token, getProfileData } = useAuthContext();

  const classes = useStyles();
  const { reviewDialogOpen, setReviewDialogOpen } = useStudyPathContext();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();

  const handleCloseReviewDialog = useCallback((): void => {
    setReviewDialogOpen(false);
    getProfileData(token);
  }, [setReviewDialogOpen, getProfileData, token]);

  const handleRating = async (value: number | null): Promise<void> => {
    try {
      const { data } = await Api.post(
        endpoints.rateLesson,
        {
          rating: value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        setErrorMessage(data.error);
        setErrorDialogOpen(true);
      }
    } catch {
      // TODO: Add proper error handling here.
      // For now we just close the dialog since we know the endpoint returns 404 always.
      handleCloseReviewDialog();
    }
  };

  const closeErrorDialog = (): void => {
    handleCloseReviewDialog();
    setErrorDialogOpen(false);
  };

  return (
    <Dialog classes={{ paper: classes.paper }} open={reviewDialogOpen} onClose={handleCloseReviewDialog} fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2} container>
            <IconButton onClick={handleCloseReviewDialog}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h3">{t<string>('home.drivingLessonCompletedHeader')}</Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">
          {t<string>('home.drivingLessonCompletedText', { teacherName: instructor })}
        </DialogContentText>
        <Box marginTop={2}>
          <Rating
            onChange={(event, newValue): void => {
              handleRating(newValue);
            }}
            name="rating"
          />
        </Box>
        <Box marginY={2}>
          <Button onClick={handleCloseReviewDialog}>{t<string>('home.submitRating')}</Button>
        </Box>
      </DialogContent>

      <Dialog
        open={errorDialogOpen}
        onClose={closeErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} color="primary">
            {t<string>('home.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};
