import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, InputLabel, Select, TextField, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { useLocaleInfo } from '../../hooks/useLocaleInfo';
import { RegisterFormProps } from '../../types';

export const UserInfo: React.FC<RegisterFormProps> = ({ formErrors, formValues, handleChange }) => {
  const { t } = useTranslation();
  const { countries, languages, error } = useLocaleInfo();

  const hasError = (key: string): boolean => formErrors.some((e) => e.path[0] === key);

  const renderFormErrorHelperText = (key: string, translationID: string): JSX.Element | void => {
    if (hasError(key)) {
      return <FormHelperText>{t<string>(translationID)}</FormHelperText>;
    }
  };

  return (
    <>
      {error && (
        <Box marginBottom={3}>
          <Alert severity="error">{t<string>('misc.apiError')}</Alert>
        </Box>
      )}
      <FormControl error={hasError('nin')}>
        <>
          <TextField
            data-testid="nin-wrapper"
            label={t<string>('userData.nin')}
            name="nin"
            defaultValue={formValues.nin}
            required
            onChange={handleChange}
            value={formValues.nin}
            error={hasError('nin')}
            inputProps={{
              'data-testid': 'nin',
            }}
            helperText={t<string>('userData.ninHelperText')}
          />
          {renderFormErrorHelperText('nin', 'formValidation.ninInvalid')}
        </>
      </FormControl>
      <FormControl error={hasError('last_name')}>
        <>
          <TextField
            data-testid="lastName-wrapper"
            label={t<string>('userData.lastName')}
            name="last_name"
            defaultValue={formValues.last_name}
            required
            onChange={handleChange}
            error={hasError('last_name')}
            inputProps={{
              'data-testid': 'last_name',
            }}
          />
          {renderFormErrorHelperText('last_name', 'formValidation.lastNameInvalid')}
        </>
      </FormControl>
      <FormControl error={hasError('first_name')}>
        <>
          <TextField
            data-testid="firstName-wrapper"
            label={t<string>('userData.firstName')}
            name="first_name"
            defaultValue={formValues.first_name}
            required
            onChange={handleChange}
            error={hasError('first_name')}
            inputProps={{
              'data-testid': 'first_name',
            }}
          />
          {renderFormErrorHelperText('first_name', 'formValidation.firstNameInvalid')}
        </>
      </FormControl>
      <FormControl error={hasError('street')}>
        <>
          <TextField
            data-testid="street-wrapper"
            label={t<string>('userData.street')}
            name="street"
            defaultValue={formValues.street}
            required
            onChange={handleChange}
            error={hasError('street')}
            inputProps={{
              'data-testid': 'street',
            }}
          />
          {renderFormErrorHelperText('street', 'formValidation.addressRequired')}
        </>
      </FormControl>
      <FormControl error={hasError('zip')}>
        <>
          <TextField
            data-testid="zip-wrapper"
            label={t<string>('userData.zip')}
            name="zip"
            defaultValue={formValues.zip}
            required
            onChange={handleChange}
            error={hasError('zip')}
            inputProps={{
              'data-testid': 'zip',
            }}
          />
          {renderFormErrorHelperText('zip', 'formValidation.zipInvalid')}
        </>
      </FormControl>
      <FormControl error={hasError('city')}>
        <>
          <TextField
            data-testid="city-wrapper"
            label={t<string>('userData.city')}
            name="city"
            defaultValue={formValues.city}
            required
            onChange={handleChange}
            error={hasError('city')}
            inputProps={{
              'data-testid': 'city',
            }}
          />
          {renderFormErrorHelperText('city', 'formValidation.cityRequired')}
        </>
      </FormControl>
      <FormControl error={hasError('home_city')}>
        <>
          <TextField
            data-testid="homeCity-wrapper"
            label={t<string>('userData.homeCity')}
            name="home_city"
            defaultValue={formValues.home_city}
            required
            onChange={handleChange}
            error={hasError('home_city')}
            inputProps={{
              'data-testid': 'home_city',
            }}
          />
          {renderFormErrorHelperText('home_city', 'formValidation.homeCityRequired')}
        </>
      </FormControl>
      <FormControl error={hasError('phone')}>
        <>
          <TextField
            data-testid="phone-wrapper"
            label={t<string>('userData.phone')}
            name="phone"
            defaultValue={formValues.phone}
            required
            onChange={handleChange}
            error={hasError('phone')}
            inputProps={{
              'data-testid': 'phone',
            }}
          />
          {renderFormErrorHelperText('phone', 'formValidation.phoneInvalid')}
        </>
      </FormControl>
      <FormControl error={hasError('email')}>
        <>
          <TextField
            data-testid="email-wrapper"
            label={t<string>('userData.email')}
            name="email"
            defaultValue={formValues.email}
            required
            onChange={handleChange}
            error={hasError('email')}
            inputProps={{
              'data-testid': 'email',
            }}
          />
          {renderFormErrorHelperText(
            'email',
            formErrors.find((e) => e.path[0] === 'email' && e.code === 'custom')?.message ||
              'formValidation.invalidEmail',
          )}
        </>
      </FormControl>
      <FormControl error={hasError('confirm_email')}>
        <>
          <TextField
            data-testid="confirmEmail-wrapper"
            label={t<string>('userData.confirmEmail')}
            name="confirm_email"
            defaultValue={formValues.confirm_email}
            required
            onChange={handleChange}
            error={hasError('confirm_email')}
            inputProps={{
              'data-testid': 'confirm_email',
            }}
          />
          {renderFormErrorHelperText('confirm_email', 'formValidation.emailMismatch')}
        </>
      </FormControl>
      <FormControl required variant="outlined" error={hasError('birth_country')}>
        <>
          <InputLabel shrink id="label_birth_country">
            {t<string>('userData.birthCountry')}
          </InputLabel>
          <Select
            displayEmpty
            data-testid="birthCountry-wrapper"
            label={t<string>('userData.birthCountry')}
            labelId="label_birth_country"
            required
            name="birth_country"
            defaultValue={formValues.birth_country}
            onChange={handleChange}
            inputProps={{
              'data-testid': 'birth_country',
            }}
            key={countries.length} // Force update on default value when countries are fetched.
          >
            <option value="">---</option>
            {countries.map((c) => (
              <option value={c.external_id} key={c.external_id}>
                {c.name}
              </option>
            ))}
          </Select>
          {renderFormErrorHelperText('birth_country', 'formValidation.birthCountryRequired')}
        </>
      </FormControl>
      <FormControl required variant="outlined" error={hasError('native_language')}>
        <>
          <InputLabel shrink id="label_native_language">
            {t<string>('userData.nativeLanguage')}
          </InputLabel>
          <Select
            data-testid="nativeLanguage-wrapper"
            label={t<string>('userData.nativeLanguage')}
            labelId="label_native_language"
            required
            name="native_language"
            defaultValue={formValues.native_language}
            onChange={handleChange}
            error={hasError('native_language')}
            inputProps={{
              'data-testid': 'native_language',
            }}
            key={languages.length} // Force update on default value when languages are fetched.
          >
            <option value="">---</option>
            {languages.map((l) => (
              <option value={l.code} key={l.code}>
                {l.name}
              </option>
            ))}
          </Select>
          {renderFormErrorHelperText('native_language', 'formValidation.nativeLanguageRequired')}
        </>
      </FormControl>
    </>
  );
};
