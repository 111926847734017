import React, { useMemo, useState, useCallback } from 'react';
import Vimeo from '@u-wave/react-vimeo';

import { Toolbar, Box, Dialog, makeStyles, IconButton, AppBar } from '@material-ui/core';
import Fullscreen from '@material-ui/icons/Fullscreen';
import Close from '@material-ui/icons/Close';
import { Slide } from '../../types';
import { colors } from '../../theme';
import { endpoints } from '../../utils';
import { baseURL } from '../../api';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    minHeight: '25rem',
  },
  AppBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  embed: {
    position: 'absolute',
    margin: 'auto',
    width: '100%',
    height: '100%',
    border: 'none',
  },
  dialog: {
    padding: 0,
  },
  video: {
    width: '100%',
    padding: 0,
    background: colors.primary,
  },
  appbar: {
    background: 'transparent',
    backgroundColor: 'transparent',
  },
  externalLink: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface Props {
  slide: Slide;
  isPaused?: boolean;
  isCompleted?: (slide_id: number, completed: boolean) => void;
  videoStarted?: () => void;
  videoPlaying?: () => void;
}

export const Embed: React.FC<Props> = ({
  slide: { url, id, content_type, description, embed_url },
  isPaused,
  isCompleted,
  videoStarted,
  videoPlaying,
}) => {
  const classes = useStyles();
  const [fs, setFs] = useState(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [videoHasStarted, setVideoHasStarted] = useState(false);
  const videoEnded = (): void => {
    if (isCompleted) {
      isCompleted(id, true);
    }
  };

  const renderIframe = useMemo(
    () => <iframe src={`${url}#toolbar=0`} title="PDF" className={classes.embed} />,
    [classes.embed, url],
  );

  const handleVideoEnd = (): void => {
    setCurrentTime(0);
    videoEnded();
  };

  const onVideoStart = useCallback((): void => {
    if (!videoHasStarted) {
      if (videoStarted) {
        videoStarted();
        setVideoHasStarted(true);
      }
    }
  }, [videoHasStarted, setVideoHasStarted, videoStarted]);

  const onVideoPlaying = useCallback((): void => {
    if (videoPlaying) {
      videoPlaying();
    }
  }, [videoPlaying]);

  if (!!url && url.includes('vimeo')) {
    if (!fs) {
      return (
        <Box className={classes.root}>
          <Vimeo
            className={classes.embed}
            keyboard={false}
            video={url}
            controls
            onEnd={handleVideoEnd}
            paused={isPaused}
            autopause
            responsive
            //@ts-ignore
            onPlaying={onVideoPlaying}
            onPlay={onVideoStart}
            start={currentTime}
            onTimeUpdate={(duration): void => setCurrentTime(duration.seconds)}
          />
          <Box>
            <IconButton color="primary" onClick={(): void => setFs(true)}>
              <Fullscreen />
            </IconButton>
          </Box>
        </Box>
      );
    }
    return (
      <Dialog open fullWidth fullScreen className={classes.dialog}>
        <AppBar className={classes.AppBar}>
          <Toolbar>
            <IconButton onClick={(): void => setFs(false)}>
              <Close color="primary" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Vimeo
          video={url}
          controls
          onEnd={handleVideoEnd}
          paused={isPaused}
          autopause
          responsive
          start={currentTime}
          onTimeUpdate={(duration): void => setCurrentTime(duration.seconds)}
        />
      </Dialog>
    );
  }

  if (embed_url) {
    return (
      <Box height="90%" className={classes.root}>
        <iframe src={`${embed_url}#toolbar=0`} title="PDF" className={classes.embed} />
      </Box>
    );
  }

  return (
    <>
      {content_type === 'infographic' && <img src={`${baseURL}${endpoints.embedded(id)}`} alt="material" />}
      {(content_type === 'document' || content_type === 'link') && !!description && (
        <Box className={classes.externalLink}>
          <Box textAlign="left" dangerouslySetInnerHTML={{ __html: description }} />
        </Box>
      )}
      {!!url && <Box height="90%">{renderIframe}</Box>}
    </>
  );
};
