import React, { useEffect } from 'react';
import { makeStyles, List, ListItem, ListItemText, Link } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Api } from '../api';
import { Header, MainTemplate, SlidesDialog } from '../components';
import { useAuthContext, useStudyPathContext } from '../context';
import { Section } from '../types';
import { endpoints } from '../utils';

const useStyles = makeStyles(({ palette, spacing }) => ({
  liContainer: {
    marginTop: spacing(2),
    borderTop: `0.05rem solid ${palette.grey[400]}`,
  },
  title: {
    '& svg': {
      transform: 'rotateZ(0)',
      transitionProperty: 'transform',
      transitionDelay: '0.04s',
    },
  },

  accPanel: {
    display: 'none',
    paddingLeft: '32px',

    '& span': {
      fontSize: '16px',
    },
  },

  open: {
    display: 'flex',

    '& svg': {
      transform: 'rotateZ(180deg)',
    },
  },
}));

export const PracticeExams: React.FC = () => {
  const {
    profileData: { current_course },
    token,
  } = useAuthContext();

  const {
    setSections,
    setSelectedCategory,
    selectedCategory,
    categories,
    setCategories,
    setSlides,
    setQuestionsList,
    setExamIndex,
  } = useStudyPathContext();
  const classes = useStyles();
  const { t } = useTranslation();

  // Fetch all sections for the course, find the self-study section and fill categories based on that.
  useEffect(() => {
    setQuestionsList([]);
    setExamIndex(0);
    setCategories([]);
    if (current_course) {
      (async (): Promise<void> => {
        try {
          const { data } = await Api.get<Section[]>(endpoints.slides(current_course), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const sections = data.filter((s) => s.section_type === 'self_learning');
          setSections(sections);
          setCategories(sections[0].categories || []);
        } catch {
          setSections([]);
        }
      })();
    }
  }, [current_course, setCategories, setSections, token, setQuestionsList, setExamIndex]);

  // Sets the Slides when a Self-Study Content is selected (aka. clicked upon)
  useEffect(() => {
    setSlides((!!selectedCategory && categories.find((c) => c.id === selectedCategory.id)?.slides) || []);
  }, [categories, selectedCategory, setSlides]);

  // creates accordion from this bulk of questions, takes titles id and passed it to childrens classes
  useEffect(() => {
    const titles = document.querySelector('.accordion-wrapper');
    //@ts-ignore
    const children = Array.from(titles.children) as HTMLCollectionOf<HTMLElement>;
    let i = 'placeholder';

    //@ts-ignore
    children.forEach((element) => {
      if (element.classList.contains('acc-panel')) {
        element.classList.add(i);
        element.classList.add(classes.accPanel);
      } else if (element.classList.contains('acc-title')) {
        i = element.id;
        element.classList.add(i);
        element.classList.add(classes.title);
      }
    });
  });

  //toggle open panels that contain with clickd id
  // any is Category
  function toggleOpen(c: any) {
    const titles = document.querySelectorAll<HTMLElement>('.id-' + c.id);

    titles.forEach((title) => {
      title.classList.toggle(classes.open);
    });
  }

  return (
    <MainTemplate>
      <Container>
        <Header titleText={t<string>('practiceExams.header')} hidePrev />
      </Container>
      <Container disableGutters>
        <Container className={classes.liContainer} disableGutters>
          <List className={'accordion-wrapper'}>
            {categories.map((c) => {
              return c.is_category_title ? (
                <ListItem
                  id={'id-' + c.id}
                  key={c.id}
                  component={Link}
                  onClick={(): void => {
                    toggleOpen(c);
                  }}
                  className={'acc-title'}
                >
                  <ListItemText primary={c.name} />
                  <KeyboardArrowDown />
                </ListItem>
              ) : (
                <ListItem
                  key={c.id}
                  component={Link}
                  onClick={(): void => {
                    setSelectedCategory(c);
                  }}
                  className={'acc-panel'}
                >
                  <ListItemText primary={c.name} />
                </ListItem>
              );
            })}
          </List>
          <SlidesDialog sectionName="PracticeExams" />
        </Container>
      </Container>
    </MainTemplate>
  );
};
