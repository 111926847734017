import React, { useState, useEffect, MouseEvent, FormEvent, ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Select,
  InputLabel,
  FormGroup,
  FormHelperText,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DateTime } from 'luxon';
import { Api } from '../../api';
import { endpoints } from '../../utils';
import { RegisterFormEvent, RegisterFormProps, License } from '../../types';

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  formLabel: {
    marginBottom: spacing(1),
  },
  licenseChoices: {
    marginBottom: spacing(3),
  },
  licenseRadioButton: {
    display: 'none',
  },
  licenseRadioLabel: {
    color: palette.text.primary,
    padding: `${spacing(1)} ${spacing(2)}`,
    background: 'transparent',
    margin: `0 ${spacing(1)} ${spacing(1)} 0`,
    border: `1px solid ${palette.text.secondary}`,
    borderRadius: shape.borderRadius,
  },
  selectedLicense: {
    background: palette.primary.main,
    color: palette.primary.contrastText,
    borderColor: palette.primary.main,
  },
  dateSelect: {
    width: '33%',
  },
  dateSelectInput: {
    margin: '0',
    padding: '0',
  },
  dateSelectLabel: {
    fontSize: '0.8rem',
  },
}));

export const Licenses: React.FC<RegisterFormProps> = ({ formValues, formErrors, handleChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedLicenses, setSelectedLicenses] = useState<string[]>(formValues.licenses);
  const [licenses, setLicenses] = useState<string[]>([]);
  const [transmissionTypePreference, setTransmissionTypePreference] = useState<string>('manual');
  const currentYear = DateTime.now().year;

  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await Api.get<License[]>(endpoints.getLicences);
      setLicenses(data.map((l) => l.code));
    })();
  }, []);

  const updateSelection = (e: MouseEvent<HTMLButtonElement>): void => {
    // @ts-ignore: The typings for the event type does not contain the `target` key while it actually does.
    const val = e.target.value;

    if (selectedLicenses.includes(val)) {
      setSelectedLicenses(selectedLicenses.filter((l) => l !== val));
    } else {
      setSelectedLicenses([...selectedLicenses, val]);
    }
  };

  const handleLicensesChange = (e: FormEvent): void => {
    // @ts-ignore: The typings for the event type does not contain the `target` key while it actually does.
    const val = e.target.value;
    let licenses = selectedLicenses;

    if (!selectedLicenses.some((l) => l === val)) {
      licenses.push(val);
    } else {
      licenses = licenses.filter((l) => l !== val);
    }

    handleChange({ name: 'licensesEvent', licenses } as RegisterFormEvent);
  };

  const handleTransmissionTypeChange = (e: ChangeEvent<HTMLInputElement>, value: string): void => {
    setTransmissionTypePreference(value);
    handleChange(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.licenseChoices}>
        {formValues.can_be_driven_automatic && (
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.formLabel}>
              {t<string>('userData.transmissionTypePreference')}
            </FormLabel>
            <RadioGroup
              value={transmissionTypePreference}
              aria-label={t<string>('userData.transmissionTypePreference')}
              name="transmission_type_preference"
              onChange={handleTransmissionTypeChange}
            >
              <FormControlLabel
                key="manual"
                value="manual"
                label={t<string>('userData.manualTransmissionType')}
                control={<Radio />}
              />
              <FormControlLabel
                key="automatic"
                value="automatic"
                label={t<string>('userData.automaticTransmissionType')}
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        )}

        <FormControl component="fieldset">
          <FormLabel component="legend" className={classes.formLabel}>
            {t<string>('userData.currentLicenses')}
          </FormLabel>
          <FormGroup
            row
            data-testid="licenses"
            onChange={handleLicensesChange}
            aria-label={t<string>('userData.currentLicenses')}
          >
            {licenses.map((l: string) => (
              <FormControlLabel
                key={l}
                className={`${classes.licenseRadioLabel} ${
                  selectedLicenses.includes(l) ? classes.selectedLicense : ''
                }`}
                labelPlacement="top"
                value={l}
                name="licenses"
                label={l}
                control={<Checkbox onClick={updateSelection} className={classes.licenseRadioButton} />}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" size="small" className={classes.dateSelect}>
          <InputLabel shrink className={classes.dateSelectLabel} id="label_day">
            {t<string>('userData.day')}
          </InputLabel>
          <Select
            className={classes.dateSelectInput}
            data-testid="licenseAcquired-days"
            label={t<string>('userData.days')}
            labelId="label_days"
            required
            name="license_day"
            onChange={handleChange}
            defaultValue={formValues.license_day}
            inputProps={{
              'data-testid': 'days',
            }}
          >
            <option value="">---</option>
            {[...new Array(31)].map((_, d: number) => (
              <option value={String(d + 1)} key={d + 1}>
                {d + 1}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" className={classes.dateSelect}>
          <InputLabel shrink className={classes.dateSelectLabel} id="label_month">
            {t<string>('userData.month')}
          </InputLabel>
          <Select
            data-testid="licenseAcquired-months"
            label={t<string>('userData.months')}
            labelId="label_months"
            required
            name="license_month"
            onChange={handleChange}
            defaultValue={formValues.license_month}
            inputProps={{
              'data-testid': 'months',
            }}
          >
            <option value="">---</option>
            {[...new Array(12)].map((_, d: number) => (
              <option value={String(d + 1)} key={d + 1}>
                {d + 1}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" className={classes.dateSelect}>
          <InputLabel shrink className={classes.dateSelectLabel} id="label_year">
            {t<string>('userData.year')}
          </InputLabel>
          <Select
            data-testid="licenseAcquired-years"
            label={t<string>('userData.year')}
            labelId="label_years"
            required
            name="license_year"
            onChange={handleChange}
            defaultValue={formValues.license_year}
            inputProps={{
              'data-testid': 'years',
            }}
          >
            <option value="">---</option>
            {[...new Array(100)].map((_, d: number) => (
              <option value={String(currentYear - d)} key={currentYear - d}>
                {currentYear - d}
              </option>
            ))}
          </Select>
        </FormControl>
        {formErrors.some((e) => e.path[0] === 'license_date_required') && (
          <FormHelperText error>{t<string>('formValidation.licenseDateMissing')}</FormHelperText>
        )}
        {formErrors.some((e) => e.path[0] === 'license_date_validation') && (
          <FormHelperText error>{t<string>('formValidation.invalidLicenseDate')}</FormHelperText>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          label={t<string>('userData.has_professional_competence')}
          name="has_professional_competence"
          onChange={handleChange}
          control={
            <Checkbox defaultChecked={formValues.has_professional_competence} name="has_professional_competence" />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          label={t<string>('userData.additional_info')}
          name="additional_info"
          defaultValue={formValues.additional_info}
          onChange={handleChange}
          rows={5}
        />
      </Grid>
    </Grid>
  );
};
