import React from 'react';
import { useTranslation } from 'react-i18next';
import { FinnishSSN } from 'finnish-ssn';
import { FormControl, FormHelperText, FormLabel, InputLabel, Select, TextField } from '@material-ui/core';
import { CheckoutFormProps } from '../../types';
import { useLocaleInfo } from '../../hooks/useLocaleInfo';

export const OtherPayer: React.FC<CheckoutFormProps> = ({
  formValues: { nin_other, birth_country },
  formErrors,
  handleChange,
}) => {
  const { t } = useTranslation();
  let ageInYears = null;
  const { countries } = useLocaleInfo();
  if (FinnishSSN.validate(nin_other)) {
    const { ageInYears: _ageInYears } = FinnishSSN.parse(nin_other);
    ageInYears = _ageInYears;
  }

  const hasError = (key: string): boolean => formErrors.some((e) => e.path[0] === key);
  const tooYoung = ageInYears !== null && ageInYears < 18;

  const renderFormErrorHelperText = (key: string, translationID: string): JSX.Element | void => {
    if (hasError(key)) {
      return <FormHelperText>{t<string>(translationID)}</FormHelperText>;
    }
  };

  return (
    <>
      <FormLabel component="legend">{t<string>('courseWebshop.PayerDetails')}</FormLabel>
      <FormControl error={hasError('nin_other')}>
        <>
          <TextField
            label={t<string>('userData.nin')}
            name="nin_other"
            onChange={handleChange}
            required
            error={hasError('nin_other')}
          />
          {renderFormErrorHelperText('nin_other', 'formValidation.ninInvalid')}
          {tooYoung && <FormHelperText error>{t<string>('userData.otherPayerTooYoung')}</FormHelperText>}
        </>
      </FormControl>
      <FormControl error={hasError('last_name_other')}>
        <>
          <TextField
            label={t<string>('userData.lastName')}
            name="last_name_other"
            onChange={handleChange}
            required
            error={hasError('last_name_other')}
          />
          {renderFormErrorHelperText('last_name_other', 'formValidation.lastNameInvalid')}
        </>
      </FormControl>
      <FormControl error={hasError('first_names_other')}>
        <>
          <TextField
            label={t<string>('userData.firstName')}
            name="first_names_other"
            onChange={handleChange}
            required
            error={hasError('first_names_other')}
          />
          {renderFormErrorHelperText('first_names_other', 'formValidation.firstNameInvalid')}
        </>
      </FormControl>
      <FormControl error={hasError('street_other')}>
        <>
          <TextField
            label={t<string>('userData.street')}
            name="street_other"
            onChange={handleChange}
            required
            error={hasError('street_other')}
          />
          {renderFormErrorHelperText('street_other', 'formValidation.addressRequired')}
        </>
      </FormControl>
      <FormControl error={hasError('zip_other')}>
        <>
          <TextField
            label={t<string>('userData.zip')}
            name="zip_other"
            onChange={handleChange}
            required
            error={hasError('zip_other')}
          />
          {renderFormErrorHelperText('zip_other', 'formValidation.zipInvalid')}
        </>
      </FormControl>
      <FormControl error={hasError('city_other')}>
        <>
          <TextField
            label={t<string>('userData.city')}
            name="city_other"
            onChange={handleChange}
            required
            error={hasError('city_other')}
          />
          {renderFormErrorHelperText('city_other', 'formValidation.cityRequired')}
        </>
      </FormControl>
      <FormControl error={hasError('phone_other')}>
        <>
          <TextField
            label={t<string>('userData.phone')}
            name="phone_other"
            onChange={handleChange}
            required
            error={hasError('phone_other')}
          />
          {renderFormErrorHelperText('phone_other', 'formValidation.phoneInvalid')}
        </>
      </FormControl>
      <FormControl error={hasError('email_other')}>
        <>
          <TextField
            label={t<string>('userData.email')}
            name="email_other"
            onChange={handleChange}
            required
            error={hasError('email_other')}
          />
          {renderFormErrorHelperText('email_other', 'formValidation.invalidEmail')}
        </>
      </FormControl>
      <FormControl error={hasError('confirm_email_other')}>
        <>
          <TextField
            label={t<string>('userData.confirmEmail')}
            name="confirm_email_other"
            onChange={handleChange}
            required
            error={hasError('confirm_email_other')}
          />
          {renderFormErrorHelperText('confirm_email_other', 'formValidation.emailMismatch')}
        </>
      </FormControl>
      <FormControl required variant="outlined" error={hasError('birth_country')}>
        <>
          <InputLabel shrink id="label_birth_country">
            {t<string>('userData.birthCountry')}
          </InputLabel>
          <Select
            displayEmpty
            data-testid="birthCountry-wrapper"
            label={t<string>('userData.birthCountry')}
            labelId="label_birth_country"
            required
            name="birth_country"
            defaultValue={birth_country}
            onChange={handleChange}
            inputProps={{
              'data-testid': 'birth_country',
            }}
            key={countries.length} // Force update on default value when countries are fetched.
          >
            <option value="">---</option>
            {countries.map((c) => (
              <option value={c.external_id} key={c.external_id}>
                {c.name}
              </option>
            ))}
          </Select>
          {renderFormErrorHelperText('birth_country', 'formValidation.birthCountryRequired')}
        </>
      </FormControl>
    </>
  );
};
