import React, { useState, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Card, Button, CardContent, Typography, Box, Chip, Grid, makeStyles } from '@material-ui/core';
import { Header, MainTemplate } from '../components';
import { useAuthContext } from '../context';
import { endpoints, urls } from '../utils';
import { Enrollment } from '../types';
import { Api } from '../api';

const useStyles = makeStyles(({ palette, spacing }) => ({
  courseCardContainer: {
    marginBottom: spacing(2),
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  courseCardTitle: {
    fontSize: '16px',
    fontWeight: 400,
  },
}));

export const MyCourses: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [enrollments, setEnrollments] = useState<Enrollment[]>([]);
  const { token, logout } = useAuthContext();
  const { t } = useTranslation();

  const handlePrev = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    history.push(urls.profile);
  };

  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await Api.get(endpoints.enrollments, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setEnrollments(data);
    })();
  }, [token, history, logout]);

  return (
    <MainTemplate>
      <Container>
        <Header handlePrev={handlePrev} titleText={t<string>('myCourses.title')} />
        <Box>
          {enrollments.map((course) => (
            <Card key={course.id} className={classes.courseCardContainer}>
              <CardContent className={classes.courseCard}>
                <Grid container>
                  <Grid item container justifyContent="flex-start">
                    {course.completed ? (
                      <Chip color="secondary" label={t<string>('myCourses.completed')} />
                    ) : (
                      <Chip label={t<string>('myCourses.ongoing')} />
                    )}
                  </Grid>
                </Grid>
                <Typography color="textSecondary" variant="body2">
                  {course.description}
                </Typography>

                {/* Approved Terms for Enrolled Course */}
                <div>
                  <Typography color="textPrimary" variant="h4">
                    {t<string>('myCourses.agreedTerms')}
                  </Typography>
                  {course.user_terms_text && <div dangerouslySetInnerHTML={{ __html: course.user_terms_text }} />}
                  {!!course.user_terms_link && (
                    <Button href={course.user_terms_link}>{t<string>('myCourses.agreedTermsDownload')}</Button>
                  )}
                </div>
                <Typography variant="h3" className={classes.courseCardTitle}>
                  {course.name}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Container>
    </MainTemplate>
  );
};
