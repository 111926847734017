import React, { MouseEvent, useEffect, useState } from 'react';

import { Container, makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { Alert } from '@material-ui/lab';
import * as zod from 'zod';
import { FinnishSSN } from 'finnish-ssn';
import { MainTemplate, CheckoutComplete, CheckoutView, Error } from '../components';
import { RegisterFormEvent, CheckoutFormValues } from '../types';
import { useQueryParams } from '../hooks';
import { Api } from '../api';
import { PHONE_NUMBER_REGEX, ZIP_CODE_REGEX, endpoints } from '../utils';
import { useAuthContext } from '../context';
import axios from 'axios';

const useStyles = makeStyles(({ spacing }) => ({
  alert: {
    marginTop: spacing(2),
  },
}));

const otherValidation = zod
  .object({
    nin_other: zod
      .string()
      .min(1)
      .refine((val) => FinnishSSN.validate(val.toUpperCase())),
    last_name_other: zod.string().min(1),
    first_names_other: zod.string().min(1),
    street_other: zod.string().min(1),
    city_other: zod.string().min(1),
    zip_other: zod.string().min(1).regex(ZIP_CODE_REGEX),
    phone_other: zod.string().min(1).regex(PHONE_NUMBER_REGEX),
    email_other: zod.string().email().min(1),
    confirm_email_other: zod.string().email().min(1),
  })
  .refine((data: Record<string, unknown>) => data.email_other === data.confirm_email_other, {
    path: ['confirm_email'],
  });

const step1Validation = zod.object({
  payer: zod.string().min(1),
  payment: zod.string().min(1),
});

const validationSchema = [step1Validation, otherValidation];

// FIXME: If previous has parental_consent set, this is not reflected in the formValues!
const DEFAULT_FORM_VALUES: CheckoutFormValues = {
  selected_school: '',
  selected_course: '',
  nin: '',
  last_name: '',
  first_name: '',
  street: '',
  zip: '',
  city: '',
  home_city: '',
  phone: '',
  email: '',
  confirm_email: '',
  birth_country: '',
  native_language: '',
  licenses: [],
  has_professional_competence: false,
  additional_info: '',
  parental_consent: false,
  has_agreed: false,
  payer: 'self',
  payment: '',
  license_day: '',
  license_month: '',
  license_year: '',
  recurrences: 1,
  nin_other: '',
  first_names_other: '',
  last_name_other: '',
  street_other: '',
  zip_other: '',
  city_other: '',
  phone_other: '',
  email_other: '',
  confirm_email_other: '',
};

const TOTAL_STEPS = 3;

interface RegisterPayload
  extends Pick<
    CheckoutFormValues,
    | 'nin'
    | 'first_name'
    | 'last_name'
    | 'street'
    | 'zip'
    | 'city'
    | 'phone'
    | 'email'
    | 'recurrences'
    | 'home_city'
    | 'parental_consent'
    | 'licenses'
    | 'has_professional_competence'
  > {
  payment_method: CheckoutFormValues['payment'];
  nationality: CheckoutFormValues['birth_country'];
  other_payer: boolean;
  invoicing_details: Partial<
    Pick<CheckoutFormValues, 'nin' | 'first_name' | 'last_name' | 'street' | 'zip' | 'city' | 'phone' | 'email'>
  >;
}

interface CheckoutReturnValues {
  html_snippet: string | null;
  student_user_id: number | null;
  order_id: number | null;
}

const DEFAULT_REGISTRATION_RETURN_VALUES: CheckoutReturnValues = {
  html_snippet: null,
  student_user_id: null,
  order_id: null,
};

export const Checkout: React.FC = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
  const [currentStep, setCurrentStep] = useState(1);
  const [formErrors, setFormErrors] = useState<zod.ZodIssue[]>([]);
  const [checkoutError, setCheckoutError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const selectedProduct = queryParams.get('product');
  const quantity = queryParams.get('quantity');
  const { token, logActivity } = useAuthContext();

  const [registrationReturnValues, setCheckoutReturnValues] = useState<CheckoutReturnValues>(
    DEFAULT_REGISTRATION_RETURN_VALUES,
  );

  useEffect(() => {
    const checkoutContainer = document.getElementById('klarna');
    if (checkoutContainer !== null) {
      checkoutContainer.innerHTML = registrationReturnValues.html_snippet || '';
      const scriptsTags = checkoutContainer.getElementsByTagName('script');
      for (let i = 0; i < scriptsTags.length; i += 1) {
        const { parentNode } = scriptsTags[i];
        if (parentNode !== null) {
          const newScriptTag = document.createElement('script');
          newScriptTag.type = 'text/javascript';
          newScriptTag.text = scriptsTags[i].text;
          parentNode.removeChild(scriptsTags[i]);
          parentNode.appendChild(newScriptTag);
        }
      }
    }
  }, [registrationReturnValues]);

  const scrollToTop = (): void =>
    window.scrollTo({
      top: 0,
    });

  const postProduct = async (data: RegisterPayload, token: string): Promise<void> => {
    try {
      const returnVals = await Api.post(endpoints.productOrder(Number(selectedProduct), Number(quantity)), data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const vals: CheckoutReturnValues = returnVals.data;
      setCheckoutReturnValues(vals);
      logActivity('PRODUCT_ORDER', `${selectedProduct}`);
      if (formValues.payment === 'anders_klarna') {
        setIsLoading(false);
      } else {
        setCurrentStep(currentStep + 1);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      if (axios.isAxiosError(e)) {
        setCheckoutError(e.response?.data.message);
      }
    }
  };

  const handlePrev = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setIsLoading(true);

    if (currentStep > 1) {
      scrollToTop();
      setCurrentStep(currentStep - 1);
      setIsLoading(false);
    }
  };

  const parseForm = ({
    payment,
    recurrences,
    birth_country,
    payer,
    nin_other,
    first_names_other,
    last_name_other,
    street_other,
    zip_other,
    city_other,
    phone_other,
    email_other,
  }: CheckoutFormValues): RegisterPayload => {
    const payload: RegisterPayload = {
      recurrences: Number(recurrences),
      payment_method: payment,
      nationality: birth_country,
      other_payer: payer === 'other',
      invoicing_details: {},
    };

    if (payer === 'other') {
      payload.invoicing_details = {
        nin: nin_other,
        first_name: first_names_other,
        last_name: last_name_other,
        street: street_other,
        zip: zip_other,
        city: city_other,
        phone: phone_other,
        email: email_other,
      };
    }

    return payload;
  };

  const handleNext = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    scrollToTop();
    setFormErrors([]); // Set errors to none if everything was successful.

    const {
      payment,
      payer,
      nin_other,
      first_names_other,
      last_name_other,
      street_other,
      zip_other,
      city_other,
      phone_other,
      email_other,
      confirm_email_other,
    } = formValues;

    try {
      const step1 = {
        payer,
        payment,
      };

      const otherPayer = {
        nin_other,
        last_name_other,
        first_names_other,
        street_other,
        city_other,
        zip_other,
        phone_other,
        email_other,
        confirm_email_other,
      };

      switch (currentStep) {
        case 1: {
          validationSchema[currentStep - 1].parse(step1);
          break;
        }

        default: {
          break;
        }
      }

      if (formValues.payer === 'other') {
        validationSchema[1].parse(otherPayer);
      }

      if (currentStep < 2) {
        setCurrentStep(currentStep + 1);
        setIsLoading(false);
      } else if (selectedProduct) {
        await postProduct(parseForm(formValues), token);
      }
    } catch (e) {
      if (e instanceof zod.ZodError) {
        setFormErrors(e.errors);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e: RegisterFormEvent): void => {
    // Clone the object to not refernce the existing state.
    const newFormValues = { ...formValues };
    if (e.target.type === 'checkbox') {
      newFormValues[e.target.name as keyof CheckoutFormValues] = e.target.checked as never;
    } else {
      newFormValues[e.target.name as keyof CheckoutFormValues] = e.target.value as never;
    }

    setFormValues(newFormValues);
  };

  return (
    <MainTemplate hideBottomNavbar>
      {registrationReturnValues.html_snippet ? (
        <Container maxWidth="sm">
          <div id="klarna" />
        </Container>
      ) : (
        <Container maxWidth="sm">
          {(!selectedProduct || !(Number(selectedProduct) > 0)) && (
            <Error>
              {!selectedProduct && (
                <Alert className={classes.alert} severity="error">
                  {t<string>('checkout.noProduct')}
                </Alert>
              )}
              {!(Number(selectedProduct) > 0) && (
                <Alert className={classes.alert} severity="error">
                  {t<string>('checkout.invalidProduct')}
                </Alert>
              )}
            </Error>
          )}
          {currentStep < TOTAL_STEPS ? (
            <CheckoutView
              isLoading={isLoading}
              formValues={formValues}
              setFormValues={setFormValues}
              formErrors={formErrors}
              handlePrev={handlePrev}
              handleNext={handleNext}
              handleChange={handleChange}
              currentStep={currentStep}
              totalSteps={TOTAL_STEPS}
              checkoutError={checkoutError}
            />
          ) : (
            <CheckoutComplete currentStep={currentStep} totalSteps={TOTAL_STEPS} paymentMethod={formValues.payment} />
          )}
        </Container>
      )}
    </MainTemplate>
  );
};
