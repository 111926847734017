import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, TableHead } from '@material-ui/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocaleInfo } from '../../hooks';
import { RegisterFormProps } from '../../types';
import { Error } from '../layout';

interface Props extends Pick<RegisterFormProps, 'formValues'> {
  registrationError: string | null;
}

export const Confirmation: React.FC<Props> = ({
  formValues: {
    nin,
    last_name,
    first_name,
    street,
    zip,
    city,
    home_city,
    phone,
    email,
    confirm_email,
    birth_country,
    native_language,
    licenses,
    has_professional_competence,
    additional_info,
    payer,
    payment,
    license_day,
    license_month,
    license_year,
    recurrences,
    nin_other,
    first_names_other,
    last_name_other,
    street_other,
    zip_other,
    city_other,
    phone_other,
    email_other,
  },
  registrationError,
}) => {
  const { t } = useTranslation();
  const paymentMethod =
    payment === 'epic_invoice' ? t<string>('courseWebshop.epicInvoice') : t<string>('courseWebshop.klarnaInvoice');
  const { countries, languages } = useLocaleInfo();
  const country = countries.find((c) => c.external_id === birth_country)?.name;
  const language = languages.find((l) => l.code === native_language)?.name;

  const licenseDate =
    !!license_year && !!license_month && !!license_day
      ? dayjs(new Date(Number(license_year), Number(license_month), Number(license_day))).format('LL')
      : '---';

  const renderError = !!registrationError && <Error>{registrationError}</Error>;

  const renderSummary = (
    <Paper>
      <Typography variant="h2" gutterBottom>
        {t<string>('confirmation.header')}
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t<string>('userData.nin')}</TableCell>
              <TableCell>{nin}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.lastName')}</TableCell>
              <TableCell>{last_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.firstName')}</TableCell>
              <TableCell>{first_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.street')}</TableCell>
              <TableCell>{street}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.zip')}</TableCell>
              <TableCell>{zip}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.city')}</TableCell>
              <TableCell>{city}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.homeCity')}</TableCell>
              <TableCell>{home_city}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.phone')}</TableCell>
              <TableCell>{phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.email')}</TableCell>
              <TableCell>{email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.confirmEmail')}</TableCell>
              <TableCell>{confirm_email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.birthCountry')}</TableCell>
              <TableCell>{country}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.nativeLanguage')}</TableCell>
              <TableCell>{language}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('confirmation.licenses')}</TableCell>
              <TableCell>{licenses.length ? licenses.map((l) => l) : '---'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('confirmation.licenseDate')}</TableCell>
              <TableCell>{licenseDate}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.professionalCompetence')}</TableCell>
              <TableCell>{has_professional_competence ? t<string>('misc.yes') : t<string>('misc.no')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('userData.additional_info')}</TableCell>
              <TableCell>{additional_info}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('confirmation.payment')}</TableCell>
              <TableCell>{paymentMethod}</TableCell>
            </TableRow>
            {payment === 'epic_invoice' && (
              <TableRow>
                <TableCell>{t<string>('confirmation.recurrences')}</TableCell>
                <TableCell>{recurrences}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {payer === 'other' && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t<string>('confirmation.otherPayerDetails')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t<string>('userData.nin')}</TableCell>
                <TableCell>{nin_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.firstName')}</TableCell>
                <TableCell>{first_names_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.lastName')}</TableCell>
                <TableCell>{last_name_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.street')}</TableCell>
                <TableCell>{street_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.zip')}</TableCell>
                <TableCell>{zip_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.city')}</TableCell>
                <TableCell>{city_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.phone')}</TableCell>
                <TableCell>{phone_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.email')}</TableCell>
                <TableCell>{email_other}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );

  return (
    <>
      {renderError}
      {renderSummary}
    </>
  );
};
