import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Alert from '@material-ui/lab/Alert';
import { colors } from '../../theme';
import { RegistrationControlProps, RegisterFormProps, Course } from '../../types';
import { Licenses } from './Licenses';
import { Agreements } from './Agreements';
import { Payment } from './Payment';
import { UserInfo } from './UserInfo';
import { RegistrationControls } from './RegistrationControls';
import { Confirmation } from './Confirmation';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  registrationHeader: {
    padding: spacing(1),
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      alignItems: 'center',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
  },
  progressBar: {
    padding: spacing(2),
    maxWidth: '125px',
    [breakpoints.up('sm')]: {
      marginRight: spacing(2),
    },
  },
  registrationHeaderTitle: {
    margin: `${spacing(1)} 0`,
  },
  registrationHeaderText: {
    margin: `${spacing(1)} 0`,
    color: palette.text.secondary,
    '& strong': {
      color: palette.text.primary,
    },
  },
  paper: {
    padding: `${spacing(3)} ${spacing(2)}`,
    marginBottom: spacing(4),
    [breakpoints.up('sm')]: {
      padding: spacing(4),
    },
  },
  textRequiredFields: {
    color: palette.text.secondary,
    marginBottom: spacing(3),
  },
}));

interface Props extends RegisterFormProps, RegistrationControlProps {
  course: Course | null;
  registrationError: string | null;
  termsLink: string;
  termsText: string;
}

export const RegisterView: React.FC<Props> = ({
  formErrors,
  formValues,
  setFormValues,
  handleChange,
  course,
  currentStep,
  totalSteps,
  handlePrev,
  handleNext,
  isLoading,
  registrationError,
  termsText,
  termsLink,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formHasErrors = formErrors.length > 0;

  const renderSteps = (): JSX.Element => {
    switch (currentStep) {
      case 1: {
        return (
          <UserInfo
            termsLink={termsLink}
            termsText={termsText}
            handleChange={handleChange}
            formValues={formValues}
            formErrors={formErrors}
          />
        );
      }

      case 2: {
        return (
          <Licenses
            termsText={termsText}
            termsLink={termsLink}
            handleChange={handleChange}
            formValues={formValues}
            formErrors={formErrors}
          />
        );
      }

      case 3: {
        return (
          <Agreements
            termsLink={termsLink}
            termsText={termsText}
            handleChange={handleChange}
            formValues={formValues}
            formErrors={formErrors}
          />
        );
      }

      case 4: {
        return (
          <Payment
            termsLink={termsLink}
            termsText={termsText}
            handleChange={handleChange}
            formValues={formValues}
            setFormValues={setFormValues}
            formErrors={formErrors}
          />
        );
      }

      case 5: {
        return <Confirmation formValues={formValues} registrationError={registrationError} />;
      }

      default: {
        return (
          <UserInfo
            termsLink={termsLink}
            termsText={termsText}
            handleChange={handleChange}
            formValues={formValues}
            formErrors={formErrors}
          />
        );
      }
    }
  };

  return (
    <>
      <div className={classes.registrationHeader}>
        <CircularProgressbar
          className={classes.progressBar}
          value={currentStep}
          maxValue={totalSteps}
          text={`${currentStep}/${totalSteps}`}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: colors.secondary,
            textColor: colors.textBlack,
            trailColor: 'rgba(0,0,0,0.1)',
          })}
        />
        <div>
          <h2 className={classes.registrationHeaderTitle}>{t<string>('userData.personalInformation')}</h2>
          <div>
            <p className={classes.registrationHeaderText}>
              {t<string>('courseWebshop.selectedSchool')}:{' '}
              <strong>{course?.company_name || t<string>('courseWebshop.noSelectedSchool')}</strong>
            </p>
            <p className={classes.registrationHeaderText}>
              {t<string>('courseWebshop.selectedCourse')}:{' '}
              <strong>{course?.name || t<string>('courseWebshop.noSelectedCourse')}</strong>
            </p>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form data-testid="register-form">
          {currentStep !== 5 && (
            <div className={classes.textRequiredFields}>{t<string>('formValidation.formRequiredFields')}</div>
          )}
          {formHasErrors && (
            <Box marginBottom={3}>
              <Alert severity="error">{t<string>('formValidation.formHasErrors')}</Alert>
            </Box>
          )}
          {renderSteps()}
        </form>
        <RegistrationControls
          isLoading={isLoading}
          handlePrev={handlePrev}
          handleNext={handleNext}
          currentStep={currentStep}
          totalSteps={totalSteps}
          formValues={formValues}
        />
      </Paper>
    </>
  );
};
