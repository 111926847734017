import React, { ChangeEvent } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Person from '@material-ui/icons/Person';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Book from '@material-ui/icons/Book';
import House from '@material-ui/icons/House';
import CalendarToday from '@material-ui/icons/CalendarToday';
import School from '@material-ui/icons/School';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { urls } from '../../utils';
import { useAuthContext, useStudyPathContext } from '../../context';

export const BottomNavbar: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const styles = makeStyles(() => ({
    container: {
      zIndex: 1,
    },
  }));
  const classes = styles();
  const { setOpenInvoicePaid } = useStudyPathContext();
  const { token, getProfileData, profileData } = useAuthContext();

  const handleChange = async (_e: ChangeEvent<Record<string, unknown>>, value: string): Promise<void> => {
    getProfileData(token);
    if ([urls.practiceExams, urls.materials].includes(value) && !profileData.open_invoices_paid) {
      setOpenInvoicePaid(!profileData.open_invoices_paid);
    } else {
      setOpenInvoicePaid(false);
      history.push(value);
    }
  };

  return (
    <BottomNavigation className={classes.container} value={location.pathname} onChange={handleChange}>
      <BottomNavigationAction icon={<House />} value={urls.home} />
      <BottomNavigationAction icon={<CalendarToday />} value={urls.calendar} />
      <BottomNavigationAction icon={<Book />} value={urls.materials} />
      <BottomNavigationAction icon={<School />} value={urls.practiceExams} />
      <BottomNavigationAction icon={<Person />} value={urls.profile} />
    </BottomNavigation>
  );
};
