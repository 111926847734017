import React, { useState, useEffect, SyntheticEvent } from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Button, TextField, Link, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAuthContext } from '../context';
import { Header } from '../components';
import { urls } from '../utils';

const useStyles = makeStyles(({ spacing, breakpoints, typography, palette }) => ({
  headerLogo: {
    margin: `${spacing(3)} auto`,
    maxWidth: '120px',
    display: 'block',
    objectFit: 'contain',
    [breakpoints.up('sm')]: {
      margin: `${spacing(6)} auto`,
      maxWidth: '150px',
    },
  },
  paper: {
    padding: `${spacing(3)} ${spacing(2)}`,
    [breakpoints.up('sm')]: {
      padding: spacing(4),
    },
  },
  title: {
    marginTop: 0,
    marginBottom: spacing(3),
    textAlign: 'center',
    fontSize: typography.h2.fontSize,
  },
  submitWrap: {
    position: 'relative',
    marginTop: spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-12px',
    marginTop: '-12px',
  },
  forgotPassword: {
    margin: `${spacing(3)} auto`,
    textAlign: 'center',
  },
  forgotPasswordLink: {
    color: palette.text.secondary,
  },
  root: {
    //backgroundImage: 'url(images/background.svg)',
    backgroundColor: '#EDF0FA',
    minHeight: '100vh',
  },
}));

export const Login: React.FC = () => {
  const { loading, error, login } = useAuthContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setButtonDisabled(loading || !(email.length > 0 && password.length > 0));
  }, [loading, email.length, password.length]);

  const handleLogin = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    login(email, password);
  };

  const handleEmailChange = (e: string): void => {
    setEmail(e.toLowerCase());
    setButtonDisabled(!(email.length > 0 && password.length > 0));
  };

  const handlePasswordChange = (e: string): void => {
    setPassword(e);
    setButtonDisabled(!(email.length > 0 && password.length > 0));
  };

  const renderLoginError = error && (
    <Grid item xs={12} data-testid="login-error">
      <Box marginBottom={3}>
        <Alert severity="error">{t<string>('misc.loginError')}</Alert>
      </Box>
    </Grid>
  );

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <Header hidePrev />
        <img className={classes.headerLogo} src="/images/logo.png" alt="Logo" width={150} height={163} />
        <Paper className={classes.paper}>
          <h1 className={classes.title}>{t<string>('misc.login')}</h1>
          <form data-testid="user-login">
            <Grid container spacing={2}>
              {renderLoginError}
              <Grid item xs={12}>
                <TextField
                  data-testid="login-email"
                  label={t<string>('userData.email')}
                  name="email"
                  required
                  disabled={loading}
                  inputProps={{
                    'data-testid': 'email',
                  }}
                  onChange={(e): void => handleEmailChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  data-testid="login-password"
                  label={t<string>('userData.password')}
                  name="password"
                  type="password"
                  required
                  disabled={loading}
                  inputProps={{
                    'data-testid': 'password',
                  }}
                  onChange={(e): void => handlePasswordChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.submitWrap}>
                  <Button
                    data-testid="login-button"
                    fullWidth
                    type="submit"
                    disabled={buttonDisabled}
                    onClick={handleLogin}
                  >
                    {t<string>('misc.login')}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <div className={classes.forgotPassword}>
          <Link href={urls.forgotPassword} className={classes.forgotPasswordLink}>
            {t<string>('misc.forgotPassword')}
          </Link>
        </div>
      </Container>
    </Box>
  );
};
