import React, { MouseEvent, useEffect, useState } from 'react';
import { Container, makeStyles, Box } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import * as zod from 'zod';
import { FinnishSSN } from 'finnish-ssn';
import { RegisterComplete, RegisterView, Error } from '../components';
import { Course, RegisterFormEvent, RegisterFormValues } from '../types';
import { useQueryParams } from '../hooks';
import { Api, getRegistrationToken, getCourse } from '../api';
import { ONLY_CHARACTERS_REGEX, PHONE_NUMBER_REGEX, ZIP_CODE_REGEX, endpoints } from '../utils';
import axios from 'axios';

const useStyles = makeStyles(({ spacing }) => ({
  alert: {
    marginTop: spacing(2),
  },
  root: {
    //backgroundImage: 'url(images/background.svg)',
    backgroundColor: '#EDF0FA',
    minHeight: '100vh',
  },
}));

const step1Validation = zod
  .object({
    nin: zod
      .string()
      .min(1)
      .refine((val) => FinnishSSN.validate(val.toUpperCase())),
    last_name: zod.string().min(1),
    first_name: zod.string().min(1),
    street: zod.string().min(1),
    city: zod.string().min(1).regex(ONLY_CHARACTERS_REGEX),
    home_city: zod.string().min(1).regex(ONLY_CHARACTERS_REGEX),
    zip: zod.string().min(1).regex(ZIP_CODE_REGEX),
    phone: zod.string().min(1).regex(PHONE_NUMBER_REGEX),
    email: zod.string().email().min(1),
    confirm_email: zod.string().email().min(1),
    birth_country: zod.string().min(1),
    native_language: zod.string().min(1),
  })
  .refine((data: Record<string, unknown>) => data.email === data.confirm_email, {
    path: ['confirm_email'],
  });

const otherValidation = zod
  .object({
    nin_other: zod
      .string()
      .min(1)
      .refine((val) => FinnishSSN.validate(val.toUpperCase())),
    last_name_other: zod.string().min(1),
    first_names_other: zod.string().min(1),
    street_other: zod.string().min(1),
    city_other: zod.string().min(1),
    zip_other: zod.string().min(1).regex(ZIP_CODE_REGEX),
    phone_other: zod.string().min(1).regex(PHONE_NUMBER_REGEX),
    email_other: zod.string().email().min(1),
    confirm_email_other: zod.string().email().min(1),
  })
  .refine((data: Record<string, unknown>) => data.email_other === data.confirm_email_other, {
    path: ['confirm_email'],
  });

const step2Validation = zod
  .object({
    transmission_type_preference: zod.string(),
    licenses: zod.string().array(),
    has_professional_competence: zod.boolean(),
    license_day: zod.string(),
    license_month: zod.string(),
    license_year: zod.string(),
    additional_info: zod.string().nullable(),
  })
  .refine(
    ({ license_day, license_month, license_year }) => {
      // Validate that the license date is not in the future.
      if (license_day && license_month && license_year) {
        return new Date(Number(license_year), Number(license_month) - 1, Number(license_day)) <= new Date();
      }

      return true;
    },
    { path: ['license_date_validation'] },
  )
  .refine(
    ({ licenses, license_day, license_month, license_year }) => {
      if (licenses.length > 0)
        if (license_month === '' || license_year === '' || license_day === '') {
          return false;
        }

      return true;
    },
    { path: ['license_date_required'] },
  );

const step3Validation = zod.object({
  parental_consent: zod.boolean(),
  has_agreed: zod.literal(true),
});

const step4Validation = zod.object({
  payer: zod.string().min(1),
  payment: zod.string().min(1),
});

const validationSchema = [step1Validation, step2Validation, step3Validation, step4Validation, otherValidation];

// FIXME: If previous has parental_consent set, this is not reflected in the formValues!
const DEFAULT_FORM_VALUES: RegisterFormValues = {
  selected_school: '',
  selected_course: '',
  nin: '',
  last_name: '',
  first_name: '',
  street: '',
  zip: '',
  city: '',
  home_city: '',
  phone: '',
  email: '',
  confirm_email: '',
  birth_country: '',
  native_language: '',
  licenses: [],

  transmission_type_preference: 'manual',
  has_professional_competence: false,
  additional_info: '',
  parental_consent: false,
  has_agreed: false,
  payer: 'self',
  payment: '',
  license_day: '',
  license_month: '',
  license_year: '',
  recurrences: 1,
  nin_other: '',
  first_names_other: '',
  last_name_other: '',
  street_other: '',
  zip_other: '',
  city_other: '',
  phone_other: '',
  email_other: '',
  confirm_email_other: '',
  can_be_driven_automatic: false,
};

const TOTAL_STEPS = 6;

interface RegisterPayload
  extends Pick<
    RegisterFormValues,
    | 'nin'
    | 'first_name'
    | 'last_name'
    | 'street'
    | 'zip'
    | 'city'
    | 'phone'
    | 'email'
    | 'recurrences'
    | 'home_city'
    | 'parental_consent'
    | 'has_professional_competence'
    | 'licenses'
  > {
  payment_method: RegisterFormValues['payment'];
  nationality: RegisterFormValues['birth_country'];
  license_additional_information: RegisterFormValues['additional_info'];
  transmission_type_preference: RegisterFormValues['transmission_type_preference'];
  latest_license_approval_date: string;
  other_payer: boolean;
  company_id: number | null;
  course_id: number | null;
  language: string;
  invoicing_details: Partial<
    Pick<RegisterFormValues, 'nin' | 'first_name' | 'last_name' | 'street' | 'zip' | 'city' | 'phone' | 'email'>
  >;
}

interface RegistrationReturnValues {
  html_snippet: string | null;
  student_user_id: number | null;
}

const DEFAULT_REGISTRATION_RETURN_VALUES: RegistrationReturnValues = {
  html_snippet: null,
  student_user_id: null,
};

export const Register: React.FC = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
  const [currentStep, setCurrentStep] = useState(1);
  const [formErrors, setFormErrors] = useState<zod.ZodIssue[]>([]);
  const [registrationError, setRegistrationError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const selectedCourse = queryParams.get('course');
  const [accessToken, setAccessToken] = useState('');
  const [course, setCourse] = useState<Course | null>(null);
  const [termsLink, setTermsLink] = useState<string>('');
  const [termsText, setTermsText] = useState<string>('');
  const [courseLoading, setCourseLoading] = useState(true); // Initialize the loading state as 'loading' to prevent flashing unwanted JSX.
  const [registrationReturnValues, setRegistrationReturnValues] = useState<RegistrationReturnValues>(
    DEFAULT_REGISTRATION_RETURN_VALUES,
  );

  // Fetch course info.
  useEffect(() => {
    if (selectedCourse) {
      (async (): Promise<void> => {
        try {
          const { data } = await getCourse(selectedCourse);
          setCourse(data);
          setTermsLink(data.user_terms_link);
          setTermsText(data.user_terms_text);
          formValues.can_be_driven_automatic = data.can_be_driven_automatic;
        } catch {
          // Request failed, e.g. indalid course ID.
        } finally {
          setCourseLoading(false);
        }
      })();
    } else {
      setCourseLoading(false);
    }
  }, [selectedCourse, formValues]);

  useEffect(() => {
    if (course) {
      localStorage.setItem('courseLandingPageUrl', course?.landing_page_url || '');
    }
  }, [course]);

  useEffect(() => {
    (async (): Promise<void> => {
      const {
        data: { access_token: accessToken },
      } = await getRegistrationToken();

      setAccessToken(accessToken);
    })();
  }, []);

  useEffect(() => {
    const checkoutContainer = document.getElementById('klarna');
    if (checkoutContainer !== null) {
      checkoutContainer.innerHTML = registrationReturnValues.html_snippet || '';
      const scriptsTags = checkoutContainer.getElementsByTagName('script');
      for (let i = 0; i < scriptsTags.length; i += 1) {
        const { parentNode } = scriptsTags[i];
        if (parentNode !== null) {
          const newScriptTag = document.createElement('script');
          newScriptTag.type = 'text/javascript';
          newScriptTag.text = scriptsTags[i].text;
          parentNode.removeChild(scriptsTags[i]);
          parentNode.appendChild(newScriptTag);
        }
      }
    }
  }, [registrationReturnValues]);

  const scrollToTop = () => window.scrollTo({ top: 0 });

  const postRegistration = async (data: RegisterPayload, accessToken: string): Promise<void> => {
    try {
      const returnVals = await Api.post(endpoints.register, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const vals: RegistrationReturnValues = returnVals.data;

      setRegistrationReturnValues(vals);
      if (formValues.payment === 'anders_klarna') {
        setIsLoading(false);
      } else {
        setCurrentStep(currentStep + 1);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      if (axios.isAxiosError(e)) {
        setRegistrationError(e.response?.data?.message);
      }
    }
  };

  const handlePrev = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setIsLoading(true);
    setRegistrationError(null);

    if (currentStep > 1) {
      scrollToTop();
      setCurrentStep(currentStep - 1);
      setIsLoading(false);
    }
  };

  const parseForm = ({
    nin,
    first_name,
    last_name,
    street,
    zip,
    city,
    phone,
    email,
    native_language,
    payment,
    recurrences,
    birth_country,
    home_city,
    parental_consent,
    additional_info,
    payer,
    licenses: _licenses,
    has_professional_competence,
    license_day,
    license_month,
    license_year,
    nin_other,
    first_names_other,
    last_name_other,
    street_other,
    zip_other,
    city_other,
    phone_other,
    email_other,
    transmission_type_preference,
  }: RegisterFormValues): RegisterPayload => {
    const payload: RegisterPayload = {
      nin,
      first_name,
      last_name,
      street,
      zip,
      city,
      phone,
      email,
      recurrences: Number(recurrences),
      home_city,
      parental_consent,
      language: native_language,
      payment_method: payment,
      nationality: birth_country,
      licenses: [],
      transmission_type_preference,
      license_additional_information: additional_info,
      has_professional_competence,
      latest_license_approval_date: '',
      other_payer: payer === 'other',
      course_id: course?.id || null,
      company_id: course?.company_id || null,
      invoicing_details: {},
    };

    // License and payer are not mandatory details.
    if (_licenses?.length) {
      const licenseDate = DateTime.fromObject({
        day: Number(license_day),
        month: Number(license_month),
        year: Number(license_year),
      }).toISODate();

      payload.latest_license_approval_date = licenseDate;

      const licenses = _licenses.map((l: string) => {
        return `epic_autokoulu.license_${l.toLowerCase()}`;
      });

      payload.licenses = licenses;
    }

    if (payer === 'other') {
      payload.invoicing_details = {
        nin: nin_other,
        first_name: first_names_other,
        last_name: last_name_other,
        street: street_other,
        zip: zip_other,
        city: city_other,
        phone: phone_other,
        email: email_other,
      };
    }

    return payload;
  };

  const validateEmailAndNIN = async ({ email, nin }: Pick<RegisterFormValues, 'email' | 'nin'>): Promise<void> => {
    const {
      data: { errors },
    } = await Api.post(
      endpoints.registrationValidation,
      { email, nin },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (errors) {
      if (errors.email) {
        const message =
          errors.email === 'Email has already been reserved for a Student User with different NIN!'
            ? t<string>('formValidation.emailExists')
            : t<string>('formValidation.invalidEmail');

        setFormErrors([{ path: ['email'], message, code: 'custom' }]);
      }

      if (errors.nin) {
        setFormErrors([{ path: ['nin'], message: '', code: 'custom' }]);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleNext = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    scrollToTop();
    setFormErrors([]); // Set errors to none if everything was successful.

    const {
      nin,
      first_name,
      last_name,
      street,
      zip,
      city,
      phone,
      email,
      native_language,
      payment,
      birth_country,
      home_city,
      additional_info,
      payer,
      licenses,
      license_day,
      license_month,
      license_year,
      nin_other,
      first_names_other,
      last_name_other,
      street_other,
      zip_other,
      city_other,
      phone_other,
      email_other,
      has_professional_competence,
      parental_consent,
      has_agreed,
      confirm_email,
      confirm_email_other,
      transmission_type_preference,
    } = formValues;

    try {
      const step1 = {
        nin,
        email,
        confirm_email,
        city,
        zip,
        last_name,
        first_name,
        street,
        home_city,
        phone,
        birth_country,
        native_language,
      };

      const step2 = {
        transmission_type_preference,
        licenses,
        license_day,
        license_month,
        license_year,
        additional_info,
        has_professional_competence,
      };

      const step3 = {
        parental_consent,
        has_agreed,
      };

      const step4 = {
        payer,
        payment,
      };

      const otherPayer = {
        nin_other,
        last_name_other,
        first_names_other,
        street_other,
        city_other,
        zip_other,
        phone_other,
        email_other,
        confirm_email_other,
      };

      switch (currentStep) {
        case 1: {
          validationSchema[currentStep - 1].parse(step1);
          break;
        }

        case 2: {
          if (formValues.licenses) validationSchema[currentStep - 1].parse(step2);
          break;
        }

        case 3: {
          validationSchema[currentStep - 1].parse(step3);
          break;
        }

        case 4: {
          validationSchema[currentStep - 1].parse(step4);
          if (formValues.payer === 'other') {
            validationSchema[4].parse(otherPayer);
          }
          break;
        }

        default: {
          break;
        }
      }

      if (currentStep === 1) {
        validateEmailAndNIN({ email, nin });
        setIsLoading(false);
      } else if (currentStep < 5) {
        setCurrentStep(currentStep + 1);
        setIsLoading(false);
      } else if (selectedCourse) {
        await postRegistration(parseForm(formValues), accessToken);
      }
    } catch (e) {
      if (e instanceof zod.ZodError) {
        setFormErrors(e.errors);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e: RegisterFormEvent): void => {
    // Clone the object to not refernce the existing state.
    const newFormValues = { ...formValues };
    if (currentStep === 1) {
      if (e.target.name === 'nin') {
        e.target.value = `${e.target.value}`.toUpperCase();
      }
      if (e.target.name === 'email' || e.target.name === 'confirm_email') {
        e.target.value = `${e.target.value}`.toLowerCase();
      }
    }

    if (e.licenses && e.name === 'licensesEvent') {
      newFormValues.licenses = e.licenses;
    } else if (e.target.type === 'checkbox') {
      newFormValues[e.target.name as keyof RegisterFormValues] = e.target.checked as never;
    } else {
      newFormValues[e.target.name as keyof RegisterFormValues] = e.target.value as never;
    }

    setFormValues(newFormValues);
  };

  return (
    <Box className={classes.root}>
      {registrationReturnValues.html_snippet ? (
        <Container maxWidth="sm">
          <div id="klarna" />
        </Container>
      ) : (
        <Container maxWidth="sm">
          {!courseLoading && (!selectedCourse || !course) && (
            <Error>
              {!selectedCourse && (
                <Alert className={classes.alert} severity="error">
                  {t<string>('register.noCourse')}
                </Alert>
              )}
              {!!selectedCourse && !course && (
                <Alert className={classes.alert} severity="error">
                  {t<string>('register.invalidCourse')}
                </Alert>
              )}
            </Error>
          )}
          {!!course &&
            (currentStep < TOTAL_STEPS ? (
              <RegisterView
                termsText={termsText}
                termsLink={termsLink}
                isLoading={isLoading}
                formValues={formValues}
                setFormValues={setFormValues}
                formErrors={formErrors}
                handlePrev={handlePrev}
                handleNext={handleNext}
                handleChange={handleChange}
                currentStep={currentStep}
                totalSteps={TOTAL_STEPS}
                course={course}
                registrationError={registrationError}
              />
            ) : (
              <RegisterComplete
                currentStep={currentStep}
                totalSteps={TOTAL_STEPS}
                landingPageUrl={course.landing_page_url}
              />
            ))}
        </Container>
      )}
    </Box>
  );
};
