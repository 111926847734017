import React from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

interface Props {
  selectedLanguage: string;
  setLaguage: (language: string) => void;
}
export const LanguageSelection: React.FC<Props> = ({ selectedLanguage, setLaguage }) => {
  const { t } = useTranslation();
  const countryToLocaleMapper = React.useMemo(
    () => ({
      FI: 'fi',
      SE: 'sw',
      GB: 'en',
    }),
    [],
  );
  const getSelectedLocale = React.useCallback(
    (selectedLanguage: string) => {
      const selectedLocale = Object.entries(countryToLocaleMapper).find((item) => item[0] === selectedLanguage);
      return selectedLocale && selectedLocale[1];
    },
    [countryToLocaleMapper],
  );
  const toggleLanguage = React.useCallback((locale: string) => i18n.changeLanguage(locale), []);
  React.useEffect(() => {
    ((): void => {
      const locale = getSelectedLocale(selectedLanguage) ?? 'fi';
      localStorage.setItem('user_language', selectedLanguage);
      toggleLanguage(locale);
    })();
  }, [getSelectedLocale, selectedLanguage, toggleLanguage]);

  return (
    <ReactFlagsSelect
      countries={['FI', 'SE', 'GB']}
      customLabels={{ FI: t<string>('lang.fi'), GB: t<string>('lang.en'), SE: t<string>('lang.se') }}
      placeholder={t<string>('lang.selectLanguage')}
      onSelect={(c: string): void => setLaguage(c)}
      selected={selectedLanguage}
    />
  );
};
