import React, { FunctionComponent, useEffect } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthContext } from '../../context/auth';

import { urls } from '../../utils';

interface Props extends RouteProps {
  component: FunctionComponent;
}

export const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const { token, expiresIn, getProfileData } = useAuthContext();

  const expireTime = new Date();
  expireTime.setSeconds(expireTime.getSeconds() + Number(expiresIn));

  const expiryTime = Date.parse(String(expireTime));
  const currentTime = new Date().getTime();
  // Automatically fetch profile data on all private routes if the user is authenticated.
  useEffect(() => {
    if (token) {
      getProfileData(token);
    }
  }, [token, getProfileData]);

  return (
    <Route
      render={(): JSX.Element => (token && expiryTime > currentTime ? <Component /> : <Redirect to={urls.login} />)}
      {...rest}
    />
  );

  // return <Route render={(props: RouteProps): JSX.Element => <Component />} />;
};
