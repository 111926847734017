import axios, { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { endpoints } from './utils';
import { AccessTokenData, Course } from './types';

export const baseURL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

export const Api = axios.create({
  baseURL,
  withCredentials: false,
  // TODO: Our language setter is not working yet
  // params: { lang: 'fi_FI' },

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const getRegistrationToken = (): Promise<AxiosResponse<AccessTokenData>> =>
  Api.post(
    endpoints.getRegistrationToken,
    queryString.stringify({
      grant_type: 'client_credentials',
      client_id: process.env.REACT_APP_API_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

//  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
//   client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,

export const getAccessToken = (email: string, password: string): Promise<AxiosResponse<AccessTokenData>> =>
  Api.post(
    endpoints.getAccessToken,
    queryString.stringify({
      grant_type: 'password',
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
      username: email,
      password,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

export const getCourse = (courseId: string): Promise<AxiosResponse<Course>> =>
  Api.get(endpoints.course(Number(courseId)));
