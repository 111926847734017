import React from 'react';
import { Paper, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Redirect } from 'react-router-dom';
import { colors } from '../../theme';
import { urls } from '../../utils';
import { useQueryParams } from '../../hooks';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  paper: {
    padding: `${spacing(3)} ${spacing(2)}`,
    marginBottom: spacing(4),
    [breakpoints.up('sm')]: {
      padding: spacing(4),
    },
  },
  progressBar: {
    padding: spacing(2),
    maxWidth: '125px',
  },
  homeLink: {
    color: 'deepskyblue',
  },
}));
interface Props {
  currentStep: number;
  totalSteps: number;
  paymentMethod: string;
}

export const CheckoutComplete: React.FC<Props> = ({ currentStep, totalSteps, paymentMethod }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const selectedProduct = queryParams.get('product');

  return (
    <Paper className={classes.paper}>
      <CircularProgressbar
        className={classes.progressBar}
        value={6}
        maxValue={6}
        text={`${currentStep}/${totalSteps}`}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: colors.secondary,
          textColor: colors.textBlack,
        })}
      />

      {paymentMethod === 'anders_klarna' && selectedProduct ? (
        <Redirect to={urls.klarnaConfirm(selectedProduct)} />
      ) : (
        <>
          <h1>{t<string>('courseWebshop.paymentCompletedHeader')}</h1>
          <span>
            <Link href={urls.home} className={classes.homeLink}>
              {t<string>('nav.backToStudyPath')}
            </Link>
          </span>
        </>
      )}
    </Paper>
  );
};
