import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button, Container, Typography, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { endpoints, urls } from '../utils';
import { Header, MainTemplate } from '../components';
import { useAuthContext, useStudyPathContext } from '../context';
import { Api } from '../api';

const useStyles = makeStyles(({ palette, spacing }) => ({
  liContainer: {
    marginTop: spacing(2),
    borderTop: `0.05rem solid ${palette.grey[400]}`,
  },
  logoutButton: {
    marginTop: spacing(4),
  },
}));

export const Profile: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { logout, profileData, token } = useAuthContext();
  const { setStudyPathPhoneNumber } = useStudyPathContext();
  const [currentCourse, setCurrentCourse] = useState<string>('');
  const listItems = [
    {
      href: urls.updateProfile,
      text: t<string>('profile.personalInformation'),
    },
    {
      href: urls.invoices,
      text: t<string>('profile.invoices'),
    },
    {
      href: urls.myCourses,
      text: t<string>('profile.myCourses'),
    },
    {
      href: urls.changePassword,
      text: t<string>('profile.changePassword'),
    },
  ];

  const handleLogout = (e: SyntheticEvent): void => {
    e.preventDefault();
    logout();
    Api.get(endpoints.informLogout, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(() => setStudyPathPhoneNumber(''));
  };

  // current course
  useEffect(() => {
    if (!token) return;
    Api.get(endpoints.course(profileData.current_course), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => setCurrentCourse(response.data.name));
  }, [profileData, token]);

  return (
    <MainTemplate>
      <Container maxWidth="sm" data-testid="profile-display">
        <Header titleText={t<string>('profile.profile')} hidePrev />
        <Typography data-testid="profile-names" variant="body2">
          {profileData.first_name} {profileData.last_name}
        </Typography>
        <Typography data-testid="profile-email" variant="body2">
          {profileData.email}
        </Typography>
        <Typography data-testid="profile-selected-course" variant="body2">
          {t<string>('profile.selectedCourse')}: {currentCourse}
        </Typography>
      </Container>
      <Container maxWidth="sm" className={classes.liContainer} disableGutters data-testid="profile-buttons">
        {listItems.map(({ href, text }) => (
          <ListItem component="a" href={href} key={text}>
            <ListItemText>{text}</ListItemText>
            <KeyboardArrowRight />
          </ListItem>
        ))}
      </Container>
      <Container maxWidth="sm">
        <Button
          className={classes.logoutButton}
          variant="contained"
          onClick={handleLogout}
          data-testid="logout-button"
          fullWidth
        >
          {t<string>('profile.logout')}
        </Button>
      </Container>
    </MainTemplate>
  );
};
