import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { FinnishSSN } from 'finnish-ssn';
import { RegistrationControlProps } from '../../types';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  navigationArrows: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'stretch',
    marginTop: spacing(3),
    [breakpoints.up('sm')]: {
      marginTop: spacing(4),
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
  },
  buttonBack: {
    [breakpoints.down('xs')]: {
      marginBottom: spacing(2),
    },
  },
}));

export const RegistrationControls: React.FC<RegistrationControlProps> = ({
  isLoading,
  handlePrev,
  handleNext,
  currentStep,
  formValues: { nin, nin_other, parental_consent, payer, has_agreed },
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let ageInYears = null;
  let ageInYearsOther = null;

  // Parsing an invalid SSN throws error.
  if (FinnishSSN.validate(nin.toUpperCase())) {
    const { ageInYears: _ageInYear } = FinnishSSN.parse(nin.toUpperCase());
    ageInYears = _ageInYear;
  }

  if (FinnishSSN.validate(nin_other.toUpperCase())) {
    const { ageInYears: _ageInYearOther } = FinnishSSN.parse(nin_other.toUpperCase());
    ageInYearsOther = _ageInYearOther;
  }

  // Disable the next button on agreements phase in case the user is under 16 and has not checked the parental consent.
  const tooYoungAndNoConsent = !parental_consent && ageInYears !== null && ageInYears < 16 && currentStep === 3;

  // Disable the next button on other payer details if the other payer is below 16.
  const tooYoungOtherPayer = currentStep === 4 && ageInYearsOther !== null && ageInYearsOther < 18;

  // If the user is below 18, force to input the information of the other payer.
  const tooYoungSelfPayer = currentStep === 4 && ageInYearsOther !== null && ageInYearsOther < 16 && payer === 'self';
  const hasNotAgreedToTerms = currentStep === 3 && !has_agreed;

  const disabled = isLoading || tooYoungAndNoConsent || tooYoungOtherPayer || tooYoungSelfPayer || hasNotAgreedToTerms;

  return (
    <div className={classes.navigationArrows}>
      {currentStep !== 1 && (
        <Button
          className={classes.buttonBack}
          data-testid="registerNav-prev"
          id="registernav-prev"
          onClick={handlePrev}
          startIcon={<ArrowBack aria-hidden="true" />}
          variant="text"
        >
          {t<string>('nav.prev')}
        </Button>
      )}
      <Button
        data-testid="registerNav-next"
        id="registernav-next"
        onClick={handleNext}
        endIcon={
          isLoading ? (
            <CircularProgress aria-hidden="true" size={24} color="inherit" />
          ) : (
            <ArrowForward aria-hidden="true" />
          )
        }
        disabled={disabled}
        variant="contained"
      >
        {t<string>('nav.next')}
      </Button>
    </div>
  );
};
