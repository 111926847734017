import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, TableHead } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CheckoutFormProps } from '../../types';
import { Error } from '../layout';

interface Props extends Pick<CheckoutFormProps, 'formValues'> {
  checkoutError: string | null;
}

export const Confirmation: React.FC<Props> = ({
  formValues: {
    additional_info,
    payer,
    payment,
    recurrences,
    nin_other,
    first_names_other,
    last_name_other,
    street_other,
    zip_other,
    city_other,
    phone_other,
    email_other,
  },
  checkoutError,
}) => {
  const { t } = useTranslation();
  const paymentMethod =
    payment === 'epic_invoice' ? t<string>('courseWebshop.epicInvoice') : t<string>('courseWebshop.klarnaInvoice');

  const renderError = !!checkoutError && <Error>{checkoutError}</Error>;

  const renderSummary = (
    <Paper>
      <Typography variant="h2" gutterBottom>
        {t<string>('confirmation.header')}
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t<string>('userData.additional_info')}</TableCell>
              <TableCell>{additional_info}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t<string>('confirmation.payment')}</TableCell>
              <TableCell>{paymentMethod}</TableCell>
            </TableRow>
            {payment === 'epic_invoice' && (
              <TableRow>
                <TableCell>{t<string>('confirmation.recurrences')}</TableCell>
                <TableCell>{recurrences}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {payer === 'other' && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t<string>('confirmation.otherPayerDetails')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t<string>('userData.nin')}</TableCell>
                <TableCell>{nin_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.firstName')}</TableCell>
                <TableCell>{first_names_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.lastName')}</TableCell>
                <TableCell>{last_name_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.street')}</TableCell>
                <TableCell>{street_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.zip')}</TableCell>
                <TableCell>{zip_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.city')}</TableCell>
                <TableCell>{city_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.phone')}</TableCell>
                <TableCell>{phone_other}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t<string>('userData.email')}</TableCell>
                <TableCell>{email_other}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );

  return (
    <>
      {renderError}
      {renderSummary}
    </>
  );
};
