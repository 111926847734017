import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Typography, Grid, Card, CardContent, Box, Button } from '@material-ui/core';

import { Alert, Rating } from '@material-ui/lab';

import { DirectionsCar, Event, AccessTime, AssignmentInd } from '@material-ui/icons';
import { Lesson } from '../../types';
import 'react-calendar/dist/Calendar.css';

interface Props {
  i: number;
  lesson: Lesson;
  calendarValue: Date;
  handleClick: (lesson: Lesson | undefined) => void;
  buttonText: string;
}

export const LessonBox: React.FC<Props> = ({ lesson, calendarValue, handleClick, buttonText }) => {
  const { t } = useTranslation();

  const getHoursAndMinutes = useCallback((date: string): string => {
    const dateValue = new Date(date);
    const hours = String(dateValue.getHours()).padStart(2, '0');
    const minutes = String(dateValue.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }, []);

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h4" style={{ display: 'inline-block' }}>
              {lesson.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {lesson.is_simulator && <Chip size="small" label="Simulator" variant="outlined" />}
          </Grid>
          <Grid item xs={6}>
            <Chip
              size="small"
              style={{ backgroundColor: 'white' }}
              icon={<Event />}
              label={`${calendarValue.getDate()}.${calendarValue.getMonth() + 1}.${calendarValue.getFullYear()}`}
            />
          </Grid>
          <Grid item xs={6}>
            <Chip
              size="small"
              style={{ backgroundColor: 'white' }}
              icon={<AccessTime />}
              label={`${getHoursAndMinutes(lesson.start_date)}-${getHoursAndMinutes(lesson.end_date)}`}
            />
          </Grid>

          <Grid item xs={6}>
            <Chip
              size="small"
              icon={<AssignmentInd />}
              label={lesson.instructor.name}
              style={{ backgroundColor: 'white' }}
            />
            {lesson.state === 'open' && !lesson.is_simulator && (
              <Rating value={lesson.instructor.rating} precision={0.5} readOnly />
            )}
          </Grid>
          <Grid>
            <Chip size="small" icon={<DirectionsCar />} label={lesson.vehicle} style={{ backgroundColor: 'white' }} />
          </Grid>
          <Grid item xs={12}>
            {lesson.show_description && <Box dangerouslySetInnerHTML={{ __html: lesson.description }} />}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              {lesson.state === 'cancelled' && <Alert severity="error">{t<string>('lessons.cancelled')}</Alert>}
              {lesson.state === 'completed' && <Alert severity="success">{t<string>('lessons.completed')}</Alert>}
              {lesson.state !== 'cancelled' && lesson.state !== 'completed' && (
                <Button onClick={(): void => handleClick(lesson)} size="small">
                  {buttonText}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
