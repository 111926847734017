import React from 'react';

import { useTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox, Grid, Link, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FinnishSSN } from 'finnish-ssn';
import { RegisterFormProps } from '../../types';

const useStyles = makeStyles(({ spacing }) => ({
  licenseText: {
    marginBottom: spacing(1),
  },
  checkbox: {
    marginBottom: spacing(1),
  },
  agreementLink: {
    color: 'deepskyblue',
  },
}));

export const Agreements: React.FC<RegisterFormProps> = ({
  termsLink,
  termsText,
  handleChange,
  formValues: { nin, parental_consent, has_agreed },
  formErrors,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let ageInYears = null;

  if (FinnishSSN.validate(nin)) {
    const { ageInYears: _ageInYears } = FinnishSSN.parse(nin);
    ageInYears = _ageInYears;
  }

  const tooYoungAndNoConsent = !parental_consent && ageInYears !== null && ageInYears < 16;
  const agreementError = formErrors.some((e) => e.path[0] === 'has_agreed');
  const parentalConsentDisabled = ageInYears !== null && ageInYears >= 16;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {termsText !== '' && <div className={classes.licenseText} dangerouslySetInnerHTML={{ __html: termsText }} />}
      </Grid>
      <Grid item xs={12} className={classes.licenseText}>
        {termsLink !== '' && (
          <b>
            <Link href={termsLink} className={classes.agreementLink} target="_blank" rel="noreferrer">
              {t<string>('misc.downloadTerms')}
            </Link>
          </b>
        )}

        <FormControlLabel
          className={classes.checkbox}
          label={t<string>('userData.parental_consent')}
          onChange={handleChange}
          control={<Checkbox defaultChecked={parental_consent} name="parental_consent" />}
          disabled={parentalConsentDisabled}
        />
        {tooYoungAndNoConsent && <FormHelperText error>{t<string>('userData.tooYoung')}</FormHelperText>}
        <FormControlLabel
          className={classes.checkbox}
          label={t<string>('userData.has_agreed')}
          onChange={handleChange}
          control={<Checkbox defaultChecked={has_agreed} name="has_agreed" required />}
        />
        {agreementError && <FormHelperText error>{t<string>('formValidation.agreementRequired')}</FormHelperText>}
      </Grid>
    </Grid>
  );
};
