import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { Slide } from '../../types';
import { Embed } from '../common';

interface Props {
  slide?: Slide;
}

export const SlideView: React.FC<Props> = ({ slide }) => (
  <Container>
    <Typography variant="h2">{slide?.name || 'Slide'}</Typography>
    <Typography variant="h3">{slide?.content_type}</Typography>
    {slide && <Embed slide={slide} />}
  </Container>
);
