import React, { useEffect } from 'react';
import { Link, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { colors } from '../../theme';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  paper: {
    padding: `${spacing(3)} ${spacing(2)}`,
    marginBottom: spacing(4),
    [breakpoints.up('sm')]: {
      padding: spacing(4),
    },
  },
  progressBar: {
    padding: spacing(2),
    maxWidth: '125px',
  },
}));

interface Props {
  currentStep: number;
  totalSteps: number;
  landingPageUrl: string;
}

export const RegisterComplete: React.FC<Props> = ({ currentStep, totalSteps, landingPageUrl }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  useEffect(() => {
    if (landingPageUrl) window.location.replace(landingPageUrl);
  }, [landingPageUrl]);

  return landingPageUrl ? (
    <Link href={landingPageUrl} />
  ) : (
    <Paper className={classes.paper}>
      <CircularProgressbar
        className={classes.progressBar}
        value={6}
        maxValue={6}
        text={`${currentStep}/${totalSteps}`}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: colors.secondary,
          textColor: colors.textBlack,
        })}
      />
      <h1>{t<string>('courseWebshop.paymentCompletedHeader')}</h1>
      <p>{t<string>('courseWebshop.paymentCompletedText1')}</p>
      <p>{t<string>('courseWebshop.paymentCompletedText2')}</p>
    </Paper>
  );
};
