import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Card,
  CardActionArea,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  Checkbox,
  Box,
  Typography,
  IconButton,
  AppBar,
  Dialog,
  Toolbar,
} from '@material-ui/core';
//import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { Api } from '../../api';
import { useAuthContext, useStudyPathContext } from '../../context';
import { Slide, SurveyQuestion, SurveySection } from '../../types';
import { endpoints } from '../../utils';
import { parseImageSrcFromString } from '../../utils/string';

import Fullscreen from '@material-ui/icons/Fullscreen';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles(({ spacing }) => ({
  questionCard: {
    marginTop: spacing(2),
    borderRadius: '0.5rem',
  },

  formControlLabel: {
    width: '100%',
    textAlign: 'left',
  },

  true: {
    width: '100%',
    textAlign: 'left',

    '& .Mui-checked': {
      color: 'green',

      '&::after': {
        content: "'✓'",
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: '20px',
      },

      '& .MuiIconButton-label': {
        color: 'transparent',
      },

      '& .MuiTouchRipple-root': {
        background: 'green',
        opacity: 0.1,
        width: '50%',
        display: 'flex',
        height: '50%',
        margin: 'auto',
      },
    },
  },

  false: {
    width: '100%',
    textAlign: 'left',

    '& .Mui-checked': {
      color: 'red',
      position: 'relative',

      '&::after': {
        content: "'✕'",
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: '20px',
      },

      '& .MuiIconButton-label': {
        color: 'transparent',
      },

      '& .MuiTouchRipple-root': {
        background: 'red',
        opacity: 0.1,
        width: '50%',
        display: 'flex',
        height: '50%',
        margin: 'auto',
      },
    },
  },

  questionContainer: {
    width: '100%',

    '& label': {
      width: '100%',
      padding: spacing(2),
      margin: 0,
    },
  },

  openButton: {
    '@media (max-width: 1200px)': {
      paddingLeft: '0',
      position: 'absolute',
    },
  },

  root: {
    position: 'relative',
    display: 'flex',
    minHeight: '25rem',
    '@media (max-width: 1200px)': {
      minHeight: '0',
      marginTop: '2rem',
    },
  },

  embed: {
    position: 'absolute',
    margin: 'auto',
    width: '100%',
    height: '100%',
    border: 'none',
    objectFit: 'contain',
  },

  embedNotActive: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    border: 'none',
    objectFit: 'contain',
  },

  imageContainer: {
    padding: '5rem',
    '@media (max-width: 1200px)': {
      padding: '1rem',
      paddingTop: '5rem',
    },
  },

  image: {
    width: '100%',
    height: '100%',
    zIndex: 9999,
    margin: 'auto',
    objectFit: 'contain',
    cursor: 'none',
  },

  appBar: {
    backgroundColor: 'transparent',
    zIndex: 9998,
    boxShadow: 'none',
  },

  button: {
    '&:hover': {
      zIndex: 9998,
      color: 'red',
      height: '100%',
    },
  },

  dialog: {
    padding: 0,
    backgroundColor: 'rgb(53 43 43 / 50%)',
    zIndex: 9998,
    '&:hover': { zIndex: 9998, backgroundColor: 'transparent' },
  },
  appbar: {},
}));

interface Props {
  slide: Slide;
}

export const Certification: React.FC<Props> = ({
  slide: {
    survey_id,
    section_id,
    is_practice_exam,
    is_simulator,
    is_eas_lesson,
    is_night_driving_lesson,
    is_rtk_lesson,
    is_slippery_driving_lesson,
  },
}) => {
  const classes = useStyles();
  const { token } = useAuthContext();
  const [isRight, setIsRight] = useState(false);
  const [fs, setFs] = useState(false);
  const [imgID, setImgId] = useState(0);
  const [[X, Y], setXY] = useState([0, 0]);
  const [[ImgWidth, ImgHeight], setSize] = useState([0, 0]);
  const [ShowMagnifier, setShowMagnifier] = useState(false);

  const {
    setSurveyId,
    answers,
    setAnswers,
    questionsList,
    setQuestionsList,
    setSurveys,
    questionIndex,
    examIndex,
    setQuestionIndex,
  } = useStudyPathContext();

  // Fetch survey and questions data.
  useEffect(() => {
    (async () => {
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data: sectionsData } = await Api.get<SurveySection[]>(endpoints.examSections(survey_id), requestConfig);

      const res = await Promise.all(
        sectionsData.map((s) => Api.get<SurveyQuestion[]>(endpoints.examQuestions(survey_id, s.id), requestConfig)),
      );

      setSurveyId(survey_id);
      setSurveys((s) => [...s, survey_id]);
      setQuestionsList((oldArray) => [
        ...oldArray,
        res
          .map((r) => r.data)
          .map((q) => q.filter((q) => ['simple_choice', 'multiple_choice'].includes(q.question_type)))
          .flat(),
      ]);
    })();
  }, [section_id, setSurveys, setSurveyId, survey_id, token, setQuestionsList]);

  // Reset progress when unmounting.
  useEffect(() => {
    return () => {
      setQuestionIndex(0);
      setSurveys([]);
      setQuestionsList([]);
      setSurveyId(null);
      setAnswers([]);
    };
  }, [setAnswers, setQuestionIndex, setSurveyId, setQuestionsList, setSurveys]);

  // Update answers for simple choice answers.
  const handleSimpleChoiceAnswerChange =
    (questionId: number, answerId: number, isCorrect: boolean, data: any) =>
    (e: ChangeEvent<Record<string, string>>): void => {
      //console.log(data);
      if (isCorrect) {
        setIsRight(true);
      } else {
        setIsRight(false);
      }

      if (answers.some((a) => a.questionId === questionId)) {
        setAnswers(
          answers.map((a) => {
            if (a.questionId === questionId) {
              a.answerId = answerId;
              a.isCorrect = isCorrect;
              a.values = [e.target.value];
            }
            return a;
          }),
        );
      } else {
        setAnswers([...answers, { questionId, answerId, isCorrect, values: [e.target.value] }]);
      }
    };

  // Update answers for multiple choice answers.
  const handleMultipleChoiceAnswerChange =
    (questionId: number, answerId: number, isCorrect: boolean) =>
    (e: ChangeEvent<Record<string, string>>, checked: boolean): void => {
      if (!checked) {
        setAnswers(answers.filter((a) => a.answerId !== answerId));
        return;
      }

      if (answers.some((a) => a.questionId === questionId && a.answerId === answerId)) {
        setAnswers(
          answers.map((a) => {
            if (a.questionId === questionId) {
              a.answerId = answerId;
              a.values = [...a.values, e.target.value];
            }

            return a;
          }),
        );

        return;
      }

      setAnswers([...answers, { questionId, answerId, isCorrect, values: [e.target.value] }]);
    };

  const insertUrlToLink = (html: string) => {
    return html.replace('src="', `src="${process.env.REACT_APP_API_BASE_URL}`);
  };

  const renderQuestionDescription = (q: SurveyQuestion) => {
    const magnifierHeight = 300;
    const magnifieWidth = 300;
    const imgPadding = 380;
    const zoomLevel = 1.5;
    const relativeSrc = parseImageSrcFromString(q.description);

    if (relativeSrc) {
      if (!fs) {
        return (
          <Box className={classes.root}>
            <img
              className={classes.embedNotActive}
              width="100%"
              src={`${process.env.REACT_APP_API_BASE_URL}${relativeSrc}`}
              alt="i"
            />
            <Box style={{ position: 'absolute' }}>
              <IconButton
                color="primary"
                onClick={(): void => {
                  setImgId(q.id);
                  setFs(true);
                }}
              >
                <Fullscreen />
              </IconButton>
            </Box>
          </Box>
        );
      } else {
        if (imgID === q.id) {
          return (
            <Dialog
              open
              fullWidth
              fullScreen
              className={classes.dialog}
              style={{ zIndex: imgID === q.id ? 9998 : 1300 }}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    className={classes.button}
                    style={{ color: imgID === q.id ? '#f45c61' : 'transparent' }}
                    onClick={(): void => setFs(false)}
                  >
                    <Close color="primary" />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <div className={classes.imageContainer}>
                <img
                  className={classes.image}
                  width="100%"
                  src={`${process.env.REACT_APP_API_BASE_URL}${relativeSrc}`}
                  alt="i"
                  onMouseEnter={(e) => {
                    // update image size and turn-on magnifier
                    const elem = e.currentTarget;
                    const { width, height } = elem.getBoundingClientRect();
                    setSize([width, height]);
                    setShowMagnifier(true);
                  }}
                  onMouseMove={(e) => {
                    // update cursor position
                    const elem = e.currentTarget;
                    const { top, left } = elem.getBoundingClientRect();

                    // calculate cursor position on the image
                    const X = e.pageX - left - window.pageXOffset;
                    const Y = e.pageY - top - window.pageYOffset;
                    setXY([X, Y]);
                  }}
                  onMouseLeave={() => {
                    // close magnifier
                    setShowMagnifier(false);
                  }}
                />
                <div
                  style={{
                    display: ShowMagnifier ? '' : 'none',
                    position: 'absolute',

                    // prevent magnifier blocks the mousemove event of img
                    pointerEvents: 'none',
                    // set size of magnifier
                    height: `${magnifierHeight}px`,
                    width: `${magnifieWidth}px`,

                    // move element center to cursor pos
                    top: `${Y - magnifierHeight + imgPadding / 2}px`,
                    left: `${X - magnifieWidth + imgPadding / 2}px`,
                    opacity: '1', // reduce opacity so you can verify position
                    backgroundImage: `url('${`${process.env.REACT_APP_API_BASE_URL}${relativeSrc}`}')`,
                    backgroundRepeat: 'no-repeat',
                    zIndex: '9999',

                    //calculate zoomed image size
                    backgroundSize: `${ImgWidth * zoomLevel}px ${ImgHeight * zoomLevel}px`,

                    //calculate position of zoomed image.
                    backgroundPositionX: `${-X * zoomLevel + magnifieWidth / 2}px`,
                    backgroundPositionY: `${-Y * zoomLevel + magnifierHeight / 2}px`,
                  }}
                ></div>
              </div>
            </Dialog>
          );
        }
      }
    }

    return (
      <>
        {!!q.description && (
          <Box textAlign="left" dangerouslySetInnerHTML={{ __html: insertUrlToLink(q.description) }} />
        )}
      </>
    );
  };

  /*


  customer asked this to be removed, 


  const handleCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let markedQuestions: number[] = [];

      const markedQuestionsString = String(localStorage.getItem('marked_questions'));
      if (markedQuestionsString.length > 0) markedQuestions = JSON.parse(markedQuestionsString);

      if (e.target.checked) {
        markedQuestions.push(questionIndex);
      } else {
        const index = markedQuestions.indexOf(questionIndex, 0);
        if (index > -1) {
          markedQuestions.splice(index, 1);
        }
      }
      localStorage.setItem('marked_questions', JSON.stringify(markedQuestions));
    },
    [questionIndex],
  );

  const renderCheckQuestion = useMemo(
    () =>
      is_practice_exam && (
        <Box>
          {t<string>('practiceExams.mark')} <Checkbox onChange={handleCheck} />
        </Box>
      ),
    [is_practice_exam, handleCheck, t],
  );
  {renderCheckQuestion;}
        */

  //single slide

  const slideRef = React.createRef();

  useEffect(() => {
    // @ts-ignore
    if (slideRef.current) {
      // @ts-ignore
      if (slideRef.current.activeSlide !== null) {
        // @ts-ignore
        const height = slideRef.current.activeSlide.firstChild.offsetHeight + 30;
        // @ts-ignore
        slideRef.current.containerNode.parentElement.style.height = height + 'px';
        // @ts-ignore
        slideRef.current.containerNode.style.height = height + 'px';
      }
    }
  }, [slideRef]);

  if (!!questionsList && questionsList?.length > 0 && questionsList[examIndex])
    return (
      <SwipeableViews
        index={questionIndex}
        containerStyle={{ overflow: 'visible' }}
        disabled
        animateHeight={true}
        // @ts-ignore
        ref={(elem: SwipeableViews) => (slideRef.current = elem)}
      >
        {questionsList[examIndex].map((q) => {
          if (q.question_type === 'simple_choice') {
            return (
              <Box key={q.id} style={{ paddingBottom: '1rem' }}>
                <Box textAlign="left">
                  <Typography variant="h3">{q.title}</Typography>
                  {renderQuestionDescription(q)}
                </Box>

                {q.suggested_answer_ids.map((s) => (
                  <Card key={s.id} className={classes.questionCard}>
                    {(is_eas_lesson || is_night_driving_lesson || is_rtk_lesson || is_slippery_driving_lesson) && (
                      <CardActionArea className={`${isRight ? classes.true : classes.false}`}>
                        <Grid className={classes.questionContainer} container>
                          <FormControlLabel
                            value={s.value}
                            label={s.value}
                            checked={answers.some((a) => a.answerId === s.id)}
                            control={<Radio color="primary" />}
                            onChange={handleSimpleChoiceAnswerChange(q.id, s.id, s.is_correct, q)}
                          />
                        </Grid>
                      </CardActionArea>
                    )}

                    {(is_practice_exam || is_simulator) && (
                      <CardActionArea>
                        <Grid className={classes.questionContainer} container>
                          <FormControlLabel
                            className={classes.formControlLabel}
                            value={s.value}
                            label={s.value}
                            checked={answers.some((a) => a.answerId === s.id)}
                            control={<Radio color="primary" />}
                            onChange={handleSimpleChoiceAnswerChange(q.id, s.id, s.is_correct, q)}
                          />
                        </Grid>
                      </CardActionArea>
                    )}

                    {!is_practice_exam &&
                      !is_simulator &&
                      !is_eas_lesson &&
                      !is_night_driving_lesson &&
                      !is_rtk_lesson &&
                      !is_slippery_driving_lesson && (
                        <CardActionArea>
                          <Grid className={classes.questionContainer} container>
                            <FormControlLabel
                              className={classes.formControlLabel}
                              value={s.value}
                              label={s.value}
                              checked={answers.some((a) => a.answerId === s.id)}
                              control={<Radio color="primary" />}
                              onChange={handleSimpleChoiceAnswerChange(q.id, s.id, s.is_correct, q)}
                            />
                          </Grid>
                        </CardActionArea>
                      )}
                  </Card>
                ))}
              </Box>
            );
          }

          if (q.question_type === 'multiple_choice') {
            return (
              <Box key={q.id}>
                <Box textAlign="left">
                  <Typography variant="h3">{q.title}</Typography>
                  {renderQuestionDescription(q)}
                </Box>
                {q.suggested_answer_ids.map((s) => (
                  <Card key={s.id} className={classes.questionCard}>
                    <CardActionArea>
                      <Grid className={classes.questionContainer} container>
                        <FormControlLabel
                          className={classes.formControlLabel}
                          value={s.value}
                          label={s.value}
                          checked={answers.some((a) => a.answerId === s.id)}
                          control={<Checkbox color="primary" />}
                          onChange={handleMultipleChoiceAnswerChange(q.id, s.id, s.is_correct)}
                        />
                      </Grid>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            );
          }
        })}
      </SwipeableViews>
    );

  return <></>;
};
