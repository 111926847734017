import React, { useEffect, useMemo, useState } from 'react';
import { Link, makeStyles, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { KeyboardArrowRight } from '@material-ui/icons';
import { useAuthContext, useStudyPathContext } from '../context';
import { endpoints } from '../utils';
import { Api } from '../api';
import { Header, Embed, MainTemplate } from '../components';
import { Slide } from '../types';

const useStyles = makeStyles(({ palette, spacing }) => ({
  liContainer: {
    marginTop: spacing(2),
    borderTop: `0.05rem solid ${palette.grey[400]}`,
  },
}));

export const Materials: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [materials, setMaterials] = useState<Slide[]>([]);
  const { token, logout } = useAuthContext();
  const [material, setMaterial] = useState<Slide>();
  const { setStudyPathPhoneNumber } = useStudyPathContext();

  useEffect(() => {
    (async (): Promise<void> => {
      if (token) {
        try {
          const { data } = await Api.get(endpoints.getStudyMaterials, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setMaterials(data.filter((m: Record<string, unknown>) => m.is_category === false));
        } catch {
          logout();
          await Api.get(endpoints.informLogout, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setStudyPathPhoneNumber('');
        }
      }
    })();
  }, [token, history, logout, setStudyPathPhoneNumber]);

  const renderHeader = useMemo(() => <Header titleText={t<string>('profile.materials')} />, [t]);

  const renderMaterials = useMemo(() => {
    if (material === undefined) {
      return (
        <List data-testid="materialsList">
          {materials.map((m) => {
            return (
              <ListItem key={m.id} component={Link} onClick={(): void => setMaterial(m)}>
                <ListItemText primary={m.name} />
                <KeyboardArrowRight />
              </ListItem>
            );
          })}
        </List>
      );
    }

    // TODO: Responsive IFrame size
    return (
      <Container>
        <Embed slide={material} />
      </Container>
    );
  }, [materials, material]);

  // TODO: Should return link when setting materials, but embed code has a bug
  return (
    <MainTemplate>
      <Container data-testid="materialContainer">{renderHeader}</Container>
      <Container className={classes.liContainer} disableGutters>
        {renderMaterials}
      </Container>
    </MainTemplate>
  );
};
