import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Api, getAccessToken } from '../api';
import { ProfileData, ProfileDataResponse } from '../types';
import { endpoints, urls } from '../utils';

const { token: initialToken = '', expiresIn: initialExpiresIn = '' } = localStorage.getItem('epic_user')
  ? JSON.parse(localStorage.getItem('epic_user') ?? '')
  : {};

const initialProfileData = {
  birth_date: '',
  city: '',
  country: [[]],
  current_course: 0,
  email: '',
  first_name: '',
  home_city: '',
  language: '',
  last_name: '',
  licenses: [],
  nin: '',
  phone: '',
  street: '',
  zip: '',
  latest_driving_session_reviewed: null,
  latest_done_driving_lesson_id: null,
  study_phone_number: '',
  open_invoices_paid: false,
  transmission_type_preference: 'manual',
  course: null,
};

const initialState = {
  authData: {
    token: initialToken,
    expiresIn: initialExpiresIn,
    loading: false,
    error: false,
  },
  setAuthData: (): void => {},
  profileData: initialProfileData,
  setProfileData: (): void => {},
};

interface AuthData {
  token: string;
  expiresIn: number | string;
  loading: boolean;
  error: boolean;
}

interface AuthContextType {
  authData: AuthData;
  setAuthData: Dispatch<SetStateAction<AuthData>>;
  profileData: ProfileData;
  setProfileData: Dispatch<SetStateAction<ProfileData>>;
}

const AuthContext = createContext<AuthContextType>(initialState);

interface UseAuthContext extends AuthData {
  login: (email: string, password: string) => void;
  logout: () => void;
  getProfileData: (token: string) => void;
  profileData: ProfileData;
  logActivity: (action: string, description?: string, slide_id?: number) => void;
  refreshServerToken: () => void;
  logSlideActivity: (action: string, slide_id?: number) => void;
}

export const useAuthContext = (): UseAuthContext => {
  const history = useHistory();
  const { setAuthData, authData, profileData, setProfileData } = useContext(AuthContext);

  const logout = useCallback((): void => {
    setAuthData({
      ...authData,
      token: '',
      expiresIn: '',
    });

    localStorage.removeItem('epic_user');
    history.push(urls.login);
  }, [authData, setAuthData, history]);

  const login = useCallback(
    async (email: string, password: string): Promise<void> => {
      setAuthData({ ...authData, loading: true });

      try {
        const {
          data: { access_token: token, expires_in: resExpiresIn },
        } = await getAccessToken(email, password);
        const expiresIn = Number(resExpiresIn);
        const newAuthData = {
          token,
          expiresIn,
        };

        setAuthData({
          ...authData,
          ...newAuthData,
          loading: false,
        });

        // TODO: This is not necessarily secure enough! https://thesecurityvault.com/appsec/auth-token-in-localstorage
        localStorage.setItem('epic_user', JSON.stringify(newAuthData));
        history.push(urls.home);
      } catch {
        setAuthData({ ...authData, error: true, loading: false });
      }
    },
    [authData, history, setAuthData],
  );

  const refreshServerToken = useCallback(async (): Promise<void> => {
    const returnVals = await Api.post(
      endpoints.refreshToken,
      {},
      {
        headers: {
          Authorization: `Bearer ${authData.token}`,
        },
      },
    );
    const expiration = returnVals.data;
    const expiresIn = Number(expiration.expiration_time_seconds);
    setAuthData({ ...authData, expiresIn });
  }, [authData, setAuthData]);

  const logActivity = useCallback(
    async (action: string, description?: string, slide_id?: number): Promise<void> => {
      if (authData.token) {
        const path = window.location.pathname;
        await Api.post(
          endpoints.log,
          {
            place: path,
            action,
            description,
            slide_id,
          },
          {
            headers: {
              Authorization: `Bearer ${authData.token}`,
            },
          },
        );
      }
    },
    [authData],
  );

  const logSlideActivity = useCallback(
    async (action: string, slide_id?: number): Promise<void> => {
      logActivity(action, '', slide_id);
    },
    [logActivity],
  );

  const getProfileData = useCallback(
    async (token: string): Promise<void> => {
      const { data } = await Api.get<ProfileDataResponse>(endpoints.getStudentProfile, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const strValueOrEmptyStr = (any: any) => (typeof any === 'string' ? any : '');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const numOrZero = (any: any) => (typeof any === 'number' ? any : 0);

      const city = strValueOrEmptyStr(data.city);
      const home_city = strValueOrEmptyStr(data.home_city);
      const current_course = numOrZero(data.current_course);
      const phone = strValueOrEmptyStr(data.phone);
      const street = strValueOrEmptyStr(data.street);
      const transmission_type_preference = strValueOrEmptyStr(data.transmission_type_preference);
      const latest_done_driving_lesson_id =
        typeof data.latest_done_driving_lesson_id === 'boolean' ? null : data.latest_done_driving_lesson_id;
      const _data = {
        ...data,
        city,
        current_course,
        home_city,
        phone,
        street,
        latest_done_driving_lesson_id,
        transmission_type_preference,
      };

      setProfileData(_data);
    },
    [setProfileData],
  );

  return {
    login,
    logout,
    getProfileData,
    logActivity,
    refreshServerToken,
    logSlideActivity,
    profileData,
    ...authData,
  };
};

interface Props {
  children?: React.ReactNode;
}
export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthData>({
    token: initialToken,
    expiresIn: initialExpiresIn,
    loading: false,
    error: false,
  });

  const [profileData, setProfileData] = useState<ProfileData>(initialProfileData);

  return (
    <AuthContext.Provider value={{ authData, setAuthData, profileData, setProfileData }}>
      {children}
    </AuthContext.Provider>
  );
};
