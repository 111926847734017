import { ChangeEvent, Dispatch, SetStateAction, MouseEvent } from 'react';
import { ZodIssue } from 'zod';

export interface SuggestedAnswerID {
  answer_score: number;
  id: number;
  is_correct: boolean;
  value: string;
}

export interface SurveyQuestion {
  answer_date: false;
  answer_datetime: false;
  answer_numerical_box: number;
  answer_score: number;
  description: string;
  id: number;
  is_time_limited: boolean;
  question_type: string;
  questions_selection: string;
  random_questions_count: number;
  suggested_answer_ids: SuggestedAnswerID[];
  time_limit: number;
  title: string;
}

export interface Survey {
  id: number;
  description: string;
  is_time_limited: boolean;
  layout: string;
  progression_mode: string;
  questions: number[];
  scoring_success_min: number;
  scoring_type: string;
  time_limit: number;
  title: string;
  users_can_go_back: boolean;
}

export interface Course {
  additional_companies_ids: number[];
  company_id: number;
  company_name: string;
  description: false;
  id: number;
  is_bundle: false;
  license_code: string;
  license_id: number;
  license_name: string;
  min_req_age_days: number;
  min_req_age_months: number;
  min_req_age_years: number;
  name: string;
  product_id: number;
  product_ids: number[];
  req_licenses_id: number[];
  tag_ids: number[];
  user_terms_link: string;
  user_terms_text: string;
  landing_page_url: string;
  completed: boolean;
  completed_date: string;
  can_be_driven_automatic: boolean;
  contact_info: string;
}

export interface ProfileDataResponse {
  all_invoices_paid: boolean;
  birth_date: string;
  city: string | false;
  country: never[][];
  course: Course | null;
  current_course: number | false;
  email: string;
  first_name: string;
  home_city: string | false;
  language: string;
  last_name: string;
  latest_done_driving_lesson_id: number | null | false;
  latest_driving_session_reviewed: boolean | null;
  licenses: string[];
  nin: string;
  open_invoices_paid: boolean;
  phone: false;
  street: false;
  study_phone_number: string;
  transmission_type_preference: string | false;
  zip: string;
}

export interface ProfileData {
  birth_date: string;
  city: string;
  country: never[][];
  course: Course | null;
  current_course: number;
  email: string;
  first_name: string;
  home_city: string;
  language: string;
  last_name: string;
  licenses: string[];
  nin: string;
  phone: string;
  street: string;
  zip: string;
  latest_driving_session_reviewed: boolean | null;
  latest_done_driving_lesson_id: number | null;
  study_phone_number: string;
  open_invoices_paid: boolean;
  transmission_type_preference: string;
}

type ContentType = 'document' | 'video' | 'infographic' | 'certification' | 'presentation' | 'notification' | 'link';

export interface Slide {
  completed: boolean;
  completion_time: number;
  survey_time_limit: number;
  is_time_limited: boolean;
  content_type: ContentType;
  description: string;
  document_id: string;
  embed_code: string;
  embed_url: string;
  id: number;
  is_category: boolean;
  is_section: boolean;
  name: string;
  section_id: number;
  sequence: number;
  slide_resource_downloadable: boolean;
  url: string;
  survey_id: number;
  survey: Survey;
  is_practice_exam: boolean;
  is_eas_lesson: boolean;
  is_night_driving_lesson: boolean;
  is_rtk_lesson: boolean;
  is_simulator: boolean;
  is_slippery_driving_lesson: boolean;
  questions: SurveyQuestion[];
}

export interface Category {
  completed: boolean;
  completion_time: number;
  content_type: ContentType;
  description: boolean;
  document_id: boolean;
  embed_code: boolean;
  embed_url: boolean;
  id: number;
  is_practice_exam: boolean;
  is_category: boolean;
  is_category_title: boolean;
  is_section: boolean;
  name: string;
  section_id: number;
  sequence: number;
  slide_resource_downloadable: boolean;
  slides: Slide[];
  url: boolean;
  rating?: number;
}

export interface Section {
  categories: Category[];
  completed: boolean;
  completion_time: number;
  content_type: ContentType;
  description: boolean;
  document_id: boolean;
  embed_code: boolean;
  embed_url: boolean;
  id: number;
  is_category: boolean;
  is_section: boolean;
  name: string;
  section_id: boolean;
  sequence: number;
  slide_resource_downloadable: boolean;
  url: boolean;
  section_type: 'supervised' | 'study_material' | 'self_learning';
}

export interface RegisterFormValues {
  selected_school: string;
  selected_course: string;
  nin: string;
  last_name: string;
  first_name: string;
  street: string;
  zip: string;
  city: string;
  home_city: string;
  phone: string;
  email: string;
  confirm_email: string;
  birth_country: string;
  native_language: string;
  licenses: string[];
  transmission_type_preference: string;
  can_be_driven_automatic: boolean;
  has_professional_competence: boolean;
  additional_info: string;
  parental_consent: boolean;
  has_agreed: boolean;
  payer: string;
  payment: string;
  license_day: string;
  license_month: string;
  license_year: string;
  recurrences: number;
  nin_other: string;
  first_names_other: string;
  last_name_other: string;
  street_other: string;
  zip_other: string;
  city_other: string;
  phone_other: string;
  email_other: string;
  confirm_email_other: string;
}

export interface CheckoutFormValues {
  selected_school?: string;
  selected_course?: string;
  nin?: string;
  last_name?: string;
  first_name?: string;
  street?: string;
  zip?: string;
  city?: string;
  home_city?: string;
  phone?: string;
  email?: string;
  confirm_email?: string;
  birth_country: string;
  native_language?: string;
  licenses?: string[];
  has_professional_competence?: boolean;
  additional_info?: string;
  parental_consent?: boolean;
  has_agreed?: boolean;
  payer: string;
  payment: string;
  license_day?: string;
  license_month?: string;
  license_year?: string;
  recurrences: number;
  nin_other: string;
  first_names_other: string;
  last_name_other: string;
  street_other: string;
  zip_other: string;
  city_other: string;
  phone_other: string;
  email_other: string;
  confirm_email_other: string;
}

export type RegisterFormEvent = { name?: string; licenses?: string[] } & ChangeEvent<{
  name?: string;
  value?: unknown;
  type?: string;
  checked?: boolean;
}>;

export interface RegisterFormProps {
  handleChange: (e: RegisterFormEvent) => void;
  formErrors: ZodIssue[];
  formValues: RegisterFormValues;
  setFormValues?: Dispatch<SetStateAction<RegisterFormValues>>;
  termsLink: string;
  termsText: string;
}

export type CheckoutFormEvent = { name?: string; licenses?: string[] } & ChangeEvent<{
  name?: string;
  value?: unknown;
  type?: string;
  checked?: boolean;
}>;

export interface CheckoutFormProps {
  handleChange: (e: CheckoutFormEvent) => void;
  formErrors: ZodIssue[];
  formValues: CheckoutFormValues;
  setFormValues?: Dispatch<SetStateAction<CheckoutFormValues>>;
  termsLink?: string;
  termsText?: string;
}
export interface RegistrationControlProps {
  isLoading: boolean;
  handlePrev: (e: MouseEvent<HTMLButtonElement>) => void;
  handleNext: (e: MouseEvent<HTMLButtonElement>) => void;
  totalSteps: number;
  currentStep: number;
  formValues: RegisterFormValues;
}

export interface CheckoutControlProps {
  isLoading: boolean;
  handlePrev: (e: MouseEvent<HTMLButtonElement>) => void;
  handleNext: (e: MouseEvent<HTMLButtonElement>) => void;
  totalSteps: number;
  currentStep: number;
  formValues: CheckoutFormValues;
}
export interface Material {
  contentType: string;
  completionTime: number;
  is_category: boolean;
  id: number;
  name: string;
  url: string;
  embedUrlLink: string;
}

export interface SlideProps {
  slide?: Slide;
}

export interface License {
  name: string;
  code: string;
  type: string;
}

export interface Questions {
  survey_id: number;
  questions: SurveyQuestion[];
}

export interface SurveyAnswer {
  id: number;
  value: string;
  is_correct: boolean;
  answer_score: number;
}

export interface SurveyCorrectAnswers {
  id: number;
  question: string;
  question_feedback: string | boolean;
  answers: SurveyAnswer[];
  user_answers: SurveyAnswer[];
}

export interface SurveyResultsSection {
  max_score: number;
  min_scoring_success: number;
  score: number;
  title: string;
  questions: SurveyCorrectAnswers[];
}

export interface SurveyResults {
  exam_passed: boolean;
  scoring_percentage: number;
  scoring_total: number;
  total_correct_answers: number;
  sections: SurveyResultsSection[];
  max_score: number;
  min_scoring_success: number;
}

export interface SurveySection {
  id: number;
  title: string;
}

export interface SurveyQuestionAnswer {
  questionId: number;
  answerId: number | null;
  values: string[];
  isCorrect: boolean;
}

export interface OpenHours {
  open_from: string;
  open_to: string;
  notes: string;
}

export interface Enrollment {
  additional_companies_ids: number[];
  company_id: number;
  description: boolean;
  id: number;
  is_bundle: boolean;
  license_id: number;
  min_req_age_days: number;
  min_req_age_months: number;
  min_req_age_years: number;
  name: string;
  product_id: number;
  product_ids: number[];
  req_licenses_id: number[];
  tag_ids: number[];
  user_terms_link: string;
  user_terms_text: string;
  user_terms_summary: string;
  user_terms_filename: string;
  completed: boolean;
  completed_date: string;
}

export interface AccessTokenData {
  access_token: string;
  expires_in: string;
}

export interface StudentAttendance {
  student_id: string;
  name: string;
  section_id: number;
  category_id: number;
  slide_id: string;
}

export interface Instructor {
  id: number;
  name: string;
  rating: number;
}

export interface Lesson {
  attendances: StudentAttendance[];
  company: string;
  end_date: string;
  id: string;
  instructor: Instructor;
  is_enrolled: boolean;
  is_simulator: boolean;
  license: Pick<License, 'name'>;
  name: string;
  number_of_days: number;
  service: string;
  start_date: string;
  state: string;
  vehicle: string;
  description: string;
  show_description: boolean;
  type: string;
}

export interface DrivingLessonInfo {
  left_extra_driving_lesson_hours: number;
  left_mandatory_driving_lesson_hours: number;
  total_extra_driving_lesson_hours: number;
  total_mandatory_driving_lesson_hours: number;
  used_extra_driving_lesson_hours: number;
  used_mandatory_driving_lesson_hours: number;
  student_current_lesson: string;
  student_current_lesson_type: string;
  student_current_simulator: boolean;
  passed_driving_exam: boolean;
  undone_driving_exam: boolean;
  extra_exam_available: boolean;
}

export interface Recurrence {
  amount: string;
  recurrences: number;
}

export interface AvailableRecurrence {
  recurrences: Recurrence[];
  total_amount: string;
}

export enum ExamState {
  NotStarted = 'notstarted',
  Started = 'started',
  Ended = 'ended',
}

export interface Service {
  type: string;
  name: string;
  product_id: number;
}
