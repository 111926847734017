import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { MainTemplate, SlideView } from '../components';
import { Api } from '../api';

export const Course: React.FC = () => {
  const [courseName, setCourseName] = useState('');
  const { courseId } = useParams<{ courseId: string }>();
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    (async (): Promise<void> => {
      const {
        data: { name, slides },
      } = await Api.get(`/courses/${courseId}/slides`);

      setCourseName(name);
      setSlides(slides);
    })();
  }, [courseId]);

  return (
    <MainTemplate>
      <Container>
        <h1>{courseName}</h1>
        <div>
          {slides.map((slide) => (
            <SlideView slide={slide} />
          ))}
        </div>
      </Container>
    </MainTemplate>
  );
};
