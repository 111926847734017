import React from 'react';
import { Dialog, Button, DialogContent, DialogActions, DialogContentText, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
  isOpen: boolean;
  handleClose: () => void;
}

export const SimpleDialog: React.FC<Props> = ({ text, isOpen, handleClose }) => {
  const { t } = useTranslation();
  // possible html tags
  const htmlRegExp = new RegExp('</?[a-z][sS]*>', 'ig');
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {!htmlRegExp.test(text) ? text : <Box dangerouslySetInnerHTML={{ __html: text }} />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t<string>('home.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
