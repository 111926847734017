import React, { ChangeEventHandler, FormEvent, useState, MouseEvent } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import * as zod from 'zod';
import { ZodIssue } from 'zod';
import { MainTemplate, Header } from '../components';
import { useAuthContext } from '../context';
import { Api } from '../api';
import { endpoints, urls } from '../utils';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  paper: {
    marginTop: spacing(2),
    padding: `${spacing(3)} ${spacing(2)}`,
    [breakpoints.up('sm')]: {
      padding: spacing(4),
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: spacing(3),
  },
  textField: {
    marginTop: spacing(2),
  },
  submitButton: {
    marginTop: spacing(2),
  },
  buttonProgress: {
    color: palette.common.white,
  },
}));

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const passwordValidation = zod
  .object({
    newPassword: zod.string().min(6),
    confirmNewPassword: zod.string().min(6),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "Passwords don't match",
    path: ['confirm'],
  });

export const ChangePassword: React.FC = () => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [values, setValues] = useState(initialValues);
  const { token } = useAuthContext();
  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState<ZodIssue[]>([]);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const handleTextFieldChange: ChangeEventHandler<HTMLInputElement> = ({ target }) =>
    setValues({ ...values, [target.name]: target.value });

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmNewPassword } = values;

    try {
      passwordValidation.parse({ newPassword, confirmNewPassword });
    } catch (e) {
      if (e instanceof zod.ZodError) {
        setErrors(e.errors);
        return;
      }
    }
    setSubmitting(true);
    setError('');

    try {
      await Api.post(
        endpoints.studentPassword,
        {
          password_new: newPassword,
          password_old: oldPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setErrors([]);
      setPasswordChanged(true);
      setValues(initialValues);
    } catch {
      setError(t<string>('misc.changePasswordError'));
    } finally {
      setSubmitting(false);
    }
  };

  const handlePrev = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    history.push(urls.profile);
  };

  return (
    <MainTemplate>
      <Container maxWidth="sm">
        <Header handlePrev={handlePrev} />
        <Paper className={classes.paper}>
          <Typography variant="h2" className={classes.title}>
            {t<string>('changePassword.title')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t<string>('changePassword.subtitle')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t<string>('changePassword.passwordInfo')}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              className={classes.textField}
              value={values.oldPassword}
              onChange={handleTextFieldChange}
              label={t<string>('changePassword.oldPassword')}
              name="oldPassword"
              required
              disabled={submitting}
              type="password"
            />
            <TextField
              className={classes.textField}
              value={values.newPassword}
              onChange={handleTextFieldChange}
              label={t<string>('changePassword.confirmNewPassword')}
              name="newPassword"
              required
              disabled={submitting}
              type="password"
            />
            <TextField
              className={classes.textField}
              value={values.confirmNewPassword}
              onChange={handleTextFieldChange}
              label={t<string>('changePassword.confirmNewPassword')}
              name="confirmNewPassword"
              required
              disabled={submitting}
              type="password"
            />
            {errors.map((e, i) => {
              if (e.code === 'too_small')
                return (
                  <Alert key={i} severity="error">
                    {t<string>('changePassword.passwordInfo')}
                  </Alert>
                );
              if (e.code === 'custom')
                return (
                  <Alert key={i} severity="error">
                    {t<string>('formValidation.passwordsNotMatch')}
                  </Alert>
                );
            })}
            {passwordChanged && <Alert severity="success">{t<string>('changePassword.passwordChanged')}</Alert>}
            {!!error && (
              <Box marginTop="1rem">
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Button
              fullWidth
              type="submit"
              className={classes.submitButton}
              disabled={submitting}
              endIcon={submitting && <CircularProgress size={20} className={classes.buttonProgress} />}
            >
              {t<string>('forgotPassword.sendResetLink')}
            </Button>
          </form>
        </Paper>
      </Container>
    </MainTemplate>
  );
};
