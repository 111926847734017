import React, { useState, MouseEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Container,
  Button,
  Paper,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import * as zod from 'zod';
import { urls, endpoints, ONLY_CHARACTERS_REGEX, PHONE_NUMBER_REGEX, ZIP_CODE_REGEX } from '../utils';
import { Header, MainTemplate } from '../components';
import { useLocaleInfo } from '../hooks';
import { ProfileData } from '../types';
import { Api } from '../api';
import { useAuthContext } from '../context';
import axios from 'axios';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  paper: {
    padding: `${spacing(3)} ${spacing(2)}`,
    marginBottom: spacing(4),
    [breakpoints.up('sm')]: {
      padding: spacing(4),
    },
  },
  saveButton: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'stretch',
    marginTop: spacing(3),
    [breakpoints.up('sm')]: {
      marginTop: spacing(4),
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
    '& button + button': {
      [breakpoints.down('xs')]: {
        marginTop: spacing(2),
      },
    },
  },
}));

export const PersonalInformation: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const accessDataString = localStorage.getItem('epic_user');
  const accessData = JSON.parse(String(accessDataString));
  const [formErrors, setFormErrors] = useState<zod.ZodIssue[]>([]);
  const [profileUpdateError, setProfileUpdateError] = useState();
  const { languages } = useLocaleInfo();
  const { profileData } = useAuthContext();
  const [formValues, setFormValues] = useState<ProfileData>(profileData);

  useEffect(() => {
    setFormValues(profileData);
  }, [profileData]);

  const putProfileUpdate = async (data: Record<string, unknown>, accessToken: string): Promise<void> => {
    try {
      return await Api.post(endpoints.getStudentProfile, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setProfileUpdateError(e.response?.data?.message);
      }
    }
  };

  const hasError = (key: string): boolean => formErrors.some((e: zod.ZodIssue) => e.path[0] === key);

  const renderFormErrorHelperText = (key: string, translationID: string): JSX.Element | void => {
    if (hasError(key)) {
      return <FormHelperText>{t<string>(translationID)}</FormHelperText>;
    }
  };

  const handlePrev = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    history.push(urls.profile);
  };

  const profileUpdateValidation = zod.object({
    first_name: zod.string().min(1),
    last_name: zod.string().min(1),
    street: zod.string().min(1),
    city: zod.string().min(1).regex(ONLY_CHARACTERS_REGEX),
    zip: zod.string().min(1).regex(ZIP_CODE_REGEX),
    home_city: zod.string().min(1).regex(ONLY_CHARACTERS_REGEX),
    phone: zod.string().min(1).regex(PHONE_NUMBER_REGEX),
    language: zod.string().min(1),
    transmission_type_preference: zod.string(),
  });

  const handleSave = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    const { first_name, last_name, street, zip, city, home_city, phone, language, transmission_type_preference } =
      formValues;

    const formData = {
      first_name,
      last_name,
      street,
      zip,
      city,
      home_city,
      phone,
      language,
      transmission_type_preference,
    };

    try {
      setFormErrors([]);
      profileUpdateValidation.parse(formData);
      await putProfileUpdate(formData, accessData.token);
      history.push(urls.profile);
    } catch (e) {
      if (e instanceof zod.ZodError) {
        setFormErrors(e.errors);
      }
    }
  };

  return (
    <MainTemplate>
      <Container>
        <Header handlePrev={handlePrev} titleText={t<string>('profile.personalInformation')} hidePrev={false} />
        <Paper className={classes.paper}>
          <form data-testid="personal-information-form">
            <FormControl>
              <TextField value={formValues.nin} label={t<string>('userData.nin')} disabled />
            </FormControl>
            <FormControl error={hasError('lastName')}>
              <>
                <TextField
                  data-testid="lastName-wrapper"
                  label={t<string>('userData.lastName')}
                  name="last_name"
                  value={formValues.last_name.trim()}
                  required
                  InputLabelProps={{ shrink: true }}
                  onChange={(e): void => setFormValues({ ...formValues, last_name: e.target.value })}
                  error={hasError('last_name')}
                  inputProps={{
                    'data-testid': 'lastName',
                  }}
                />
                {renderFormErrorHelperText('lastName', 'formValidation.lastNameInvalid')}
              </>
            </FormControl>
            {/* TODO: Fix first names after Student name changes have been made */}
            <FormControl error={hasError('firstName')}>
              <>
                <TextField
                  data-testid="firstName-wrapper"
                  label={t<string>('userData.firstName')}
                  name="first_name"
                  value={formValues.first_name.trim()}
                  required
                  InputLabelProps={{ shrink: true }}
                  onChange={(e): void => setFormValues({ ...formValues, first_name: e.target.value })}
                  error={hasError('first_name')}
                  inputProps={{
                    'data-testid': 'firstName',
                  }}
                />
                {renderFormErrorHelperText('firstName', 'formValidation.firstNameInvalid')}
              </>
            </FormControl>
            <FormControl>
              <TextField value={formValues.email} label={t<string>('userData.email')} disabled />
            </FormControl>
            <FormControl error={hasError('street')}>
              <>
                <TextField
                  data-testid="street-wrapper"
                  label={t<string>('userData.street').trim()}
                  name="street"
                  value={formValues.street}
                  required
                  InputLabelProps={{ shrink: true }}
                  onChange={(e): void => setFormValues({ ...formValues, street: e.target.value })}
                  error={hasError('street')}
                  inputProps={{
                    'data-testid': 'street',
                  }}
                />
                {renderFormErrorHelperText('street', 'formValidation.addressRequired')}
              </>
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl error={hasError('zip')}>
                  <>
                    <TextField
                      data-testid="zip-wrapper"
                      label={t<string>('userData.zip')}
                      name="zip"
                      value={formValues.zip}
                      required
                      InputLabelProps={{ shrink: true }}
                      onChange={(e): void => setFormValues({ ...formValues, zip: e.target.value })}
                      error={hasError('zip')}
                      inputProps={{
                        'data-testid': 'zip',
                      }}
                    />
                    {renderFormErrorHelperText('zip', 'formValidation.zipInvalid')}
                  </>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl error={hasError('city')}>
                  <>
                    <TextField
                      data-testid="city-wrapper"
                      label={t<string>('userData.city')}
                      name="city"
                      value={formValues.city.trim()}
                      required
                      InputLabelProps={{ shrink: true }}
                      onChange={(e): void => setFormValues({ ...formValues, city: e.target.value })}
                      error={hasError('city')}
                      inputProps={{
                        'data-testid': 'city',
                      }}
                    />
                    {renderFormErrorHelperText('city', 'formValidation.cityRequired')}
                  </>
                </FormControl>
              </Grid>
            </Grid>
            <FormControl error={hasError('homeCity')}>
              <>
                <TextField
                  data-testid="homeCity-wrapper"
                  label={t<string>('userData.homeCity')}
                  name="home_city"
                  value={formValues.home_city.trim()}
                  required
                  InputLabelProps={{ shrink: true }}
                  onChange={(e): void => setFormValues({ ...formValues, home_city: e.target.value })}
                  error={hasError('home_city')}
                  inputProps={{
                    'data-testid': 'homeCity',
                  }}
                />
                {renderFormErrorHelperText('homeCity', 'formValidation.homeCityRequired')}
              </>
            </FormControl>
            <FormControl error={hasError('phone')}>
              <>
                <TextField
                  data-testid="phone-wrapper"
                  label={t<string>('userData.phone')}
                  name="phone"
                  InputLabelProps={{ shrink: true }}
                  value={formValues.phone}
                  required
                  onChange={(e): void => setFormValues({ ...formValues, phone: e.target.value })}
                  error={hasError('phone')}
                  inputProps={{
                    'data-testid': 'phone',
                  }}
                />
                {renderFormErrorHelperText('phone', 'formValidation.phoneInvalid')}
              </>
            </FormControl>
            <FormControl required variant="outlined" error={hasError('nativeLanguage')}>
              <>
                <InputLabel shrink id="labelNativeLanguage">
                  {t<string>('userData.nativeLanguage')}
                </InputLabel>
                <Select
                  data-testid="nativeLanguage-wrapper"
                  label={t<string>('userData.nativeLanguage')}
                  labelId="labelNativeLanguage"
                  required
                  value={formValues.language}
                  name="language"
                  onChange={(e): void => setFormValues({ ...formValues, language: e.target.value as string })}
                  error={hasError('language')}
                  inputProps={{
                    'data-testid': 'nativeLanguage',
                  }}
                  key={languages.length} // Force update on default value when languages are fetched.
                >
                  <option value="">---</option>
                  {languages.map((l) => (
                    <option value={l.code} key={l.code}>
                      {l.name}
                    </option>
                  ))}
                </Select>
                {renderFormErrorHelperText('nativeLanguage', 'formValidation.nativeLanguageRequired')}
              </>
            </FormControl>
            <FormControl>
              <InputLabel shrink>{t<string>('userData.birthCountry')}</InputLabel>
              <Select value={formValues.country[0][1]} disabled>
                <option value={formValues.country[0][1]}>{formValues.country[0][1]}</option>
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <FormLabel>{t<string>('userData.transmissionTypePreference')}</FormLabel>
              <RadioGroup
                value={formValues.transmission_type_preference}
                aria-label={t<string>('userData.transmissionTypePreference')}
                name="transmission_type_preference"
                onChange={(e): void =>
                  setFormValues({ ...formValues, transmission_type_preference: e.target.value as string })
                }
              >
                <FormControlLabel
                  checked={formValues.transmission_type_preference === 'manual'}
                  key="manual"
                  value="manual"
                  label={t<string>('userData.manualTransmissionType')}
                  control={<Radio />}
                />
                <FormControlLabel
                  checked={formValues.transmission_type_preference === 'automatic'}
                  key="automatic"
                  value="automatic"
                  label={t<string>('userData.automaticTransmissionType')}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
            {profileUpdateError}
            <div className={classes.saveButton}>
              <Button data-testid="profileSave" id="profileSave" onClick={handleSave} variant="contained">
                {t<string>('profile.save')}
              </Button>
            </div>
          </form>
        </Paper>
      </Container>
    </MainTemplate>
  );
};
