import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import {
  Category,
  Enrollment,
  Lesson,
  Section,
  Slide,
  Survey,
  SurveyQuestion,
  SurveyQuestionAnswer,
  OpenHours,
  SurveyResults,
  ExamState,
} from '../types';
import { useAuthContext } from './auth';

interface StudyPathContextType {
  sections: Section[];
  setSections: Dispatch<SetStateAction<Section[]>>;
  reservations: Lesson[];
  setReservations: Dispatch<SetStateAction<Lesson[]>>;
  categories: Category[];
  setCategories: Dispatch<SetStateAction<Category[]>>;
  selectedCategory: Category | null;
  setSelectedCategory: Dispatch<SetStateAction<Category | null>>;
  slideIndex: number;
  setSlideIndex: Dispatch<SetStateAction<number>>;
  examIndex: number;
  setExamIndex: Dispatch<SetStateAction<number>>;
  reviewDialogOpen: boolean;
  setReviewDialogOpen: Dispatch<SetStateAction<boolean>>;
  survey: Survey | null;
  setSurvey: Dispatch<SetStateAction<Survey | null>>;
  questions: SurveyQuestion[];
  setQuestions: Dispatch<SetStateAction<SurveyQuestion[]>>;
  surveyAnswers: SurveyResults | null;
  setSurveyAnswers: Dispatch<SetStateAction<SurveyResults | null>>;
  questionIndex: number;
  setQuestionIndex: Dispatch<SetStateAction<number>>;
  answers: SurveyQuestionAnswer[];
  setAnswers: Dispatch<SetStateAction<SurveyQuestionAnswer[]>>;
  surveyId: number | null;
  setSurveyId: Dispatch<SetStateAction<number | null>>;
  selectedCourse: number | null;
  setSelectedCourse: Dispatch<SetStateAction<number | null>>;
  enrollments: Enrollment[];
  setEnrollments: Dispatch<SetStateAction<Enrollment[]>>;
  categoryLocked: boolean;
  progress: number;
  handleSlidesPrev: () => void;
  slides: Slide[];
  surveys: number[];
  setSurveys: Dispatch<SetStateAction<number[]>>;
  questionsList: SurveyQuestion[][];
  setQuestionsList: Dispatch<SetStateAction<SurveyQuestion[][]>>;
  setSlides: Dispatch<SetStateAction<Slide[]>>;
  prevCategory: Category | null;
  handleOpenPrevCategory: () => void;
  handleCloseReviewDialog: () => void;
  handleCategoryClick: (category: Category) => void;
  isOpenNow: boolean;
  setIsOpenNow: Dispatch<SetStateAction<boolean>>;
  openHours: OpenHours[];
  setOpenHours: Dispatch<SetStateAction<OpenHours[]>>;
  studyPathError: boolean;
  setStudyPathError: Dispatch<SetStateAction<boolean>>;
  liveChatLoaderURL: string;
  setLiveChatLoaderURL: Dispatch<SetStateAction<string>>;
  liveChatWidget: string;
  setLiveChatWidget: Dispatch<SetStateAction<string>>;
  studyPathPhoneNumber: string;
  setStudyPathPhoneNumber: Dispatch<SetStateAction<string>>;
  openInvoicePaid: boolean;
  setOpenInvoicePaid: Dispatch<SetStateAction<boolean>>;
}

const initialState = {
  surveys: [],
  setSurveys: (): void => {},
  questionsList: [],
  setQuestionsList: (): void => {},
  sections: [],
  setSections: (): void => {},
  reservations: [],
  setReservations: (): void => {},
  categories: [],
  setCategories: (): void => {},
  selectedCategory: null,
  setSelectedCategory: (): void => {},
  slideIndex: 0,
  setSlideIndex: (): void => {},
  examIndex: 0,
  setExamIndex: (): void => {},
  reviewDialogOpen: false,
  setReviewDialogOpen: (): void => {},
  survey: null,
  setSurvey: (): void => {},
  questions: [],
  setQuestions: (): void => {},
  surveyAnswers: null,
  setSurveyAnswers: (): void => {},
  questionIndex: 0,
  setQuestionIndex: (): void => {},
  answers: [],
  setAnswers: (): void => {},
  surveyId: 0,
  setSurveyId: (): void => {},
  selectedCourse: null,
  setSelectedCourse: (): void => {},
  enrollments: [],
  setEnrollments: (): void => {},
  categoryLocked: false,
  progress: 0,
  handleCloseReviewDialog: (): void => {},
  handleCategoryClick: (): void => {},
  handleOpenPrevCategory: (): false | void => {},
  handleSlidesPrev: (): void => {},
  slides: [],
  setSlides: (): void => {},
  prevCategory: null,
  isOpenNow: false,
  openHours: [],
  setIsOpenNow: (): void => {},
  setOpenHours: (): void => {},
  studyPathError: false,
  setStudyPathError: (): false | void => {},
  liveChatLoaderURL: '',
  setLiveChatLoaderURL: (): string | void => {},
  liveChatWidget: '',
  setLiveChatWidget: (): string | void => {},
  studyPathPhoneNumber: '',
  setStudyPathPhoneNumber: (): string | void => {},
  openInvoicePaid: false,
  setOpenInvoicePaid: (): false | void => {},
};

const StudyPathContext = createContext<StudyPathContextType>(initialState);
export const useStudyPathContext = (): StudyPathContextType => useContext(StudyPathContext);

interface Props {
  children?: React.ReactNode;
}
export const StudyPathContextProvider: React.FC<Props> = ({ children }) => {
  const { token, getProfileData, profileData, logActivity } = useAuthContext();
  const [sections, setSections] = useState<Section[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [reservations, setReservations] = useState<Lesson[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [examIndex, setExamIndex] = useState(0);
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [surveyId, setSurveyId] = useState<number | null>(null);
  const [surveys, setSurveys] = useState<number[]>([]);
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyResults | null>(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<SurveyQuestionAnswer[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
  const [enrollments, setEnrollments] = useState<Enrollment[]>([]);
  const [isOpenNow, setIsOpenNow] = useState<boolean>(false);
  const [openHours, setOpenHours] = useState<OpenHours[]>([]);
  const [studyPathError, setStudyPathError] = useState<boolean>(false);
  const [liveChatLoaderURL, setLiveChatLoaderURL] = useState<string>('');
  const [liveChatWidget, setLiveChatWidget] = useState<string>('');
  const [studyPathPhoneNumber, setStudyPathPhoneNumber] = useState<string>('');
  const [openInvoicePaid, setOpenInvoicePaid] = useState<boolean>(false);

  const prevCategory = (!!selectedCategory && categories[categories.indexOf(selectedCategory) - 1]) || null;
  const categoryLocked = !!selectedCategory && !!prevCategory && !selectedCategory.completed && !prevCategory.completed;
  const progress = Number(((categories.filter((p) => !!p.completed).length / categories.length) * 100).toFixed()) || 0;
  const handleCloseReviewDialog = useCallback((): void => setReviewDialogOpen(false), [setReviewDialogOpen]);
  // Lock the user out if it is not open times.
  const handleCategoryClick = useCallback(
    async (category: Category) => {
      if (!category.completed) {
        getProfileData(token);

        //check if website is closed
        const date = new Date()
          .toLocaleTimeString('fi-FI', {
            hour: '2-digit',
            minute: '2-digit',
          })
          .replace('.', ':');

        // temp fix have to look into this
        if (openHours) {
          if (openHours.length) {
            if (openHours[0]) {
              if (date >= openHours[0].open_from && date <= openHours[0].open_to) {
                setIsOpenNow(true);
                if (isOpenNow && studyPathPhoneNumber && profileData.open_invoices_paid) {
                  logActivity('OPEN_CATEGORY', category.name);
                  setSelectedCategory(category);
                  localStorage.setItem('examState', ExamState.Started);
                  localStorage.setItem('examIndex', 'started');
                } else if (!(isOpenNow && studyPathPhoneNumber)) {
                  setStudyPathError(true);
                } else {
                  setOpenInvoicePaid(!profileData.open_invoices_paid);
                }
              } else {
                setStudyPathError(true);
              }
            } else {
              setStudyPathError(true);
            }
          } else {
            setStudyPathError(true);
          }
        } else {
          setStudyPathError(true);
        }
      }
    },
    [
      openHours,
      setSelectedCategory,
      isOpenNow,
      studyPathPhoneNumber,
      setStudyPathError,
      setOpenInvoicePaid,
      token,
      getProfileData,
      profileData,
      logActivity,
    ],
  );

  const handleOpenPrevCategory = useCallback(
    (): false | void => !!prevCategory && setSelectedCategory(prevCategory),
    [prevCategory, setSelectedCategory],
  );

  // This one assigns the Slides to the study path context which is used globally
  const [slides, setSlides] = useState<Slide[]>([]);
  const [questionsList, setQuestionsList] = useState<SurveyQuestion[][]>([]);

  // Move to the previous slide or question or close the category.
  const handleSlidesPrev = useCallback((): void => {
    if (slides[slideIndex]?.content_type === 'certification' && questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
      logActivity('STUDY_PATH', 'PREV_SLIDE');
    } else if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
      logActivity('STUDY_PATH', 'PREV_SLIDE');
    } else {
      setSlideIndex(0);
      setSelectedCategory(null);
      logActivity('STUDY_PATH', 'PREV_SLIDE (EXIT EXAM)');
      localStorage.setItem('examState', ExamState.NotStarted);
      localStorage.setItem('endTime', '0');
      localStorage.setItem('examID', '');
    }
  }, [questionIndex, setQuestionIndex, setSelectedCategory, setSlideIndex, slideIndex, slides, logActivity]);

  const values = {
    sections,
    setSections,
    reservations,
    setReservations,
    categories,
    setCategories,
    selectedCategory,
    setSelectedCategory,
    slideIndex,
    setSlideIndex,
    examIndex,
    setExamIndex,
    reviewDialogOpen,
    setReviewDialogOpen,
    survey,
    setSurvey,
    surveys,
    setSurveys,
    questions,
    setQuestions,
    surveyAnswers,
    setSurveyAnswers,
    questionIndex,
    setQuestionIndex,
    answers,
    setAnswers,
    surveyId,
    setSurveyId,
    selectedCourse,
    setSelectedCourse,
    enrollments,
    setEnrollments,
    categoryLocked,
    progress,
    handleCloseReviewDialog,
    handleCategoryClick,
    handleOpenPrevCategory,
    handleSlidesPrev,
    slides,
    questionsList,
    setQuestionsList,
    setSlides,
    prevCategory,
    isOpenNow,
    setIsOpenNow,
    openHours,
    setOpenHours,
    studyPathError,
    setStudyPathError,
    liveChatLoaderURL,
    setLiveChatLoaderURL,
    liveChatWidget,
    setLiveChatWidget,
    studyPathPhoneNumber,
    setStudyPathPhoneNumber,
    openInvoicePaid,
    setOpenInvoicePaid,
  };

  return <StudyPathContext.Provider value={values}>{children}</StudyPathContext.Provider>;
};
