import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningRounded';

const useStyles = makeStyles(({ spacing, breakpoints, palette, typography }) => ({
  errorWrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: `${spacing(3)} ${spacing(2)}`,
    margin: `${spacing(2)} 0`,
    [breakpoints.up('sm')]: {
      padding: spacing(4),
    },
  },
  icon: {
    fontSize: '6rem',
    color: palette.error.main,
  },
  errorText: {
    fontSize: typography.body1.fontSize,
  },
}));

interface Props {
  children: ReactNode;
}

export const Error: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.errorWrapper}>
      <WarningIcon className={classes.icon} aria-hidden="true" />
      <h2>{`${t<string>('misc.somethingWrong')} :(`}</h2>
      <div className={classes.errorText}>{children}</div>
    </Paper>
  );
};
