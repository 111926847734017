import { useEffect, useState } from 'react';
import { Api } from '../api';
import { endpoints } from '../utils';
import { useStickyState } from './useStickyState';

interface Country {
  external_id: string;
  name: string;
}

interface Language {
  code: string;
  name: string;
}

interface UseLocaleInfo {
  countries: Country[];
  languages: Language[];
  error: boolean;
}

// A custom hook that fetches the available languages and countries.
export const useLocaleInfo = (): UseLocaleInfo => {
  const [countries, setCountries] = useStickyState<Country[]>([], 'countries');
  const [languages, setLanguages] = useStickyState<Language[]>([], 'languages');
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const [{ data: countryData }, { data: languageData }] = await Promise.all([
          Api.get<Country[]>(endpoints.getCountries),
          Api.get<Language[]>(endpoints.getLanguages),
        ]);

        const finland = countryData.find((c) => c.external_id === 'base.fi');

        // Move Finland to first item for better UX.
        if (finland) {
          const filteredCountryData = countryData.filter((c) => c.external_id !== 'base.fi');
          filteredCountryData.unshift(finland);
          setCountries(filteredCountryData);
        }

        const finnish = languageData.find((l) => l.code === 'fi_FI');

        // Move Finnish to first item for better UX.
        if (finnish) {
          const filteredLanguageData = languageData.filter((l) => l.code !== 'fi_FI');
          filteredLanguageData.unshift(finnish);
          setLanguages(filteredLanguageData);
        }
      } catch {
        setError(true);
      }
    })();
  }, [setCountries, setLanguages, setError]);

  return {
    countries,
    languages,
    error,
  };
};
