// https://stackoverflow.com/a/29767609/10504286
export const PHONE_NUMBER_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const ZIP_CODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const ONLY_CHARACTERS_REGEX = /^[a-zA-ZäöüÄÖÜß-\s]+$/;

export const endpoints = {
  getRegistrationToken: '/authentication/oauth2/token',
  getAccessToken: '/authentication/oauth2/token',
  getStudentProfile: '/student-profile',
  forgotPassword: '/student-password/reset/',
  studentPassword: '/student-password',
  getCountries: '/countries',
  getLanguages: '/languages',
  getLicences: '/licenses',
  getStudyMaterials: '/study-materials',
  getKlarnaOrder: (klarnaOrderId: string): string => `/payments/klarna/${klarnaOrderId}/read/`,
  register: '/students/registration',
  registrationValidation: '/students/registration/validation',
  invoices: '/invoices',
  slides: (id: number): string => `/courses/${id}/slides`,
  enrollments: '/enrolled-courses',
  getLessons: '/lessons',
  getReservations: '/reservations',
  getLessonSlides: '/lessons_slides',
  reserveLesson: (id: string): string => `/lessons/${id}/reserve`,
  cancelReservation: (id: string): string => `/lessons/${id}/cancel-reservation`,
  examInfo: (surveyId: number): string => `/exam/${surveyId}`,
  examSections: (surveyId: number): string => `/exam/${surveyId}/sections`,
  examUserAnswers: (surveyId: number): string => `/exam/${surveyId}/answers`,
  examCorrectAnswers: (surveyId: number): string => `/exam/${surveyId}/correct-answers`,
  examQuestions: (surveyId: number, sectionId: number): string => `/exam/${surveyId}/section/${sectionId}`,
  submitExamAnswers: (surveyId: number): string => `/exam/${surveyId}/answer`,
  enroll: (courseId: number): string => `/courses/${courseId}/enroll`,
  course: (id: number): string => `/courses/${id}`,
  checkIsOpenRightNow: '/studies/open-hours/check-open',
  getOpenHours: '/studies/open_hours/',
  rateLesson: '/lessons/rate',
  completeSlide: (id: string): string => `/slides/${id}/complete`,
  liveChat: '/live-chat',
  studyPathPhoneNumber: '/studies/phone-number/',
  informLogout: '/studies/inform-logout',
  availableRecurrences: (courseId: number): string => `/available-recurrences/course/${courseId}`,
  recurrences: (productId: number, quantity: number): string =>
    `/available-recurrences/product/${productId}/${quantity}`,
  productOrder: (productId: number, quantity: number): string => `/product-order/${productId}/${quantity}`,
  extraDrivingLessonProduct: '/extra-services',
  drivingLessonInfo: '/student/driving-lesson-info',
  setMainCourse: (courseId: number): string => `/student/set-main-course/${courseId}`,
  refreshToken: '/refresh-token',
  checkLateCancellation: (lessonId: number): string => `/lesson/check-late-cancellation/${lessonId}`,
  log: '/student/activity-log',
  embedded: (slideId: number): string => `/slides/embedded/${slideId}`,
};

export const urls = {
  profile: '/profile',
  login: '/login',
  registration: '/register',
  courses: '/courses',
  updateProfile: '/personal-information',
  invoices: '/invoices',
  settings: '/settings',
  myCourses: '/my-courses',
  support: '/support',
  materials: '/materials',
  changePassword: '/change-password',
  forgotPassword: '/forgot-password',
  course: (id: string): string => `/course/${id}`,
  klarnaConfirm: (klarnaOrderId: string): string => `/checkout/confirm/${klarnaOrderId}`, // TODO: Check if we want Klarna Confirmation in addition?
  home: '/',
  calendar: '/calendar',
  practiceExams: '/practice-exams',
  checkout: '/checkout',
  liveChat: '/chat-support',
};

export const tags = {
  is_eas_lesson: 'eas',
  is_night_driving_lesson: 'night',
  is_rtk_lesson: 'rtk',
  is_simulator: 'simulator',
  is_normal: 'normal',
  is_slippery_driving_lesson: 'slippery',
};
