import React, { useState, useEffect, useRef, useCallback, useMemo, ChangeEvent } from 'react';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import {
  makeStyles,
  Container,
  IconButton,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Card,
  CardContent,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
  Tab,
} from '@material-ui/core';
import Calendar, { CalendarTileProperties, ViewCallbackProperties } from 'react-calendar';
import { TabList, TabPanel, TabContext, AlertTitle, Alert } from '@material-ui/lab';
import dayjs from 'dayjs';
import { Api } from '../api';
import { useAuthContext } from '../context';
import { endpoints } from '../utils';
import { Header, MainTemplate, SimpleDialog, ConfirmationDialog } from '../components';
import 'react-calendar/dist/Calendar.css';
import { Lesson, DrivingLessonInfo, Instructor, Slide } from '../types';
import { LessonBox } from '../components/calendar';
import { getSections } from '../components/studentpath/Sections';
import axios from 'axios';

const useStyles = makeStyles(({ palette, spacing }) => ({
  calendar: {
    width: '100%',
  },
  topListItem: {
    borderTop: `0.05rem solid ${palette.grey[400]}`,
    marginTop: spacing(4),
  },
  hasLesson: {
    background: '#FFFFFF',
    color: '#000',
  },
  lessons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  hasNoLesson: {
    background: '#DDD',
  },
  isEnrolled: {
    background: '#73a9ff',
  },
  cancelledLesson: {
    background: '#f45c61',
  },
  completedLesson: {
    background: '#4287f5',
  },
  drivingHours: {
    display: 'flex',
    alignContent: 'center',
    marginBottom: '10px',
    marginTop: '10px',
  },
}));

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const CalendarComponent: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [calendarValue, setCalendarValue] = useState(new Date());
  const reservationDate = dayjs(calendarValue).format('LL');
  const [reservations, setReservations] = useState<Lesson[]>([]);
  const [userReservations, setUserReservations] = useState<Lesson[]>([]);
  const [lessonSlides, setLessonSlides] = useState<Slide[]>([]);
  const [selectedLessonSlideIndex, setSelectedLessonSlideIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);
  const [currentLesson, setCurrentLesson] = useState<Lesson>();
  const [cancelReservationText, setCancelReservationText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handleCloseDialog = (): void => setDialogOpen(false);
  const [currentMonth, setCurrentMonth] = useState(0);
  const prevValue = usePrevious(currentMonth);
  const {
    token,
    logout,
    logActivity,
    profileData: { current_course, transmission_type_preference, course },
  } = useAuthContext();

  const [drivingLessonInfo, setDrivingLessonInfo] = useState<DrivingLessonInfo | null>(null);

  const [instructor, setInstructor] = useState<string>('');
  const [vehicle, setVehicle] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [lessonType, setLessonType] = useState<string>('');

  const [preferedInstructor, setPreferredInstructor] = useState(0);
  const prevInstructor = usePrevious(preferedInstructor);
  const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [instructorsSet, setInstructorsSet] = useState<Set<string>>(() => new Set());
  const [isExtraDrivingLesson, setIsExtraDrivingLesson] = useState<boolean>(false);
  const [isExtraExam, setIsExtraExam] = useState<boolean>(false);

  const [leftExtraDrivingLessonHours, setLeftExtraDrivingLessonHours] = useState<number>(0);
  const [isValidToReserve, setIsValidToReserve] = useState<boolean>(false);
  const [drivesAutomatic, setDrivesAutomatic] = useState<boolean>(false);

  // set current node
  useEffect(() => {
    (async (): Promise<void> => {
      const data = await getSections(current_course, token);
      if (data) {
        const categories = data.find((s) => s.section_type === 'supervised')?.categories || [];
        // get slides from current node
        const currentNode = categories.find((c) => c.completed === false);
        const currentNodeIndex = categories.findIndex((item) => item.name === currentNode?.name);
        const previousNode = categories.find((c, i) => i === currentNodeIndex - 1);
        const { slides } = categories.find((c) => c.completed === false) ?? {};

        if (slides) {
          // check if current node is open
          const isCurrentNodeOpen = previousNode?.completed ?? false;
          // check if slides has reservable content-types
          const hasReservableContent = slides
            .map((s) => s.content_type)
            .some((item) => ['driving_lesson', 'online_lesson', 'driving_exam'].includes(item));
          setIsValidToReserve((!previousNode || isCurrentNodeOpen) && hasReservableContent);
        }
      }
    })();
  }, [setIsValidToReserve, current_course, token]);

  useEffect(() => {
    (async (): Promise<void> => {
      // completed lessons
      if (token.length) {
        const { data } = await Api.get<Slide>(endpoints.getLessonSlides, {
          params: {},
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (Array.isArray(data)) {
          setLessonSlides(data);
        }
      }
    })();
  }, [token]);

  useEffect(() => {
    setDrivesAutomatic(
      drivingLessonInfo?.student_current_lesson_type === 'driving_lesson' &&
        !drivingLessonInfo?.student_current_simulator &&
        transmission_type_preference === 'automatic',
    );
  }, [
    drivingLessonInfo?.student_current_lesson_type,
    drivingLessonInfo?.student_current_simulator,
    transmission_type_preference,
  ]);

  const getUserReservations = useCallback(
    async (token: string): Promise<void> => {
      const { data } = await Api.get(endpoints.getReservations, {
        params: {},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (Array.isArray(data)) {
        const sortedData = data.sort((curr: { start_date: string }, prev: { start_date: string }) =>
          curr.start_date.localeCompare(prev.start_date),
        );
        setUserReservations(sortedData);
      }
    },
    [setUserReservations],
  );

  useEffect(() => {
    setPreferredInstructor(Number(localStorage.getItem('preferedInstructor')));
  }, []);

  useEffect(() => {
    localStorage.setItem('preferedInstructor', String(preferedInstructor));
  }, [preferedInstructor]);

  useEffect(() => {
    getUserReservations(token);
  }, [token, logout, getUserReservations]);

  useEffect(() => {
    const i = [...instructorsSet];
    setInstructors(
      i.map((s) => {
        return JSON.parse(s);
      }),
    );
  }, [instructorsSet]);

  const reservationText = useCallback((): string => {
    if (lessonType === 'online_lesson') {
      return t<string>('calendar.dialogOnlineLessonReserved', { reservationDate });
    }
    if (vehicle) {
      return t<string>('calendar.dialogText', { reservationDate, company, instructor, vehicle });
    }
    return t<string>('calendar.dialogTextExtra', { reservationDate, company, instructor });
  }, [lessonType, vehicle, reservationDate, company, instructor, t]);

  // get open lesson
  useEffect(() => {
    (async (): Promise<void> => {
      if (lessonSlides.length > 0 || isExtraExam || isExtraDrivingLesson) {
        // open lessons
        let params = {
          states: 'open',
          reservable: true,
          date_from: `${calendarValue.getFullYear()}-${calendarValue.getMonth() + 1}-1 00:00:00`,
        };
        if (isExtraDrivingLesson) {
          params = Object.assign(params, { extra_lesson: true });
        } else if (isExtraExam) {
          params = Object.assign(params, { extra_exam: true });
        }
        params = Object.assign(params, { slide_id: lessonSlides[selectedLessonSlideIndex]?.id });

        let allOpenLessons = [];
        try {
          const response = (
            await Api.get(endpoints.getLessons, {
              params,
              paramsSerializer: (params) => {
                return qs.stringify(params);
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          ).data;
          if (Array.isArray(response)) {
            allOpenLessons = response;
          }

          setInstructorsSet(
            new Set<string>(
              allOpenLessons.map((o: Lesson) => {
                return JSON.stringify(o.instructor);
              }),
            ),
          );
        } catch {
          console.log('Error getting open lessons');
        }
        // cancelled lessons
        let cancelledLessons = [];
        try {
          const response = (
            await Api.get(endpoints.getLessons, {
              params: {
                states: 'cancelled',
                date_from: `${calendarValue.getFullYear()}-${calendarValue.getMonth() + 1}-1 00:00:00`,
              },
              paramsSerializer: (params) => {
                return qs.stringify(params);
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          ).data;
          if (Array.isArray(response)) {
            cancelledLessons = response;
          }
        } catch {
          console.log('Error getting cancelled lesson');
        }
        const combinedLessons = [...allOpenLessons, ...cancelledLessons];

        let sortedData = combinedLessons.sort((curr: { start_date: string }, prev: { start_date: string }) =>
          curr.start_date.localeCompare(prev.start_date),
        );

        if (preferedInstructor) {
          const filteredData = sortedData.filter((s) => {
            if (s.instructor.id === preferedInstructor) return s;
          });
          if (filteredData.length > 0) sortedData = filteredData;
        }

        setReservations(sortedData);
      }
    })();
  }, [
    token,
    logout,
    calendarValue,
    currentMonth,
    prevValue,
    prevInstructor,
    preferedInstructor,
    isExtraDrivingLesson,
    leftExtraDrivingLessonHours,
    isExtraExam,
    selectedLessonSlideIndex,
    lessonSlides,
    lessonSlides.length,
  ]);

  const updateEnrollmentState = useCallback(
    (lessonId: string): void => {
      setReservations(
        reservations.map((r) => {
          if (r.id === lessonId) {
            r.is_enrolled = !r.is_enrolled;
          }
          return r;
        }),
      );
    },
    [setReservations, reservations],
  );

  const closeErrorDialog = (): void => {
    setErrorDialogOpen(false);
  };

  const closeConfirmReserveDialog = (): void => {
    setConfirmCancelDialogOpen(false);
  };

  const changeDialogText = (cancelled: boolean): string => {
    if (cancelled) {
      return 'dialogCancelLesson';
    }
    return 'dialogText';
  };

  const handleCancelReservation = useCallback(
    (lesson: Lesson) => async (): Promise<void> => {
      try {
        const {
          data: { is_late_cancelation },
        } = await Api.get(endpoints.checkLateCancellation(Number(lesson.id)), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCurrentLesson(lesson);
        setCancelReservationText(t<string>('lessons.cancelMessage'));
        if (is_late_cancelation) {
          setCancelReservationText(t<string>('lessons.cancelCharge'));
        }
        setConfirmCancelDialogOpen(true);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setErrorDialogOpen(true);
          setErrorMessage(e.response?.data.message);
          setErrorDialogOpen(true);
        }
      }
    },
    [token, setConfirmCancelDialogOpen, setCurrentLesson, t],
  );

  const reserveLesson = useCallback(
    (lesson: Lesson) => async (): Promise<void> => {
      if (drivesAutomatic) {
        setErrorDialogOpen(true);
        setErrorMessage(course?.contact_info || '');
        setErrorDialogOpen(true);
        return;
      }
      try {
        let params = {};
        if (isExtraDrivingLesson) {
          params = Object.assign(params, { extra_lesson: true });
        } else if (isExtraExam) {
          params = Object.assign(params, { extra_exam: true });
        } else {
          params = Object.assign(params, { slide_id: lessonSlides[selectedLessonSlideIndex]?.id });
        }
        if (isValidToReserve || isExtraDrivingLesson || isExtraExam) {
          const {
            data: { error },
          } = await Api.post(endpoints.reserveLesson(lesson.id), params, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (error) {
            logActivity('RESERVE_LESSON_ERROR', `${lesson.name} [${lesson.type}]`);
            setErrorMessage(t<string>('calendar.errorReserving'));
            setErrorDialogOpen(true);
          } else {
            getUserReservations(token);
            changeDialogText(true);
            setDialogOpen(true);
            updateEnrollmentState(lesson.id);
            logActivity('RESERVE_LESSON', `${lesson.name} [${lesson.type}]`);
          }
        } else {
          setErrorMessage(t<string>('calendar.errorValidToReserve'));
          setErrorDialogOpen(true);
        }
        const lessonToReserve = reservations.find((item) => item.id === lesson.id);

        if (lessonToReserve) {
          setLessonType(lessonToReserve?.type);
          setInstructor(lessonToReserve?.instructor.name);
          setVehicle(lessonToReserve?.vehicle);
          setCompany(lessonToReserve?.company);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          logActivity('RESERVE_LESSON_ERROR', `${lesson.name} [${lesson.type}] ${e.response?.data.message}`);
          setErrorDialogOpen(true);
          setErrorMessage(e.response?.data.message);
          setErrorDialogOpen(true);
        }
      }
    },
    [
      token,
      getUserReservations,
      updateEnrollmentState,
      t,
      reservations,
      isExtraDrivingLesson,
      isExtraExam,
      isValidToReserve,
      drivesAutomatic,
      course?.contact_info,
      logActivity,
      lessonSlides,
      selectedLessonSlideIndex,
    ],
  );

  const cancelReservation = useCallback(
    async (lesson: Lesson): Promise<void> => {
      changeDialogText(false);
      try {
        await Api.post(
          endpoints.cancelReservation(lesson.id),
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        getUserReservations(token);
        changeDialogText(false);
        setConfirmCancelDialogOpen(false);
        updateEnrollmentState(lesson.id);
        logActivity('CANCEL_RESERVATON', `${lesson.name} [${lesson.type}]`);
      } catch (e) {
        setErrorMessage(t<string>('calendar.errorCancellingReservation'));
        setErrorDialogOpen(true);
      }
    },
    [token, getUserReservations, updateEnrollmentState, t, logActivity],
  );

  const isLessonDateFromDay = (date: string): boolean => {
    const lessonDate = new Date(date);
    return (
      lessonDate.getDate() === calendarValue.getDate() &&
      lessonDate.getMonth() === calendarValue.getMonth() &&
      lessonDate.getFullYear() === calendarValue.getFullYear()
    );
  };

  // driving lesson hours
  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const { data } = await Api.get(endpoints.drivingLessonInfo, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { left_extra_driving_lesson_hours } = data;
        setDrivingLessonInfo(data);
        setLeftExtraDrivingLessonHours(left_extra_driving_lesson_hours);
      } catch {
        setDrivingLessonInfo(null);
      }
    })();
  }, [token, reserveLesson, cancelReservation, setDrivingLessonInfo]);

  const renderCheckBox = useMemo(() => {
    return (
      <>
        <Typography variant="h4" style={{ paddingTop: '32px', paddingBottom: '8px' }}>
          {t<string>('calendar.reserveLesson')}
        </Typography>
        <Card>
          <CardContent>
            <RadioGroup row name="radio_buttons_group">
              {lessonSlides.length > 0 &&
                lessonSlides.map((i, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Radio
                        value={index}
                        key={index}
                        onChange={(e): void => {
                          setSelectedLessonSlideIndex(Number(e.target.value));
                          setIsExtraDrivingLesson(false);
                          setIsExtraExam(false);
                        }}
                        checked={selectedLessonSlideIndex === index && !isExtraExam && !isExtraDrivingLesson}
                      />
                    }
                    label={`${t<string>('calendar.drivingLesson')}: ${i.name} ${i.is_simulator ? '(Simulator)' : ''}`}
                  />
                ))}

              {!!drivingLessonInfo?.extra_exam_available && (
                <FormControlLabel
                  control={
                    <Radio
                      value="extra_exam"
                      onChange={() => {
                        setIsExtraExam(true);
                        setIsExtraDrivingLesson(false);
                      }}
                    />
                  }
                  label={`${t<string>('calendar.extraExam')}`}
                />
              )}
              {!!leftExtraDrivingLessonHours && (
                <FormControlLabel
                  control={
                    <Radio
                      value="extra"
                      onChange={() => {
                        setIsExtraDrivingLesson(true);
                        setIsExtraExam(false);
                      }}
                    />
                  }
                  label={`${t<string>('calendar.extraLesson')}`}
                />
              )}
            </RadioGroup>
          </CardContent>
        </Card>
      </>
    );
  }, [
    leftExtraDrivingLessonHours,
    setIsExtraDrivingLesson,
    drivingLessonInfo?.extra_exam_available,
    selectedLessonSlideIndex,
    isExtraExam,
    isExtraDrivingLesson,
    lessonSlides,
    t,
  ]);

  const compareDates = (date1: Date, date2: Date): boolean => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const getLessonBoxColor = useCallback(
    (state: string): string => {
      if (state === 'completed') return classes.completedLesson;
      if (state === 'cancelled') return classes.cancelledLesson;
      if (state === 'open') return classes.hasLesson;
      return classes.hasNoLesson;
    },
    [classes.cancelledLesson, classes.completedLesson, classes.hasLesson, classes.hasNoLesson],
  );

  const tileClassName = useCallback(
    (dateProps: CalendarTileProperties): string => {
      const dateBox = dateProps.date;
      let boxClass = classes.hasNoLesson;
      userReservations.forEach((lesson) => {
        const lessonDate = new Date(lesson.start_date);
        if (compareDates(lessonDate, dateBox)) {
          if (lesson.is_enrolled) {
            boxClass = classes.isEnrolled;
          } else boxClass = getLessonBoxColor(lesson.state);
        }
      });

      reservations.forEach((lesson) => {
        const lessonDate = new Date(lesson.start_date);
        if (compareDates(lessonDate, dateBox)) {
          if (lesson.is_enrolled) {
            boxClass = classes.isEnrolled;
          } else boxClass = getLessonBoxColor(lesson.state);
        }
      });

      return boxClass;
    },
    [reservations, userReservations, classes.hasNoLesson, classes.isEnrolled, getLessonBoxColor],
  );

  const onViewChange = (props: ViewCallbackProperties): void => {
    setCurrentMonth(props.activeStartDate.getMonth());
    setCalendarValue(props.activeStartDate);
  };

  const [tabNumber, setTabNumber] = React.useState('1');

  const handleTabChange = (event: ChangeEvent<unknown>, newValue: string): void => {
    setTabNumber(newValue);
  };
  return (
    <MainTemplate>
      <Container maxWidth="sm">
        <Header titleText={t<string>('calendar.title')} />
        <Typography style={{ paddingBottom: '32px' }} variant="body2">
          {t<string>('calendar.drivingLessonInfo')}
        </Typography>
        {drivesAutomatic && (
          <Alert severity="warning">
            <AlertTitle>{t<string>('calendar.automaticMessageTitle')}</AlertTitle>
            {course?.contact_info}
          </Alert>
        )}
        {!!drivingLessonInfo && (
          <>
            <Typography variant="h4">{t<string>('calendar.drivingLessonHours')}</Typography>

            <Card className={classes.drivingHours} style={{ paddingBottom: '32px' }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      {t<string>('calendar.mandatoryDrivingHours')}:
                      <Chip
                        label={`${drivingLessonInfo?.left_mandatory_driving_lesson_hours}/${drivingLessonInfo?.total_mandatory_driving_lesson_hours}`}
                        size="small"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {t<string>('calendar.usedDrivingHours')}:
                    <Chip label={drivingLessonInfo?.used_mandatory_driving_lesson_hours} size="small" />
                  </Grid>

                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      {t<string>('calendar.extraDrivingHours')}:
                      <Chip
                        size="small"
                        label={`${drivingLessonInfo?.left_extra_driving_lesson_hours}/${drivingLessonInfo?.total_extra_driving_lesson_hours}`}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    {t<string>('calendar.usedDrivingHours')}:{' '}
                    <Chip size="small" label={drivingLessonInfo?.used_extra_driving_lesson_hours} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {renderCheckBox}
            <Box mt={1}>
              <Typography id="default_teacher" variant="h4" style={{ paddingTop: '32px', paddingBottom: '8px' }}>
                {t<string>('calendar.defaultTeacher')}
              </Typography>
              <Select
                value={preferedInstructor}
                onChange={(e): void => {
                  setPreferredInstructor(Number(e.target.value));
                }}
              >
                <option value="">{t<string>('calendar.allTeachers')}</option>
                {instructors.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.name}
                  </option>
                ))}
              </Select>
            </Box>
          </>
        )}
        <Calendar
          className={classes.calendar}
          value={calendarValue}
          onChange={setCalendarValue}
          onActiveStartDateChange={onViewChange}
          tileClassName={tileClassName}
          locale="fi"
        />
        <TabContext value={tabNumber}>
          <Box>
            <TabList onChange={handleTabChange}>
              <Tab
                label={t<string>('calendar.dailyLessons')}
                value="1"
                style={{ marginTop: '32px', marginBottom: '8px' }}
              />
              <Tab
                label={t<string>('calendar.myReservations')}
                value="2"
                style={{ marginTop: '32px', marginBottom: '8px' }}
              />
            </TabList>
          </Box>
          <TabPanel value="2">
            <Box className={classes.lessons}>
              {userReservations.map((reservation, i) => {
                return (
                  <LessonBox
                    key={i}
                    i={i}
                    lesson={reservation}
                    calendarValue={new Date(reservation.start_date)}
                    handleClick={handleCancelReservation(reservation)}
                    buttonText={t<string>('calendar.cancelReservation')}
                  />
                );
              })}
            </Box>
          </TabPanel>
          <TabPanel value="1">
            <Box className={classes.lessons}>
              {reservations
                .filter((lesson) => isLessonDateFromDay(lesson.start_date))
                .map((lesson, i) => {
                  if (lesson.is_enrolled === false && lesson.state === 'open') {
                    return (
                      <LessonBox
                        key={i}
                        i={i}
                        lesson={lesson}
                        calendarValue={calendarValue}
                        handleClick={reserveLesson(lesson)}
                        buttonText={t<string>('calendar.reserve')}
                      />
                    );
                  }
                  if (lesson.is_enrolled === true && lesson.state !== 'cancelled') {
                    return (
                      <LessonBox
                        key={i}
                        i={i}
                        lesson={lesson}
                        calendarValue={calendarValue}
                        handleClick={handleCancelReservation(lesson)}
                        buttonText={t<string>('calendar.cancelReservation')}
                      />
                    );
                  }
                })}
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
      <SimpleDialog text={errorMessage} isOpen={errorDialogOpen} handleClose={closeErrorDialog} />
      <ConfirmationDialog
        text={cancelReservationText}
        handleYes={(): false | Promise<void> => !!currentLesson && cancelReservation(currentLesson)}
        handleCancel={closeConfirmReserveDialog}
        isOpen={confirmCancelDialogOpen}
      />
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <IconButton size="small">
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h3" align="center">
                {t<string>('calendar.dialogTitle')}
              </Typography>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{reservationText()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} fullWidth>
            {t<string>('home.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </MainTemplate>
  );
};
