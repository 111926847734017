import { createTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

export const colors = {
  white: '#ffffff',
  black: '#000000',
  gray: '#cccccc',
  textBlack: '#1b1924',
  textBlackish: '#101010',
  textGray: '#8f8e91',
  textWhite: '#ffffff',
  primary: '#f45c61',
  primaryGradient: 'linear-gradient(to right, #f45c61, #e86a3c)',
  primaryLight: '#f67c80',
  primaryDark: '#aa4043',
  secondary: '#15cbae',
  secondaryGradient: 'linear-gradient(to left, #15cbae, #34c163)',
  secondaryLight: '#43d5be',
  secondaryDark: '#0e8e79',
  background: '#f2f5fe',
  alert: '#931318',
  asteriskColor: '#ff0000',
  actionDisabledBackground: 'rgba(0, 0, 0, 0.12)', // Default value
  epicPrimaryRed: '#b9171c',
};

export const borderRadius = {
  borderRadiusDefault: 4,
  borderRadiusSmall: 2,
  borderRadiusLarge: 15,
};

export const spacing = (factor: number): string => `${0.5 * factor}rem`;

export const BOTTOM_NAVBAR_HEIGHT = '4rem';

const _palette = createPalette({
  common: {
    black: colors.black,
    white: colors.white,
  },
  primary: {
    light: colors.primaryLight,
    main: colors.primary,
    dark: colors.primaryDark,
    contrastText: colors.white,
  },
  secondary: {
    light: colors.secondaryLight,
    main: colors.secondary,
    dark: colors.secondaryDark,
    contrastText: colors.white,
  },
  text: {
    primary: colors.textBlack,
    secondary: colors.textGray,
  },
  background: {
    paper: colors.white,
    default: colors.background,
  },
  error: {
    main: colors.alert,
  },
});

export const theme = createTheme({
  palette: _palette,
  props: {
    MuiAppBar: {
      color: 'default',
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'secondary',
    },
    MuiCircularProgress: {
      color: 'primary',
    },
    MuiFab: {
      color: 'primary',
    },
    MuiFormControl: {
      fullWidth: true,
      variant: 'outlined',
      margin: 'normal',
    },
    MuiInputBase: {
      color: 'secondary',
    },
    MuiPaper: {
      variant: 'elevation',
      elevation: 0,
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiNativeSelect: {
      variant: 'outlined',
    },
    MuiSelect: {
      fullWidth: true,
      variant: 'outlined',
      native: true,
    },
    MuiSlider: {
      color: 'secondary',
    },
    MuiTextField: {
      fullWidth: true,
      variant: 'outlined',
    },
    MuiSwitch: {
      color: 'secondary',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'antialiased',
          mozOsxFontSmoothing: 'grayscale',
        },
        '.MuiBottomNavigationAction-root.Mui-selected': {
          color: `${colors.black} !important`,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$focused fieldset': {
          borderColor: `${colors.textBlack} !important`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: colors.textBlack,
        },
      },
      asterisk: {
        color: colors.asteriskColor,
      },
      // This fixes the buggy label on Safari: https://github.com/mui-org/material-ui/issues/20391
      shrink: {
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        backgroundColor: _palette.background.paper,
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: colors.textBlack,
        },
      },
      asterisk: {
        color: colors.asteriskColor,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiListItem: {
      root: {
        backgroundColor: 'transparent',
        color: _palette.common.black,
        marginTop: 0,
        borderBottom: `0.05rem solid ${_palette.grey[400]}`,
        borderRadius: 0,
      },
    },
    MuiButton: {
      contained: {
        paddingLeft: spacing(5),
        paddingRight: spacing(5),
      },
      containedPrimary: {
        background: colors.primaryGradient,
        boxShadow: 'none',
        '&$disabled': {
          background: colors.actionDisabledBackground,
        },
      },
      containedSecondary: {
        background: colors.secondaryGradient,
        boxShadow: 'none',
        '&$disabled': {
          background: colors.actionDisabledBackground,
        },
      },
      outlined: {
        backgroundColor: colors.white,
        color: colors.textBlack,
        borderColor: colors.textBlack,
        paddingLeft: spacing(5),
        paddingRight: spacing(5),
      },
      outlinedPrimary: {
        color: colors.textBlack,
        borderColor: colors.primary,
      },
      outlinedSecondary: {
        borderColor: colors.secondary,
      },
      text: {
        backgroundColor: 'transparent',
        color: colors.textBlack,
        borderColor: 'transparent',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: borderRadius.borderRadiusLarge,
      },
    },
    MuiBottomNavigation: {
      root: {
        zIndex: 100,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: BOTTOM_NAVBAR_HEIGHT,
        borderTop: `0.05rem solid ${_palette.grey[300]}`,
      },
    },
    MuiBottomNavigationAction: {
      wrapper: {
        flexDirection: 'row',
      },
      root: {
        flexGrow: 1,
        padding: `${spacing(1)} ${spacing(2)} !important`,
        minWidth: 0,
      },
      iconOnly: {
        paddingTop: `${spacing(1)} !important`,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
      },
    },
  },
  typography: {
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontFamily: 'Rubik, sans-serif',
    h1: {
      fontSize: 30,
      fontWeight: 600,
      letterSpacing: '0em',
    },
    h2: {
      fontSize: 22,
      fontWeight: 600,
      letterSpacing: '0em',
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 400,
    },
    body1: {
      fontSize: 18,
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
    },
    button: {
      fontWeight: 400,
      fontSize: 18,
      textTransform: 'none',
    },
    caption: {
      fontSize: 15,
      fontWeight: 400,
    },
    overline: {
      fontSize: 16,
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: borderRadius.borderRadiusDefault,
  },
  spacing,
});
