import React, { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { Container, Button, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Api } from '../../api';
import { endpoints } from '../../utils';
import { Error, MainTemplate } from '../layout';
import { RegisterComplete } from './RegisterComplete';

// TODO: We should maybe have the Total steps in some other file
export const KlarnaConfirmation: React.FC = () => {
  // This also does a Get request to Klarna API
  const { t } = useTranslation();
  const useStyles = makeStyles(({ spacing }) => ({
    alert: {
      marginTop: spacing(2),
    },
    root: {
      //backgroundImage: 'url(images/background.svg)',
      backgroundColor: '#EDF0FA',
      minHeight: '100vh',
    },
  }));
  const classes = useStyles();
  const { klarnaOrderId } = useParams<{ klarnaOrderId: string }>();
  const [loading, setLoading] = useState(true);
  const [acknowledged, setAcknowledged] = useState(false);
  const [confirmationSnippet, setConfirmationSnippet] = useState('');
  const [error, setError] = useState<string | null>(null);

  const acknowledgeOrder = (): void => {
    setAcknowledged(true);
  };

  // TODO: Can we put this script tag replacement code as a utility so we don't replicate it everytime?
  useEffect(() => {
    const checkoutContainer = document.getElementById('klarnaConfirm');
    if (checkoutContainer !== null) {
      const scriptsTags = checkoutContainer.getElementsByTagName('script');
      checkoutContainer.innerHTML = confirmationSnippet || '';

      // This is necessary otherwise the scripts tags are not going to be evaluated
      for (let i = 0; i < scriptsTags.length; i += 1) {
        const { parentNode } = scriptsTags[i];
        if (parentNode !== null) {
          const newScriptTag = document.createElement('script');
          newScriptTag.type = 'text/javascript';
          newScriptTag.text = scriptsTags[i].text;
          parentNode.removeChild(scriptsTags[i]);
          parentNode.appendChild(newScriptTag);
        }
      }
    }
  }, [confirmationSnippet]);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        // NOTE: We can't render the Klarna Confirmation endpoint
        const res = await Api.get(endpoints.getKlarnaOrder(klarnaOrderId), {});
        setConfirmationSnippet(res.data.html_snippet);
      } catch (e) {
        setError(String(e));
      } finally {
        setLoading(false);
      }
    })();
  }, [klarnaOrderId]);

  const getLandingPageUrl = (): string => {
    const url = localStorage.getItem('courseLandingPageUrl') || '';
    return url;
  };

  return (
    <MainTemplate hideBottomNavbar>
      <Container maxWidth="sm">
        {loading && <CircularProgress size={24} />}
        {acknowledged && (
          <div id="trinityConfirmationDetails">
            <RegisterComplete currentStep={6} totalSteps={6} landingPageUrl={getLandingPageUrl()} />
          </div>
        )}
        {!acknowledged && (
          <div>
            <h1>{t<string>('courseWebshop.orderIsReady')}</h1>
            <div id="klarnaConfirm" />
          </div>
        )}
        {!acknowledged && (
          <Button onClick={acknowledgeOrder} color="primary">
            {t<string>('courseWebshop.complete')}
          </Button>
        )}
        {!!error && (
          <Error>
            <Alert className={classes.alert} severity="error">
              {error}
            </Alert>
          </Error>
        )}
      </Container>
    </MainTemplate>
  );
};
