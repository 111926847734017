import { endpoints } from '../../utils';
import { Section } from '../../types';
import { Api } from '../../api';

// Fetch all sections for the course.
export const getSections = async (selectedCourse: number | null, token: string): Promise<Section[] | undefined> => {
  if (selectedCourse) {
    try {
      const { data } = await Api.get<Section[]>(endpoints.slides(selectedCourse), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch {
      return [];
    }
  }
  return [];
};
