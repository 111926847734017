import { useEffect, useRef, useMemo, useState } from 'react';

function getCurrentURL() {
  return window.location.href;
}

const url = getCurrentURL();

export const useTimerRef = (durationMs: number, sectionName: string | undefined) => {
  const timer = useRef(durationMs);
  const section = useRef(sectionName);
  const examState = localStorage.getItem('examState');
  const worker = useMemo(() => new Worker(new URL('./webWorker.js', url)), []);
  const is_practice = localStorage.getItem('is_practice');
  const [rootState, setRootState] = useState('false');

  useEffect(() => {
    const rootTimer = setInterval(() => {
      const root = document.querySelector('.MuiBackdrop-root');
      if (root === null) {
        setRootState('false');
      } else {
        setRootState('true');
      }
      clearInterval(rootTimer);
    }, 500);

    if (section.current !== 'PracticeExams') {
      if (rootState === 'true') {
        worker.postMessage({
          time: timer.current,
          examState: rootState,
        });
      } else {
        worker.postMessage({
          time: 2700000, // back to 45 minutes
          examState: 'false',
        });
      }
    } else {
      worker.postMessage({
        time: 2700000, // back to 45 minutes
        examState: 'false',
      });
    }
  }, [examState, worker, rootState, is_practice]);

  useEffect(() => {
    worker.onmessage = (event: MessageEvent) => {
      const { data } = event;
      timer.current = data;
    };
  });

  return timer;
};
