import React, { useCallback, useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KlarnaConfirmation, PrivateRoute, SimpleDialog } from './components';
import { theme } from './theme';
import { urls } from './utils';
import { AuthContextProvider, StudyPathContextProvider } from './context';

import {
  Login,
  Register,
  ForgotPassword,
  Home,
  Course,
  MyCourses,
  Profile,
  PersonalInformation,
  Invoices,
  ChangePassword,
  Materials,
  NotFound,
  PracticeExams,
  CalendarComponent,
  Checkout,
} from './pages';
import { Api } from './api';

export const App: React.FC = () => {
  const { t } = useTranslation();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  useEffect(() => {
    Api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          window.location.href = `${urls.login}`;
          return null;
        }
        if (error.message.trim() === 'Network Error') {
          setErrorDialogOpen(true);
        } else {
          return Promise.reject(error);
        }
      },
    );
  }, [setErrorDialogOpen]);

  const closeCourseErrorDialog = useCallback((): void => {
    setErrorDialogOpen(false);
    window.location.reload();
  }, [setErrorDialogOpen]);

  if (errorDialogOpen) {
    return (
      <SimpleDialog
        text={t<string>('misc.connectionError')}
        isOpen={errorDialogOpen}
        handleClose={closeCourseErrorDialog}
      />
    );
  }

  return (
    <AuthContextProvider>
      <StudyPathContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Switch>
              <PrivateRoute exact path={urls.myCourses} component={MyCourses} />
              <Route exact path={urls.login} component={Login} />
              <Route exact path={urls.registration} component={Register} />
              <Route exact path={urls.forgotPassword} component={ForgotPassword} />
              <Route exact path={urls.klarnaConfirm(':klarnaOrderId')} component={KlarnaConfirmation} />
              <Route exact path={urls.checkout} component={Checkout} />
              <PrivateRoute exact path={urls.home} component={Home} />
              <PrivateRoute exact path={urls.course(':courseId')} component={Course} />
              <PrivateRoute exact path={urls.profile} component={Profile} />
              <PrivateRoute exact path={urls.updateProfile} component={PersonalInformation} />
              <PrivateRoute exact path={urls.invoices} component={Invoices} />
              <PrivateRoute exact path={urls.changePassword} component={ChangePassword} />
              <PrivateRoute exact path={urls.materials} component={Materials} />
              <PrivateRoute exact path={urls.profile} component={Profile} />
              <PrivateRoute exact path={urls.practiceExams} component={PracticeExams} />
              <PrivateRoute exact path={urls.calendar} component={CalendarComponent} />
              <PrivateRoute component={NotFound} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </StudyPathContextProvider>
    </AuthContextProvider>
  );
};
