import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Category } from '../../types';

const useStyles = makeStyles({
  root: {
    zIndex: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  svg: {
    maxWidth: '100%',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  roads: {
    opacity: 0,
    animation: '1.5s $fadeIn forwards',
  },
  '@keyframes drawLines': {
    to: {
      strokeDashoffset: 0,
    },
  },
  lines: {
    strokeDashoffset: 500,
    strokeDasharray: 10,
    animation: '1.5s $drawLines forwards',
  },
});

interface Props {
  categories: Category[];
}

export const StudyPath: React.FC<Props> = ({ categories }) => {
  const { palette } = useTheme();
  const [querySelector, setQuerySelector] = useState('');
  const svgContainerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const classes = useStyles();

  // Do not draw connector after last element.
  const elements = [...Array((categories.length || 1) - 1)].map((_, i) => ({
    from: `#path-item-${i}`,
    to: `#path-item-${i + 1}`,
  }));

  const drawPath = (
    svg: SVGElement,
    path: Element,
    startX: number,
    startY: number,
    endX: number,
    endY: number,
  ): void => {
    const stroke = parseFloat(String(path.getAttribute('stroke-width')));

    // Check if the SVG is big enough to draw the path.
    // If not, set height/width.
    if (Number(svg.getAttribute('height')) < endY) {
      svg.setAttribute('height', String(endY));
    }

    if (Number(svg.getAttribute('width')) < startX + stroke) {
      svg.setAttribute('width', String(startX + stroke));
    }

    if (Number(svg.getAttribute('width')) < endX + stroke) {
      svg.setAttribute('width', String(endX + stroke));
    }

    const deltaX = endX - startX;
    const deltaY = endY - startY;
    const delta = deltaY < Math.abs(deltaX) ? deltaY : Math.abs(deltaX);

    // Set sweep-flag counter-/clockwise.
    // If start element is closer to the left edge, draw arc counter-clockwise.
    let arc = 1;

    if (startX > endX) {
      arc = 0;
    }

    path.setAttribute(
      'd',
      `M${startX} ${startY} H${endX - delta * Math.sign(deltaX)} A${delta} ${delta} 0 0 ${arc} ${endX} ${
        startY + delta
      } V${endY}`,
    );
  };

  const connectElements = useCallback(
    (container: HTMLDivElement, svg: SVGElement, path: Element, startElem: Element, endElem: Element): void => {
      // If first element is lower than the second, swap them.
      if (startElem.getBoundingClientRect().top > endElem.getBoundingClientRect().top) {
        const temp = startElem;
        startElem = endElem;
        endElem = temp;
      }

      // Get (top, left) corner coordinates of the SVG container.
      const svgTop = container.getBoundingClientRect().top;
      const svgLeft = container.getBoundingClientRect().left;

      // Get (top, left) coordinates for the two elements.
      const startCoord = startElem.getBoundingClientRect();
      const endCoord = endElem.getBoundingClientRect();

      //  Set start coordinate on X-axis depending on which direction we're moving.
      const startX =
        startCoord.left < endCoord.left ? startCoord.left + startCoord.width - svgLeft : startCoord.left - svgLeft;

      const startY = startCoord.top + startCoord.height / 2 - svgTop;

      //  Set end coordinate on X-axis depending on which direction we're moving.
      const endX =
        startCoord.left < endCoord.left
          ? endCoord.left + 0.8 * endCoord.width - svgLeft
          : endCoord.left + 0.2 * endCoord.width - svgLeft;

      const endY = endCoord.top - svgTop;

      // Call function for drawing the path.
      drawPath(svg, path, startX, startY, endX, endY);
    },
    [],
  );

  // Map through elements that we want to connect and draw road-like SVG paths for them one-by-one.
  const connectAllRoads = useCallback((): void => {
    elements.map((el, i) => {
      const start = document.querySelector(el.from);
      const end = document.querySelector(el.to);
      const path = document.querySelector(`#road${i + 1}`);

      if (svgContainerRef.current && svgRef.current && path && start && end) {
        return connectElements(svgContainerRef.current, svgRef.current, path, start, end);
      }
    });
  }, [connectElements, elements]);

  // Map through elements that we want to connect and draw SVG paths for lines on top of the road.
  const connectAllLines = useCallback((): void => {
    elements.map((el, i) => {
      const start = document.querySelector(el.from);
      const end = document.querySelector(el.to);
      const path = document.querySelector(`#line${i + 1}`);

      if (svgContainerRef.current && svgRef.current && path && start && end) {
        return connectElements(svgContainerRef.current, svgRef.current, path, start, end);
      }
    });
  }, [connectElements, elements]);

  const handleConnectAll = useCallback((): void => {
    if (querySelector) {
      connectAllRoads();
      connectAllLines();
    }
  }, [connectAllLines, connectAllRoads, querySelector]);

  useEffect(() => {
    const selector = '.path-items';

    if (document.querySelector(selector)) {
      setQuerySelector(selector);
    }
  }, []);

  // Connect the elements upon setting the query selector and resizing the window.
  useEffect(() => {
    handleConnectAll();

    // Make sure we re-render the connector when window size is changed.
    window.addEventListener('resize', handleConnectAll);

    return (): void => {
      window.removeEventListener('resize', handleConnectAll);
    };
  }, [handleConnectAll, querySelector]);

  const renderRoads = useMemo(
    () =>
      elements.map((_, i) => (
        <path
          style={{ animationDelay: `${i * 0.1}s` }}
          className={classes.roads}
          key={i}
          id={`road${i + 1}`}
          stroke={categories[i].completed ? palette.grey[600] : palette.grey[300]} // Use lighter shade as stroke for roads that lead on uncompleted items on the path.
          strokeWidth="18"
          fill="none"
        />
      )),
    [categories, classes.roads, elements, palette.grey],
  );

  const renderLines = useMemo(
    () =>
      elements.map((_, i) => (
        <path
          style={{ animationDelay: `${i * 0.1}s` }}
          className={classes.lines}
          key={i}
          id={`line${i + 1}`}
          stroke={palette.common.white}
          strokeWidth="2"
          fill="none"
        />
      )),
    [classes.lines, elements, palette.common.white],
  );

  if (!querySelector) {
    return null;
  }

  return (
    <Box className={classes.root} ref={svgContainerRef}>
      <svg ref={svgRef} className={classes.svg}>
        {renderRoads}
        {renderLines}
      </svg>
    </Box>
  );
};
