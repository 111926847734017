import { useState, useEffect, Dispatch, SetStateAction } from 'react';

// A regular `useState`-hook, except the value is stored in LS.
export const useStickyState = <T>(defaultValue: unknown, key: string): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = localStorage.getItem(key);
    return stickyValue ? JSON.parse(stickyValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
