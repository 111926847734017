import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  CardActionArea,
  Grid,
  Typography,
  Box,
  Select,
  Card,
  CardContent,
  CardMedia,
  Button,
  Container,
  Drawer,
  Chip,
  Input,
  TextField,
} from '@material-ui/core';
import { ArrowForwardRounded, Lock, LockRounded, LockOpen } from '@material-ui/icons';
import { Timeline, TimelineContent, TimelineItem } from '@material-ui/lab';
import School from '@material-ui/icons/School';
import { useTranslation } from 'react-i18next';
import * as zod from 'zod';
import { Lesson, Service } from '../types';
import { colors } from '../theme';
import { MainTemplate, Progress, SimpleDialog, SlidesDialog } from '../components';
import { useAuthContext, useStudyPathContext } from '../context';
import { endpoints, PHONE_NUMBER_REGEX, urls } from '../utils';
import { Api } from '../api';
import { StudyPath } from '../components/home/StudyPath';
import { LanguageSelection } from '../components/common/LanguageSelection';
import { getSections } from '../components/studentpath/Sections';
import axios from 'axios';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
  },
  homeTop: {
    justifyContent: 'center',
    paddingTop: '17px',
  },
  timeline: {
    padding: 0,
    margin: 0,
    position: 'relative',
  },
  error: {
    color: 'red',
  },
  timelineItem: {
    zIndex: 1,
    marginTop: spacing(8),
  },
  timelineContent: {
    padding: 0,
  },
  timelineContentOdd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  timelineCardUnlocked: {
    backgroundColor: palette.secondary.light,
  },
  timelineCard: {
    zIndex: 1,
    whiteSpace: 'unset',
    wordWrap: 'break-word',
    textAlign: 'center',
    borderRadius: '2rem',
    height: '6.5rem',
    cursor: 'pointer',
    display: 'flex',
    width: '15rem',
  },
  reservationLabel: {
    width: '15rem',
  },
  categoryName: {
    marginLeft: spacing(1),
    marginBottom: '0px',
    lineHeight: 1,
    color: '#101010',

    path: {
      fill: '#101010',
    },

    [breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  categoryNameDone: {
    marginLeft: spacing(1),
    marginBottom: '0px',
    lineHeight: 1,
    [breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  pathConnector: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
  },
  cardIcon: {
    height: '2.5rem',
    width: '2.5rem',
    fill: '#101010',
  },
  drawerRounded: {
    borderRadius: '1.5rem 1.5rem 0 0',
    [breakpoints.up('md')]: {
      maxWidth: '30rem',
      margin: '0 auto',
    },
  },
  drawerContent: {
    padding: spacing(4),
    textAlign: 'center',
  },
  drawerButtons: {
    marginTop: spacing(4),
  },
  dialog: {
    backgroundColor: colors.background,
    textAlign: 'center',
    padding: `${spacing(4)} ${spacing(2)}`,
    [breakpoints.up('md')]: {
      borderRadius: '1.5rem',
    },
  },
  reviewDialogPaper: {
    textAlign: 'center',
    borderRadius: '1.5rem',
  },
  categoryCard: {
    marginTop: spacing(3),
    borderRadius: '1.5rem',
  },
  categoryCardActionArea: {
    padding: spacing(6),
  },
  categoryLockedIcon: {
    margin: `${spacing(8)} 0`,
    width: '3rem',
    height: '3rem',
  },
  categoryLockedHeader: {
    marginBottom: spacing(4),
  },
  nextQuestionButton: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  questionPercentage: {
    color: palette.success.main,
  },
  '@keyframes fadeAnimation': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  fadeIn: {
    opacity: 0,
    animation: '1.5s $fadeAnimation forwards',
  },
  crown: {
    width: '1.75rem',
    height: '1.5rem',
    marginLeft: spacing(2),
  },
  greeting: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: '24px',
  },
  categoryPrevButton: {
    color: palette.common.black,
  },
  studyControls: {
    border: '0px solid gray',
    backgroundColor: palette.common.white,
    borderRadius: '1.5rem',

    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
    },
  },
  isOpen: {
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
    },
  },
  enterNumber: {
    [breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'flexStart',
    },
  },
  enterNumberBtn: {
    [breakpoints.down('sm')]: {
      fontSize: '16px',
      marginTop: '16px',
      width: '100%',
    },
  },
}));

export const Home: React.FC = () => {
  const userlanguage = localStorage.getItem('user_language') ?? 'FI';
  const [selectedLanguage, setSelectedLanguage] = useState(userlanguage);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [showModalPhone, setShowModalPhone] = useState(false);
  const [showGreenButton, setShowGreenButton] = useState(false);

  const {
    profileData: { first_name, current_course, study_phone_number },
    token,
    logActivity,
  } = useAuthContext();

  const {
    sections,
    setSections,
    reservations,
    setReservations,
    categories,
    setSlides,
    setCategories,
    selectedCategory,
    setSelectedCourse,
    enrollments,
    setEnrollments,
    progress,
    handleSlidesPrev,
    prevCategory,
    handleOpenPrevCategory,
    categoryLocked,
    handleCategoryClick,
    selectedCourse,
    isOpenNow,
    setIsOpenNow,
    openHours,
    setOpenHours,
    studyPathError,
    setStudyPathError,
    setLiveChatLoaderURL,
    setStudyPathPhoneNumber,
  } = useStudyPathContext();

  const { t } = useTranslation();
  const classes = useStyles();
  const [productId, setProductId] = useState<number | null>(null);
  const [mainCourseError, setMainCourseError] = useState<string>('');
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [previousCourseId, setPreviousCourseId] = useState<number | null>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [selectedProduct, setSelectedProduct] = useState<string>('0');
  const [products, setProducts] = useState<Service[]>([]);
  // Initialize the selected course with the current course.
  useEffect(() => {
    setSelectedCourse(current_course);
  }, [current_course, setSelectedCourse]);

  // Fetch our Open Hours details.
  useEffect(() => {
    if (!token) return;

    Api.get(endpoints.checkIsOpenRightNow, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(({ data }) => {
      setIsOpenNow(data.open);
      setOpenHours(data.open_times_today);
    });
  }, [setIsOpenNow, setOpenHours, token]);

  // Fetch all enrolled courses.
  useEffect(() => {
    if (!token) return;

    Api.get(endpoints.enrollments, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => setEnrollments(response.data));
  }, [setEnrollments, token]);

  useEffect(() => {
    if (!token) return;
    getSections(selectedCourse, token).then((data) => {
      if (!data) return;
      setSections(data);
    });
  }, [token, selectedCourse, setSections]);

  // Fetch live chat widget.
  // TODO: Should this be in a different place?
  useEffect(() => {
    if (!token) return;

    Api.get(endpoints.liveChat, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => setLiveChatLoaderURL(response.data))
      .catch(() => setLiveChatLoaderURL(''));
  }, [token, setLiveChatLoaderURL]);

  // When changing the section selection, also update the categories.
  useEffect(() => {
    setCategories(sections.find((s) => s.section_type === 'supervised')?.categories || []);
  }, [sections, setCategories]);
  // When category selection changes, update the Slide selection.
  useEffect(() => {
    const _slides = (!!selectedCategory && categories.find((c) => c.id === selectedCategory.id)?.slides) || [];
    setSlides(
      _slides.filter(
        (s) =>
          ['video', 'certification', 'driving_lesson', 'online_lesson', 'driving_exam'].includes(s.content_type) ||
          (s.content_type === 'notification' && !s.completed),
      ),
    );
  }, [categories, selectedCategory, setSlides]);

  useEffect(() => {
    if (!token) return;

    Api.get(endpoints.getReservations, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        if (!Array.isArray(data)) return;
        setReservations(data);
      })
      .catch(() => {
        console.error('error getting reservations');
      });
  }, [setReservations, token]);

  // set main couse for student
  const setPreviousCouse = useCallback(
    (prevValue: number) => {
      setPreviousCourseId(prevValue);
    },
    [setPreviousCourseId],
  );

  const loadExtraServices = useCallback(async (): Promise<void> => {
    const { data } = await Api.get(endpoints.extraDrivingLessonProduct, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setProducts(data);
  }, [token]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (selectedCourse && previousCourseId !== null) {
        try {
          await Api.post(
            endpoints.setMainCourse(Number(selectedCourse)),
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
        } catch (e) {
          if (axios.isAxiosError(e)) {
            setMainCourseError(e.response?.data?.message);
            setErrorDialogOpen(true);
          }
        }
      }
      loadExtraServices();
    })();
  }, [token, selectedCourse, previousCourseId, loadExtraServices]);

  const closeCourseErrorDialog = useCallback(() => {
    setErrorDialogOpen(false);
    setSelectedCourse(Number(previousCourseId));
  }, [setErrorDialogOpen, setSelectedCourse, previousCourseId]);

  const fetchPhoneNumber = useCallback((): void => {
    const phoneNumberInput = document.getElementById('studyPathPhone') as HTMLInputElement;
    const studyNumberValidation = zod.object({
      phone: zod.string().min(1).regex(PHONE_NUMBER_REGEX),
    });

    if (phoneNumberInput) {
      try {
        studyNumberValidation.parse({ phone: phoneNumberInput.value.trim() });
        // TODO: Validate phone nubmer
        (async (): Promise<void> => {
          await Api.post(
            endpoints.studyPathPhoneNumber,
            {
              study_phone_number: phoneNumberInput.value,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          setStudyPathPhoneNumber(phoneNumberInput.value.trim());
          setPhoneNumberError(false);
          setShowModalPhone(true);
          setShowGreenButton(true);
        })();
      } catch (e) {
        setPhoneNumberError(true);
        setShowModalPhone(false);
        setShowGreenButton(false);
      }
    }
  }, [setStudyPathPhoneNumber, setPhoneNumberError, setShowModalPhone, setShowGreenButton, token]);

  // Set phone number from profile info if applicable
  useEffect(() => {
    const phoneNumberInput = document.getElementById('studyPathPhone') as HTMLInputElement;
    if (phoneNumberInput && study_phone_number) {
      setStudyPathPhoneNumber(study_phone_number);
      phoneNumberInput.value = study_phone_number;
    }
  }, [setStudyPathPhoneNumber, study_phone_number]);

  // Extra driving lesson product ID
  useEffect(() => {
    loadExtraServices();
  }, [token, loadExtraServices]);

  const handleQuantity = useCallback(
    (quantity: number) => {
      const p: Service = products[Number(selectedProduct)];
      setQuantity(quantity);
      if (p) {
        if (p.type !== 'extra_driving_lesson') setQuantity(1);
      }
    },
    [setQuantity, products, selectedProduct],
  );

  // Checks if application is open or not
  useEffect(() => {
    if (openHours) {
      if (openHours.length) {
        if (openHours[0]) {
          const date = new Date()
            .toLocaleTimeString('fi-FI', {
              hour: '2-digit',
              minute: '2-digit',
            })
            .replace('.', ':');

          if (date >= openHours[0].open_from && date <= openHours[0].open_to) {
            console.log('open');
            setIsOpenNow(true);
          } else {
            console.log('not-open');
            setIsOpenNow(false);
          }
        }
      }
    }
  });

  useEffect(() => {
    const p: Service = products[Number(selectedProduct)];
    if (p) {
      setProductId(p.product_id);
      if (p.type !== 'extra_driving_lesson') {
        setQuantity(1);
      }
    }
  }, [selectedProduct, products]);

  const showModalPhoneclose = (): void => {
    setShowModalPhone(false);
  };

  const renderSectionInfo = useMemo(
    () => (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} container spacing={2} className={classes.homeTop}>
          <Grid item md={8} xs={12}>
            <Typography className={classes.greeting} variant="h1" color="textPrimary" gutterBottom>
              {t<string>('home.greeting', { first_name })}
            </Typography>
            <Typography style={{ paddingBottom: '6px' }} variant="body2">
              {t<string>('home.infoText')}
            </Typography>
            <Typography style={{ paddingBottom: '6px' }} variant="body2">
              {t<string>('home.phoneInfo')}
            </Typography>
            <Typography style={{ paddingBottom: '6px' }} variant="body2">
              {t<string>('home.whenOpen')}
            </Typography>
            {/* Show Open Times info */}
            {!!mainCourseError && (
              <SimpleDialog text={mainCourseError} isOpen={errorDialogOpen} handleClose={closeCourseErrorDialog} />
            )}
            {!!enrollments.length && (
              <Box marginTop={2} marginBottom={2}>
                <Select
                  value={selectedCourse}
                  onChange={(e): void => {
                    setPreviousCouse(Number(selectedCourse));
                    setSelectedCourse(Number(e.target.value));
                  }}
                >
                  {enrollments.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
            {products.length > 0 && (
              <>
                <Typography variant="h4">{t<string>('home.product')}</Typography>
                <Box marginTop={1}>
                  <Select
                    value={selectedProduct}
                    onChange={(e): void => {
                      setSelectedProduct(String(e.target.value));
                    }}
                  >
                    {products.map((e, i) => (
                      <option key={i} value={i}>
                        {e.name}
                      </option>
                    ))}
                  </Select>
                  <TextField
                    id="quantity"
                    label={`${t<string>('home.quantity')}`}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ min: 1 }}
                    onChange={(e): void => {
                      handleQuantity(Number(e.target.value));
                    }}
                    value={quantity || 1}
                  />
                </Box>
                <Box mt={1} mb={1}>
                  <Button
                    href={`${urls.checkout}?product=${productId}&quantity=${quantity}`}
                    variant="contained"
                    onClick={(): void => logActivity('CLICK_BUY_PRODUCT', `${products[Number(selectedProduct)].name}`)}
                  >
                    {t<string>('home.buy')}
                  </Button>
                </Box>
              </>
            )}
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end" alignItems="flex-start">
            <Progress progress={progress} />
          </Grid>
          <Grid
            className={classes.studyControls}
            xs={12}
            item
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            spacing={2}
          >
            <Grid item xs={12} md={6} justifyContent="center" alignItems="center" container className={classes.isOpen}>
              <Box alignItems="center" justifyContent="center" display="flex">
                <Typography variant="h4">
                  {t<string>('home.isOpenNow')}:{' '}
                  {isOpenNow ? <Chip label={t<string>('misc.yes')} /> : <Chip label={t<string>('misc.no')} />}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} justifyContent="center" container className={classes.isOpen}>
              <Typography variant="h4">
                {t<string>('home.openHours')}:{' '}
                {openHours.map((hours, i) => (
                  <Chip key={i} label={`${hours.open_from} - ${hours.open_to}`} />
                ))}
              </Typography>
            </Grid>
            {/* Show dialog for Study Path Phone Number */}
            <Grid item xs={12} justifyContent="center" container>
              {isOpenNow && (
                <Box alignItems="center" justifyContent="center" display="flex" className={classes.enterNumber}>
                  {phoneNumberError && (
                    <Typography variant="body2" className={classes.error}>
                      {t<string>('formValidation.phoneInvalid')}
                    </Typography>
                  )}
                  {showModalPhone && (
                    <SimpleDialog
                      text={t<string>('home.successInputPhone')}
                      isOpen={showModalPhone}
                      handleClose={showModalPhoneclose}
                    />
                  )}

                  <Input id="studyPathPhone" error={phoneNumberError} placeholder={t<string>('userData.phone')} />

                  <Button
                    className={classes.enterNumberBtn}
                    style={{
                      background: !showGreenButton ? 'linear-gradient(to right, #f45c61, #e86a3c)' : '#2da72d',
                    }}
                    onClick={(): void => fetchPhoneNumber()}
                  >
                    {t<string>('home.inputPhone')}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    [
      classes.greeting,
      classes.studyControls,
      classes.error,
      classes.homeTop,
      classes.isOpen,
      classes.enterNumberBtn,
      classes.enterNumber,
      t,
      first_name,
      enrollments,
      selectedCourse,
      progress,
      isOpenNow,
      openHours,
      phoneNumberError,
      showModalPhone,
      showGreenButton,
      setSelectedCourse,
      fetchPhoneNumber,
      productId,
      mainCourseError,
      errorDialogOpen,
      closeCourseErrorDialog,
      setPreviousCouse,
      quantity,
      handleQuantity,
      products,
      selectedProduct,
      logActivity,
    ],
  );

  const closeStudyPathError = (): void => {
    setStudyPathError(false);
  };

  const reservationText = (reservation: Lesson): string => {
    const date = new Date(reservation.start_date);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}  ${reservation.name}`;
  };

  const renderCategories = useMemo(
    () => (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Timeline align="alternate" className={clsx(classes.timeline, 'path-items')}>
            {categories.map((c, i) => (
              <TimelineItem key={i} className={classes.timelineItem}>
                <TimelineContent className={clsx(classes.timelineContent, i % 2 === 0 && classes.timelineContentOdd)}>
                  <Card
                    className={clsx(classes.timelineCard, classes.fadeIn, {
                      [classes.timelineCardUnlocked]: c.completed,
                    })}
                    style={{ animationDelay: `${i * 0.1}s`, height: '100%', width: '100%' }}
                    id={`path-item-${i}`}
                  >
                    <CardActionArea onClick={(): void => handleCategoryClick(c)}>
                      <CardContent>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <CardMedia>
                              {c.completed && <School className={classes.cardIcon} />}
                              {categories[i - 1]?.completed && !c.completed && (
                                <LockOpen className={classes.cardIcon} color="disabled" />
                              )}
                              {!categories[i - 1]?.completed && !c.completed && (
                                <Lock className={classes.cardIcon} color="disabled" />
                              )}
                            </CardMedia>
                          </Grid>
                          <Grid item xs={8} container>
                            <Typography
                              variant="h3"
                              color={c.completed ? 'initial' : 'textSecondary'}
                              gutterBottom
                              className={c.completed ? classes.categoryNameDone : classes.categoryName}
                            >
                              {c.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        {reservations.map((r, i) => {
                          if (r.attendances[0].category_id === c.id && r.state !== 'cancelled')
                            return (
                              <Chip
                                key={i}
                                className={classes.reservationLabel}
                                size="small"
                                label={reservationText(r)}
                              />
                            );
                        })}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            ))}
            <StudyPath categories={categories} />
          </Timeline>
        </Grid>
      </Grid>
    ),
    [
      categories,
      classes.cardIcon,
      classes.categoryName,
      classes.categoryNameDone,
      classes.fadeIn,
      classes.timeline,
      classes.timelineCard,
      classes.timelineContent,
      classes.timelineContentOdd,
      classes.timelineItem,
      classes.timelineCardUnlocked,
      classes.reservationLabel,
      handleCategoryClick,
      reservations,
    ],
  );

  const renderCategoryLockedDrawer = useMemo(
    () => (
      <Drawer
        anchor="bottom"
        onClose={handleSlidesPrev}
        classes={{ paper: clsx(classes.drawerContent, classes.drawerRounded) }}
        open={!!selectedCategory && categoryLocked}
      >
        {categoryLocked && !!prevCategory && (
          <Grid container direction="column" alignItems="center">
            <LockRounded className={classes.categoryLockedIcon} color="disabled" />
            <Typography variant="h2" className={classes.categoryLockedHeader}>
              {t<string>('home.categoryLockedHeader')}
            </Typography>
            <Typography component="br" />
            <Typography variant="body2" color="textSecondary">
              {t<string>('home.categoryLockedText')}
            </Typography>
            <Typography component="br" />
            <Typography variant="h5">{prevCategory.name}</Typography>
            <Typography component="br" />
            <Grid className={classes.drawerButtons} container spacing={2}>
              <Grid item xs={12}>
                <Button
                  onClick={handleOpenPrevCategory}
                  color="primary"
                  variant="contained"
                  endIcon={<ArrowForwardRounded />}
                  fullWidth
                >
                  {t<string>('home.moveToPart')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={handleSlidesPrev} variant="outlined" fullWidth>
                  {t<string>('misc.cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Drawer>
    ),
    [
      handleSlidesPrev,
      classes.drawerContent,
      classes.drawerRounded,
      classes.categoryLockedIcon,
      classes.categoryLockedHeader,
      classes.drawerButtons,
      selectedCategory,
      categoryLocked,
      prevCategory,
      t,
      handleOpenPrevCategory,
    ],
  );
  // TODO: Check the code below at later point for live chat dynamic embed!
  // useEffect(() => {
  //   // Load live Chat widget data from backend.
  //   if (liveChatLoaderURL) {
  //     const xhr = new XMLHttpRequest();
  //     xhr.addEventListener('load', () => {
  //       if (xhr.responseText !== '') {
  //         // Set actual Widget Content into Context
  //         setLiveChatWidget(xhr.responseText);
  //       }
  //     });
  //     xhr.open('GET', liveChatLoaderURL);
  //     xhr.send();
  //   }
  // }, [liveChatLoaderURL, setLiveChatWidget]);
  // useEffect(() => {
  //   // Load Actual Live Chat Widget to site (replacing the tags so it gets executed)
  //   // TODO: This script isn't being executed yet! Instead the one in index.html is!
  //   const newScriptTag = document.createElement('script');
  //   newScriptTag.textContent = liveChatWidget;
  //   newScriptTag.async = true;
  //   document.body.appendChild(newScriptTag);
  // }, [liveChatWidget]);

  return (
    <MainTemplate>
      <Container className={classes.container}>
        <Grid item xs={12} container justifyContent="flex-end">
          <LanguageSelection selectedLanguage={selectedLanguage} setLaguage={setSelectedLanguage} />
        </Grid>
        {studyPathError && (
          <SimpleDialog text={t<string>('home.notOpenNow')} isOpen={studyPathError} handleClose={closeStudyPathError} />
        )}
        {renderSectionInfo}
        {renderCategories}
        {renderCategoryLockedDrawer}
        <SlidesDialog isOpenHours={isOpenNow} />
      </Container>
    </MainTemplate>
  );
};
