import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface UseScreenType {
  isMobileOrTablet: boolean;
}

export const useScreenType = (): UseScreenType => {
  const { breakpoints } = useTheme();
  const isMobileOrTablet = useMediaQuery(breakpoints.down('md'));

  return {
    isMobileOrTablet,
  };
};
